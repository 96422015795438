import { apiAxios } from '../api/config';
import socket from '../socket';
import { IParamRestDelete } from '../types';

export class Rest_terminal {
  static searchTerminalCity(payload: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('search-terminal-city', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static searchTerminal(payload: {
    text: string;
    all?: boolean;
  }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('search-terminal', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static searchTerminalCityChild(payload: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('search-terminal-city-child', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getTerminalCity(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('get-terminal-city', (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static findTerminalCity(payload: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('find-terminal-city', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static findTerminalChildCity(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('find-terminal-child-city', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static setTerminalCity(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('set-terminal-city', payload);
    });
  }

  static setTerminal(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('set-terminal', payload);
    });
  }

  static refTerminal(payload: {}): Promise<any> {
    return new Promise(async () => {
      socket.emit('ref-terminal', payload);
    });
  }

  static getFiltersTerminals(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('get-filters-terminals', payload, (response: any) => {
        try {
          console.log(response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getTerminal(param?: any): Promise<any> {
    return apiAxios.post("terminals/get-terminal", param);
  }

  static findTerminalOne(id: string | number): Promise<any> {
    return apiAxios.get(`terminals/${id}`);
  }

  static deleteTerminal(payload: IParamRestDelete): Promise<any> {
    return apiAxios.delete('terminals/delete-terminal', {data: payload})
  }
}
