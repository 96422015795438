export const purchaseColumns = [  
  { title: 'Поставщик', dataIndex: 'company', key: 'company' },
  { title: 'Откуда', dataIndex: 'from', key: 'from' },
  { title: 'Кол-во', dataIndex: 'quantity', key: 'quantity' },
  { title: 'Тип', dataIndex: 'type', key: 'type' },
  { title: 'Состояние', dataIndex: 'condition', key: 'condition' },
  { title: 'Цена закупа', dataIndex: 'price', key: 'price' },
  { title: 'Вариант оплаты', dataIndex: 'paymentOption', key: 'paymentOption' },
];

export const subleaseColumns = [
  { title: 'Поставщик', dataIndex: 'company', key: 'company' },
  { title: 'Откуда', dataIndex: 'from', key: 'from' },
  { title: 'Куда', dataIndex: 'to', key: 'to' },
  { title: 'Тип', dataIndex: 'type', key: 'type' },
  { title: 'Кол-во', dataIndex: 'quantity', key: 'quantity' },
  { title: 'Состояние', dataIndex: 'condition', key: 'condition' },
  { title: 'Ставка', dataIndex: 'rate', key: 'rate' },
  { title: 'Дни', dataIndex: 'days', key: 'days' },
  { title: 'СНП', dataIndex: 'snp', key: 'snp' },
];

export const ktkColumns = [
  { title: '№ КТК', dataIndex: 'number', key: 'number' },
  { title: 'Откуда', dataIndex: 'from', key: 'from' },
  { title: 'Кол-во', dataIndex: 'quantity', key: 'quantity' },
  { title: 'Тип', dataIndex: 'type', key: 'type' },
  { title: 'Состояние', dataIndex: 'condition', key: 'condition' },
  { title: 'Остаточная стоимость', dataIndex: 'remainingCost', key: 'remainingCost' },
  { title: 'Терминал', dataIndex: 'terminal', key: 'terminal' },
];
