import { apiAxios } from '../api/config';
import socket from '../socket';
import { IComments, IContainer, IDeal } from '../store/sales';
import { IParamRestDelete } from '../types';

export const GENERATE_ID_DEAL_SALES = 'generate-id-deal-sales';
export const GET_FILTERS_DEAL_SALES = 'get-filters-deal-sales';
export const GET_DEALS_SALES = 'get-deals-sales';
export const GET_DEALS_SALES_IDS = 'get-deals-sales-ids';
export const SET_DEAL_SALES = 'set-deal-sales';
export const DELETE_DEAL_SALES = 'delete-deal-sales';

export class Rest_deal {
  static generateIdDeal(payload: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(GENERATE_ID_DEAL_SALES, payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getFiltersDeal(param?: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(GET_FILTERS_DEAL_SALES, param, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static getContainerDealStatuses(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('get-dealContainer-statusAll', null, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getDealsIds(param?: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(GET_DEALS_SALES_IDS, param, (response: any, req: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static setDeals(payload: {
    deal: IDeal;
    containers: IContainer[];
    comments: IComments[];
  }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(SET_DEAL_SALES, payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static findAllCommentsDeal(id: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('find-all-comments-deal-sales', id, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getDeals(param?: any): Promise<any> {
    return apiAxios.post('sales/get-deals-sales', param);
  }

  static findOneDeal(id: string | number): Promise<any> {
    return apiAxios.get(`sales/${id}`);
  }

  static deleteDeal(payload: IParamRestDelete): Promise<any> {
    return apiAxios.delete('sales/delete-deal-sales', { data: payload });
  }
}
