import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Flex, Select, Typography } from 'antd';
import { Utils } from '../../utils';
import { useParamsHistory } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { setTypeUpdateCurrency } from '../../store/types';
import { ICurrency } from '../../types';

const mapperCurrency = (arr: any, item: {[key: string]: any}) => {
  const data = arr?.map((cur: any) => {
  const result = Object.create(null);
  if (item?.[cur?.['symbol']]) {
    result.current = item?.[cur?.['symbol']];
  }
  return {...cur, ...result};
}
)
return {current: item, data}
};

export const Course: React.FC = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {location} = useParamsHistory();
  const {pathname} = location;
  const currency = useSelector(
    (state: RootState) => state.types.dataTypeCurrency,
  );
  const currencyCurrent = useSelector(
    (state: RootState) => state.types.currencyCurrent,
  );
  const [value, setValue] = useState<any>()
  const [current, setCurrent] = useState<any>('')

  useEffect(() => {
    if (!currencyCurrent && currency?.length) {
      Utils.getCourse().then((res) => {
        dispatch(setTypeUpdateCurrency(mapperCurrency(currency, res.options)))
      });
      return;
    }
    if (!current && currencyCurrent) {
      const defaultCurrency = currency?.find((item) => item?.symbol === 'USD');
        setValue(defaultCurrency);
        setCurrent(getCurrent(defaultCurrency));
    }
  }, [currencyCurrent, JSON.stringify(currency)]);

  useEffect(() => {
    if (!value && currencyCurrent) {
      const defaultCurrency = currency?.find((item) => item?.symbol === 'USD');
      setValue(defaultCurrency);
    }
  }, [pathname]);

  const getCurrent = (value?: ICurrency) => typeof value?.current === 'number' || typeof value?.current === 'string' ? value.current + ' RUB' : t('нет данных');

  const onSelect = (id: any, opt: any) => {
    setValue(opt);
    setCurrent(getCurrent(opt));
  };

  return (
    <Flex justify='space-between' align="center" gap={20} style={{minWidth: '300px'}}>
      <Typography.Paragraph style={{ margin: 0 }}>
        {t('Курс')}
      </Typography.Paragraph>
      <Typography.Paragraph strong style={{ margin: 0, minWidth: '50px' }}>
        {current}
      </Typography.Paragraph>
      <Select
        defaultValue={value}
        value={value}
        style={{ maxWidth: '150px', width: '150px' }}
        options={currency?.filter((item) => item?.symbol !== 'RUB')}
        onChange={onSelect}
      />
    </Flex>
  );
};
