import React from 'react';

import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import Comment from '../../list/Comment';
import RouteList from '../../list/RouteList';
import style from '../../../style.module.scss';
import { ITabView } from '../../../../../../store/sales';
import { COMPANY, CONTACT } from '../../../../../../constants';
import ScrollList from '../../../../../../components/ui/ScrollList';
import { getSearchLink } from '../../../../../../helpers/link-helper';
import ViewRow from '../../../../../../components/ui/List/ViewRow/ViewRow';

const TabDeal: React.FC<ITabView> = ({ deal }) => {
  const { t } = useTranslation();
  const refComments = React.useRef<HTMLDivElement>(null);
  const comments = deal?.comments || [];
  const routes = deal?.route || [];
  const width = refComments?.current
    ? refComments.current.getBoundingClientRect().width
    : '';
  const company = (
    <ViewRow
      title={t('Компания')}
      subtitle={deal?.my_company}
      isDivider
      link={
        deal?.my_company_id
          ? getSearchLink({ key: COMPANY, id: deal?.my_company_id, noUrl: true })
          : ''
      }
    />
  );
  const client = (
    <ViewRow
      title={t('Контрагент')}
      subtitle={
        deal?.contact
          ? [
              {
                text: deal?.company,
                link: deal?.company_id
                  ? getSearchLink({ key: COMPANY, id: deal?.company_id, noUrl: true })
                  : '',
              },
              {
                text: deal?.contact?.fio_contact || '',
                link: deal?.contact_id
                  ? getSearchLink({ key: CONTACT, id: deal?.contact_id, noUrl: true })
                  : '',
              },
            ]
          : deal?.company
      }
      isDivider
      link={
        deal?.company_id
          ? getSearchLink({ key: COMPANY, id: deal?.company_id, noUrl: true })
          : ''
      }
    />
  );

  return (
    <Flex vertical style={{ width: '100%' }}>
      <ViewRow title={t('Название')} subtitle={deal?.name_deal} isDivider />
      {deal?.type_deal_id === 3 ? (
        <>
          {company}
          {client}
        </>
      ) : (
        <>
          {client}
          {company}
        </>
      )}
      <ViewRow
        title={t('Номер счета')}
        subtitle={deal?.account_num}
        isDivider
      />
      <ViewRow title={t('НДС')} subtitle={deal?.nds} isDivider />
      <ViewRow title={t('Валюта')} subtitle={deal?.currency} isDivider />

      <Typography.Paragraph className={style['subtitle']}>
        {t('Маршрут')}
      </Typography.Paragraph>
      <div className={style['comments-box']}>
        {!!routes?.length && (
          <ScrollList
            setLastItemReached={(v) => v}
            height={100}
            totalTableActivity={1}
            data={routes}
            tableRef={refComments}
            paginationActivity={() => {}}
            isTable={false}
            endMessage={null}
          >
            <RouteList array={routes} width={width} />
          </ScrollList>
        )}
      </div>
      <Typography.Paragraph className={style['subtitle']}>
        {t('Комментарий')}
      </Typography.Paragraph>
      <div className={style['comments-box']}>
        {!!comments?.length && (
          <ScrollList
            setLastItemReached={(v) => v}
            height={comments?.length >= 3 ? 200 : (comments?.length || 1) * 80}
            totalTableActivity={1}
            data={comments}
            tableRef={refComments}
            paginationActivity={() => {}}
            isTable={false}
            endMessage={null}
          >
            {comments.map((comment, index) => (
              <Comment
                key={index}
                comment={comment}
                isDelete={false}
                deleteComment={() => {}}
              />
            ))}
          </ScrollList>
        )}
      </div>
    </Flex>
  );
};

export default TabDeal;
