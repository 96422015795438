import { createContext, FC, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Flex, Form, Input, notification, Typography } from 'antd';

import Icon from '@ant-design/icons/lib/components/Icon';
import { NotificationPlacement } from 'antd/es/notification/interface';

import style from './LoginForm.module.scss';
import Button from '../ui/Button/Button';
import { setAuth } from '../../store/users';
import { useStoreDispatch } from '../../store';
import { RouteNames } from '../../router/names';
import { Checkbox } from '../ui/Checkbox/Checkbox';
import {
  EmailInputIcon,
  closeEyeIcon,
  LockIcon,
  openEyeIcon,
} from '../../assets/image/svg';
import socket from '../../socket';
import { UserRequest } from '../../services/rest_users';
import { TOK_EN, Utils } from '../../utils';

interface IClickRegistration {
  clickRegistration: () => void;
}

const Context = createContext({ name: 'Default' });
const LoginForm: FC<IClickRegistration> = ({ clickRegistration }) => {
  const router = useHistory();
  const dispatch = useStoreDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [choice, setChoice] = useState<boolean>(false);

  const onFinish = async (data: any) => {
    if (socket.disconnected) {
      openNotification('topRight', 'Сервер недоступен, попробуйте позже');
      return;
    }
    const result = await UserRequest.loginAxios(data);
    const jRes = result.data;
    await Utils.setStorage(TOK_EN, jRes.data)

    if (jRes.success === 0) {
      dispatch(setAuth(false));
      openNotification('topRight', jRes.message);
    } else {
      dispatch(setAuth(true));
      router.replace(RouteNames.CRM);
    }
  };
  const openNotification = (
    placement: NotificationPlacement,
    message: string,
  ) => {
    api.error({
      message: `Ошибка`,
      description: <Context.Consumer>{({ name }) => message}</Context.Consumer>,
      placement,
    });
  };

  const clickForgetPassword = () => {
    router.push(RouteNames.FORGET_PASSWORD);
  };

  return (
    <div className={style.container}>
      {contextHolder}
      <Flex justify="center" vertical>
        <h1
          style={{
            marginBottom: 30,
            textAlign: 'center',
            fontFamily: 'Manrope',
            fontSize: 32,
            fontWeight: 700,
          }}
        >
          Добро пожаловать!
        </h1>
        <Form
          name="normal_login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите свое имя пользователя!',
                whitespace: true
              },
            ]}
          >
            <Input
              prefix={<EmailInputIcon />}
              placeholder="Email"
              style={{ marginBottom: 10, paddingRight: '35px' }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Пожалуйста, введите свой пароль!', whitespace: true  },
            ]}
          >
            <Input.Password
              className={style.input__password}
              prefix={<Icon component={LockIcon} />}
              type="password"
              placeholder="Пароль"
              iconRender={(visible) =>
                visible ? (
                  <Icon component={openEyeIcon} />
                ) : (
                  <Icon component={closeEyeIcon} />
                )
              }
            />
          </Form.Item>
          <Form.Item style={{marginBottom: '5px'}}>
            <Flex vertical align="end">
              <div>
                <Typography.Link onClick={clickForgetPassword}>
                  Забыли пароль
                </Typography.Link>
              </div>
            </Flex>
            <Flex gap={'5px'} align='center'>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox isChoice={choice} onChange={setChoice} />
              </Form.Item>
              <Typography.Text>Запомнить</Typography.Text>
            </Flex>
          </Form.Item>
          <Form.Item>
            <Flex align={'center'} vertical gap={'15px'}>
              <Button type="primary" style={{ width: '100%' }}>
                Вход
              </Button>
              <Typography.Link
                onClick={clickRegistration}
              >
                или зарегистрируйтесь
              </Typography.Link>
            </Flex>
          </Form.Item>
        </Form>
      </Flex>
    </div>
  );
};

export default LoginForm;
