export const EDIT = 'edit';
export const CREATE = 'create';
export const VIEW = 'view';
export const HISTORY = 'history';
export const VIEW_SEARCH = '/view/';

export const getTypeForm = (str: string) => {
  if (!str) return '';
  if (str?.includes(EDIT)) {
    return EDIT;
  }
  if (str?.includes(CREATE)) {
    return CREATE;
  }
  if (str?.includes(VIEW)) {
    return VIEW;
  }
  return '';
};

export const createMarkup = (html: string) => ({__html: html});
