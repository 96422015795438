import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  notification,
  Space,
} from 'antd';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

import socket from '../../socket';
import { Utils } from '../../utils';
import { columns } from './columns';
import { RouteNames } from '../../router/names';
import Layout from '../../components/Layouts';
import FormModalTerminal from './formModalTerminal';
import { RootState, useStoreDispatch } from '../../store';
import FormCardModalTerminal from './formCardModalTerminal';
import { SelectSearch } from '../../components/ui/SelectSearch/SelectSearch';
import { CREATE, EDIT, VIEW } from '../../helpers/string-helpers';
import { Table } from '../../components/ui/Table/Table';
import {
  useBreadcrumb,
  useGettingDataForTables,
  useMenu,
  useParamsHistory,
} from '../../hooks';
import {
  searchTerminalCity,
  setTerminal,
} from '../../store/terminals';

import '../../assets/scss/terminal.scss';
import { TERMINAL } from '../../constants';
import { Rest_terminal } from '../../services/rest_terminal';

const Index: React.FC = () => {
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [searchCity, setSearchCity] = useState<string | null>(null);
  const [city, setCity] = useState<[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalViewOpen, setIsModalViewOpen] = useState(false);
  const [isMap, setIsMap] = useState<boolean>(false);

  const [dataRow, setDataRow] = useState<{}>({});
  const dataCurrency = useSelector(
    (state: RootState) => state.types.dataTypeCurrency,
  );
  const cityData = useSelector((state: RootState) => state.terminals.dataCity);
  const [typeForm, setTypeForm] = useState<string | null>(null);

  useBreadcrumb(['Депо', 'Терминалы']);

  useMenu({
    openKey: RouteNames.DEPOT,
    activeKeys: [RouteNames.DEPOT, RouteNames.TERMINALS],
  });

  const {
    dataTable,
    optionsTable,
    setDataTable,
    updateEffectDate,
  } = useGettingDataForTables({
    table: TERMINAL,
    location,
    columns,
    fetchTables: Rest_terminal.getTerminal,
    fetchDelete: Rest_terminal.deleteTerminal
  });

  // проверка url
  useEffect(() => {
    const data = location?.state?.[TERMINAL];
    if (pathname === RouteNames.TERMINALS) {
      updateEffectDate();
      if (data) {
        if (data?.edit) {
          setIsMap(true);
          setDataRow(data.edit);
          setTypeForm(EDIT);
          setIsModalOpen(true);
          return;
        }
        setTypeForm(CREATE);
        setDataRow(data);
        setIsMap(false);
        setIsModalOpen(true);
        return;
      }
    }
    return history.replace(RouteNames.TERMINALS);
  }, [pathname]);

  const onEdit = () => {
    setIsModalViewOpen(false);
    setIsModalOpen(true);
    setIsMap(true);
    setTypeForm(EDIT);
  };
  const onView = () => {
    setIsModalOpen(false);
    setIsMap(true);
    setTypeForm(VIEW);
    setIsModalViewOpen(true);
  };

  useEffect(() => {
    socket.once('res-search-terminal-city', (response: any) => {
      setCity(response);
    });
    socket.on('res-set-terminal', resSetTerminal);
    socket.on('res-delete-terminal', resHandleDeleteRow);

    return () => {
      socket.off('res-set-terminal', resSetTerminal);
      socket.off('res-delete-terminal', resHandleDeleteRow);
    };
  }, []);

  const resHandleDeleteRow = (response: any) => {
    setDataTable((prevData) => {
      // Проверьте, что response является массивом, если это необходимо
      if (!Array.isArray([response])) {
        console.error('Invalid response data:', [response]);
        return prevData;
      }

      // Предположим, что response это объект с обновленными данными
      const updatedItem = response;
      return prevData.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item,
      );
    });
  };

  const resSetTerminal = (responses: any) => {
    const response = JSON.parse(responses);
    if (response.success === 0) {
      Utils.InfoOpenNotification('topRight', response.message, 20, api);
      return; // Early return to avoid further processing
    }
    response.data.stock = response.data.stock[0].add_stock;
    response.data.stock_array = [{ add_stock: response.data.stock }];
    // Handle successful response
    if (typeForm === 'create') {
      // Add new data to the beginning of the list
      const updatedData = [response.data, ...dataTable];
      setDataTable(updatedData);
    } else {
      // Update existing data in the list
      setDataTable((prevData) => {
        const index = prevData.findIndex(
          (item) => item.id === response.data.id,
        );

        if (index === -1) {
          return prevData; // Return previous data if the item is not found
        }

        // Update the item at the found index
        return [
          ...prevData.slice(0, index),
          { ...prevData[index], ...response.data },
          ...prevData.slice(index + 1),
        ];
      });
    }

    // Close the modal and show a notification
    setIsModalOpen(false);
    Utils.openNotification(
      'topLeft',
      typeForm === 'create' ? 'Добавлено' : 'Обновлено',
      api,
    );
  };

  const onChangeSelect = (event: any, option: any) => {
    // console.log(option);
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCity(event.target.value.toLocaleLowerCase());
    if (event.target.value === '') {
      setSearchCity(null);
    }
    dispatch(searchTerminalCity(event.target.value.toLocaleLowerCase())).then(
      (response) => {
        setCity(response.payload);
      },
    );
  };

  const onCreate = () => {
    form.resetFields();
    setDataRow({});
    setTypeForm('create');
    setIsModalOpen(true);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleCancelViewModal = () => {
    setIsModalViewOpen(false);
    form.resetFields();
  };

  const onFinishAddTerminal = (payload: any) => {
    Object.assign(payload, { type_form: typeForm });
    dispatch(setTerminal(payload));
  };

  const handleShowEditForm = (row: { id: number }) => {
    setDataRow(row);
    setIsMap(true);
    setTypeForm('update');
    setIsModalOpen(true);
  };

  const onRow = (row: any) => {
    setDataRow(row);
    setIsModalViewOpen(true);
  };

  return (
    <Layout>
      {contextHolder}
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <div className={'drawer-title'}>{t('TerminalTable')}</div>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={onCreate}
          />
          <SelectSearch
            dataSource={searchCity !== null ? city : cityData}
            onChangeSelect={onChangeSelect}
            dropdownRender={onNameChange}
            placeholder={t('Поиск')}
            type={'page'}
            style={{ width: 200 }}
            defaults={'Екатеринбург'}
            hiddenBtn={false}
            onClick={onCreate}
          />
          <Input
            allowClear
            prefix={<SearchOutlined />}
            className={'input header-depo'}
            placeholder={t('Поиск')}
          />
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          {...optionsTable}
          dataSource={dataTable}
          columns={columns}
          height={'calc(-162px + 100vh)'}
          style={{ overflow: 'auto hidden' }}
          onClickLink={onRow}
          editRow={handleShowEditForm}
          onRow={onRow}
        />
      </Space>
      <Modal
        maskClosable={false}
        closable={false}
        open={isModalOpen}
        onCancel={handleCancelModal}
        style={{ top: 30 }}
        width={548}
        footer={null}
      >
        <Divider style={{ marginTop: 0, marginBottom: 16 }} />
        <Flex vertical className={'modal-content'}>
          <FormModalTerminal
            title={
              typeForm === 'create'
                ? 'Добавить терминал'
                : 'Редактировать терминал'
            }
            isCopy
            onView={onView}
            form={form}
            dataCityRedux={cityData}
            defaultCity={'Екатеринбург'}
            showMapEdit={isMap}
            dataCurrency={dataCurrency}
            onFinishAddTerminal={onFinishAddTerminal}
            dataRow={dataRow}
            typeForm={typeForm}
            onClose={handleCancelModal}
          />
        </Flex>
      </Modal>
      <Modal
        open={isModalViewOpen}
        maskClosable={false}
        onCancel={handleCancelViewModal}
        closable={false}
        style={{ top: 30 }}
        footer={null}
      >
        <FormCardModalTerminal
          dataRow={dataRow}
          isCopy
          onClose={handleCancelViewModal}
          onEdit={onEdit}
        />
      </Modal>
    </Layout>
  );
};

export default Index;
