import { Key, ReactNode, useRef } from 'react';

import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Icon from '@ant-design/icons';

import { RootState, useStoreDispatch } from '../../store';
import style from './style.module.scss';
import { useParamsHistory } from '../../hooks';
import { RouteNames } from '../../router/names';
import {
  depoDefaultIcon,
  financeDefaultIcon,
  logisticsDefaultIcon,
  rentDefaultIcon,
  saleDefaultIcon,
  userDefaultIcon,
} from '../../assets/image/svg';
import { setOpenKeys } from '../../store/menu';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItem[] | null,
  style?: boolean | { display: string },
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    style,
    type,
  } as MenuItem;
}

// заменить пропсы
const Menus = (props: any) => {
  const dispatch = useStoreDispatch();
  const ref = useRef(null);
  const activeKeys: string[] = useSelector(
    (state: RootState) => state.menu.activeKeys,
  );
  const openKeys: string[] = useSelector(
    (state: RootState) => state.menu.openKeys,
  );
  const { t } = useTranslation();
  const { history } = useParamsHistory();

  const handleClickMenu = (keys: string[]) => {
    dispatch(setOpenKeys(keys));
  };

  const items: MenuProps['items'] = [
    getItem(
      <Link className={style.menu__link} to={RouteNames.CRM}>
        CRM
      </Link>,
      RouteNames.CRM,
      <Icon
        className={style.menu__icon}
        onClick={(e) => {
          history.replace(RouteNames.CRM);
        }}
        component={userDefaultIcon}
      />,
      null,
      !props.isCompanys && { display: 'none' },
    ),
    getItem(
      t('Депо'),
      RouteNames.DEPOT,
      <Icon className={style.menu__icon} component={depoDefaultIcon} />,
      [
        getItem(
          <Link
            className={classnames(
              style['menu__link'],
              style['menu__link_child'],
            )}
            to={RouteNames.TERMINALS}
          >
            {t('Терминалы')}
          </Link>,
          RouteNames.TERMINALS,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
        getItem(
          <Link
            className={classnames(
              style['menu__link'],
              style['menu__link_child'],
            )}
            to={RouteNames.CONTAINERS}
          >
            {t('Контейнеры')}
          </Link>,
          RouteNames.CONTAINERS,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
        getItem(
          <Link
            className={classnames(
              style['menu__link'],
              style['menu__link_child'],
            )}
            to={RouteNames.RELEASES}
          >
            {t('Релизы')}
          </Link>,
          RouteNames.RELEASES,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
      ],
      !props.isStaff && { display: 'none' },
    ),
    getItem(
      t('Продажи'),
      RouteNames.SALES,
      <Icon className={style.menu__icon} component={saleDefaultIcon} />,
      [
        getItem(
          <Link
            className={classnames(
              style['menu__link'],
              style['menu__link_child'],
            )}
            to={RouteNames.SALES_DEAL}
          >
            {t('Сделки')}
          </Link>,
          RouteNames.SALES_DEAL,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
      ],
      !props.isStaff && { display: 'none' },
    ),
    getItem(
      t('Rent'),
      RouteNames.RENT,
      <Icon className={style.menu__icon} component={rentDefaultIcon} />,
      [
        getItem(
          <Link
            className={classnames(
              style['menu__link'],
              style['menu__link_child'],
            )}
            to={RouteNames.RENT_DEAL}
          >
            {t('Сделки')}
          </Link>,
          RouteNames.RENT_DEAL,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
        getItem(
          <Link
            className={classnames(
              style['menu__link'],
              style['menu__link_child'],
            )}
            to={RouteNames.RENT_REQUESTS}
          >
            {t('Запросы аренды')}
          </Link>,
          RouteNames.RENT_REQUESTS,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
      ],
      !props.isStaff && { display: 'none' },
    ),
    getItem(
      t('Логистика'),
      '/logistics',
      <Icon className={style.menu__icon} component={logisticsDefaultIcon} />,
      null,
      !props.isStaff && { display: 'none' },
    ),
    getItem(
      t('Финансы'),
      RouteNames.FINANCE,
      <Icon className={style.menu__icon} component={financeDefaultIcon} />,
      [
        getItem(
          <Link
            className={classnames(
              style['menu__link'],
              style['menu__link_child'],
            )}
            to={RouteNames.FINANCE_BILLING}
          >
            {t('Выставление счета')}
          </Link>,
          RouteNames.FINANCE_BILLING,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
        getItem(
          <Link
            className={classnames(
              style['menu__link'],
              style['menu__link_child'],
            )}
            to={RouteNames.FINANCE_PAYMENT}
          >
            {t('Оплата счета')}
          </Link>,
          RouteNames.FINANCE_PAYMENT,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
      ],
      !props.isStaff && { display: 'none' },
    ),
  ];

  return (
    <Menu
      ref={ref}
      className={style.menu}
      mode="inline"
      selectedKeys={activeKeys}
      openKeys={openKeys}
      items={items}
      onOpenChange={handleClickMenu}
    />
  );
};

export default Menus;
