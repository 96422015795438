import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Flex, Typography } from 'antd';

import { pathsCrm } from './constants';
import style from './style.module.scss';
import { useStoreDispatch } from '../../store';
import { CREATE } from '../../helpers/string-helpers';
import { TableAgreements } from './components/TableAgreements';
import {
  defaultAgreement,
  setIsOpenDrawer,
  setOneAgreement,
  setTypeForm,
} from '../../store/agreement';

interface IAgreementsCRM {
  title: string;
  activeKey: string;
}

export const AgreementsCRM: React.FC<IAgreementsCRM> = ({
  activeKey,
  title,
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const onCreate = () => {
    dispatch(setOneAgreement(defaultAgreement));
    dispatch(setTypeForm(CREATE));
    dispatch(setIsOpenDrawer(true));
  };

  return (
    <div className={style.container}>
      <Flex
        justify={'space-between'}
        align={'flex-start'}
        style={{ margin: '20px 28px 0px' }}
      >
        <Typography.Title style={{ textTransform: 'capitalize' }} level={2}>
          {t(title)}
        </Typography.Title>
        <Flex gap={16}>
          <Button onClick={onCreate} type={'primary'}>
            {t('Создать')}
          </Button>
        </Flex>
      </Flex>
      <TableAgreements paths={pathsCrm} activeKey={activeKey} />
    </div>
  );
};
