// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_modal__b7Gzm {
  color: red;
}
.style_modal__b7Gzm .ant-message-custom-content {
  align-items: flex-start !important;
}
.style_modal__b7Gzm .ant-message-custom-content .anticon {
  margin-top: 2px !important;
}
.style_modal__list__yykvR {
  max-width: 700px;
}
.style_modal__list__yykvR .ant-list-header {
  padding: 0 !important;
  border: none;
}
.style_modal__list__yykvR .ant-list-item {
  border-block-end: none !important;
  margin-top: 0;
  padding-top: 0;
}
.style_modal__list__yykvR .ant-list-item:last-of-type {
  padding-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/hooks/useModalMessage/style.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AACI;EACE,kCAAA;AACN;AAAM;EACE,0BAAA;AAER;AAEE;EACE,gBAAA;AAAJ;AAEM;EACE,qBAAA;EACA,YAAA;AAAR;AAEM;EACE,iCAAA;EACA,aAAA;EACA,cAAA;AAAR;AAEM;EACE,iBAAA;AAAR","sourcesContent":[".modal {\n  color: red;\n  :global {\n    .ant-message-custom-content {\n      align-items: flex-start !important;\n      .anticon {\n        margin-top: 2px !important;\n      }\n    }\n  }\n  &__list {\n    max-width: 700px;\n    :global {\n      .ant-list-header {\n        padding: 0 !important;\n        border: none;\n      }\n      .ant-list-item {\n        border-block-end: none !important;\n        margin-top: 0;\n        padding-top: 0;\n      }\n      .ant-list-item:last-of-type {\n        padding-bottom: 0;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `style_modal__b7Gzm`,
	"modal__list": `style_modal__list__yykvR`
};
export default ___CSS_LOADER_EXPORT___;
