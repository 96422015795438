import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';
import { delay } from 'lodash';
import { AutoComplete, Flex, Form, Input, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import IconButton from '../ui/IconButton';
import { useStoreDispatch } from '../../store';
import { findExecutor } from '../../store/contact';
import { IUsers } from '../../types/users';
import { ChatItem } from './components/ChatItem';

interface IChat {
  user: IUsers;
}

export const Chat: React.FC<IChat> = ({ user }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const dispatch = useStoreDispatch();
  const [dataUsers, setDataUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const message: string = Form.useWatch('message', {
    form,
    preserve: true,
  });
  const messages: any[] = Form.useWatch('messages', {
    form,
    preserve: true,
  });
  const searchValue: string = Form.useWatch('searchValue', {
    form,
    preserve: true,
  });
  const toUser: any = Form.useWatch('toUser', {
    form,
    preserve: true,
  });

  useEffect(() => {
    form.resetFields();
  }, []);

  const initialValue = {
    message: '',
    messages: [],
    searchValue: '',
    toUser: null,
  };

  const handlerSubmit = () => {
    // отправка
    const iso = new Date().toISOString();
    const comment = {
      id: null,
      text: message,
      user: {
        avatar: user?.avatar,
        fio: user?.fio,
      },
      toUser,
      user_id: user?.id,
      updatedAt: iso,
      createdAt: iso,
    };

    form.setFieldValue('messages', [comment, ...messages]);
    form.setFieldValue('message', '');
    form.setFieldValue('searchValue', '');
    form.setFieldValue('toUser', null);
    setDataUsers([]);
  };

  const onOpenChat = () => {
    setIsOpen(true);
  };

  const onCloseChat = () => {
    setIsOpen(false);
  };

  const deleteMessage = (index: number) => {
    const result = messages.filter((_, i) => i !== index);
    form.setFieldValue('messages', result);
  };

  const handleSelect = useCallback((id: string, user: any) => {
    const [tag] =
      typeof user?.email === 'string' && /@/.test(user?.email)
        ? user.email.split('@')
        : [''];
    const info = {
      id,
      tag: `@${tag}`,
      fio: user?.fio ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
    };
    form.setFieldValue('toUser', info);
    form.setFieldValue('searchValue', user?.fio ?? '');
  }, []);

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const item = dataUsers.find(
      (user: any) => user?.label === evt.target.value,
    );

    if (!item) {
      form.setFieldValue('toUser', null);
      form.setFieldValue('searchValue', '');
    }
  };
  const handleSearch = (text: string) => {
    if (text?.length >= 2) {
      delay(() => {
        dispatch(findExecutor(text.toLocaleLowerCase()))
          .then((res) => {
            const result = res.payload?.length
              ? res.payload.map((item: any) => ({
                  label: item?.label,
                  value: item?.label,
                  id: item?.id,
                  phone: item?.phone,
                  email: item?.email,
                  fio: item?.fio,
                }))
              : [];
            setDataUsers(result);
          })
          .catch((e) => {
            console.error(e);
            setDataUsers([]);
          });
      }, 1000);
    }
  };

  return (
    <Form
      className={styles.chat}
      noValidate
      autoComplete="off"
      form={form}
      initialValues={{ ...initialValue }}
      scrollToFirstError
      component={'div'}
    >
      <Flex justify="space-between">
        <Typography.Text className={styles['label-subtitle']}>
          {t('Чат')}
        </Typography.Text>
        <Typography.Link onClick={onOpenChat}>
          {t('Развернуть')}
        </Typography.Link>
      </Flex>
      <div hidden={isOpen} className={classnames(styles['chat__control'])}>
        <div
          className={classnames('all-custom-v-scroll', styles['chat__view'])}
        >
          {messages?.map((item, index) => (
            <ChatItem
              key={index}
              comment={item}
              onDelete={() => deleteMessage(index)}
            />
          ))}
        </div>
        <div className={styles['input-box']}>
          <Form.Item name="searchValue" noStyle>
            <AutoComplete
              popupClassName="certain-category-search-dropdown"
              style={{ width: '100px' }}
              allowClear
              placeholder={t('Кому')}
              options={dataUsers}
              onSearch={handleSearch}
              onSelect={handleSelect}
              onBlur={handleSearchBlur}
              title={searchValue}
            />
          </Form.Item>
          <Form.Item name="message" noStyle>
            <Input
              className={styles['input-box__input']}
              suffix={
                <IconButton
                  disabled={!message}
                  onClick={handlerSubmit}
                  className={styles.button}
                  iType="chat"
                />
              }
              placeholder={t('Введите комментарий')}
              allowClear
            />
          </Form.Item>
        </div>
      </div>
      <Modal
        className={styles.modal}
        open={isOpen}
        footer={null}
        closable
        width={900}
        height={700}
        centered
        onCancel={onCloseChat}
        title={t('Чат')}
      >
        <Form
          className={styles['modal__content']}
          noValidate
          autoComplete="off"
          form={form}
          initialValues={{ ...initialValue }}
          scrollToFirstError
          component={'div'}
        >
          <div
            className={classnames('all-custom-v-scroll', styles['modal__view'])}
          >
            {messages?.map((item, index) => (
              <ChatItem
                key={index}
                isWidth
                comment={item}
                onDelete={() => deleteMessage(index)}
              />
            ))}
          </div>
          <div className={styles['input-box']}>
            <Form.Item name="searchValue" noStyle>
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                style={{ width: '100px' }}
                allowClear
                placeholder={t('Кому')}
                options={dataUsers}
                onSearch={handleSearch}
                onSelect={handleSelect}
                onBlur={handleSearchBlur}
                title={searchValue}
              />
            </Form.Item>
            <Form.Item name="message" noStyle>
              <Input
                className={styles['input-box__input']}
                suffix={
                  <IconButton
                    disabled={!message}
                    onClick={handlerSubmit}
                    className={styles.button}
                    iType="chat"
                  />
                }
                placeholder={t('Введите комментарий')}
                allowClear
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </Form>
  );
};
