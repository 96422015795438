import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  Button,
  Flex,
  Form,
  Input,
  Modal,
  notification,
  Space,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import socket from '../../socket';
import { Utils } from '../../utils';
import style from './style.module.scss';
import { pathsDepot } from './constants';
import { columns } from './components/columns';
import { RouteNames } from '../../router/names';
import Layout from '../../components/Layouts';
import { IContainers } from '../../types/containers';
import { FormDeal } from '../sales/deals/components';
import { setContainer } from '../../store/containers';
import { defaultValuesDeal } from '../../store/sales';
import { FormRelease } from './components/FormRelease';
import { ViewRelease } from './components/ViewRelease';
import FormContainer from '../containers/formContainer';
import { RootState, useStoreDispatch } from '../../store';
import { PdfReleaseEN, PdfReleaseRU } from './components/pdf';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import { Table } from '../../components/ui/Table/Table';
import {
  useBreadcrumb,
  useGettingDataForTables,
  useMenu,
  useParamsHistory,
} from '../../hooks';
import {
  CREATE,
  EDIT,
  HISTORY,
  VIEW,
} from '../../helpers/string-helpers';
import { Rest_releases } from '../../services/rest_releases';
import {
  defaultValues,
  IRelease,
  resetContainer,
  resetState,
  setIsContainerDrawer,
  setOneRelease,
} from '../../store/releases';
import { RELEASE } from '../../constants';
import CustomHistory from '../../components/CustomHistory';
import { historyConfigList } from './components/history';
import { mapperRelease } from './components/utils';

export const Releases = () => {
  useBreadcrumb(['Депо', 'Релизы']);
  useMenu({
    openKey: RouteNames.DEPOT,
    activeKeys: [RouteNames.DEPOT, RouteNames.RELEASES],
  });

  const dispatch = useStoreDispatch();
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { history, location } = useParamsHistory();
  const [api, contextHolder] = notification.useNotification();
  const [typeForm, setTypeForm] = useState(EDIT);
  const [returnTypeForm, setReturnTypeForm] = useState<'view' | 'edit'>(EDIT);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isCreateDeal, setIsCreateDeal] = useState(false);
  const [containersDeal, setContainersDeal] = useState<any[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [lang, setLang] = useState<'ru' | 'en'>('ru');
  const { pathname } = location;
  const release = useSelector<RootState>(
    (state) => state.releases.release,
  ) as IRelease;

  const isContainerDrawer = useSelector<RootState>(
    (state) => state.releases.isContainerDrawer,
  ) as boolean;
  const newContainer = useSelector(
    (state: RootState) => state.releases.newContainer as IContainers,
  );

  const {
    dataTable,
    optionsTable,
    updateDate,
    updateEffectDate,
  } = useGettingDataForTables({
    table: RELEASE,
    location,
    columns,
    mapperData: (data) => data?.map(mapperRelease),
    fetchTables: Rest_releases.getReleases,
    fetchDelete: Rest_releases.deleteRelease,
    checkDeletionCancellation: (row) => ({isDelete: !row?.delete, message: t('Удаленный релиз нельзя восстановить!')})
  });

  const handleSearch = (val: string) => {
    setSearchValue(val);
  };

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => {
      setSearchColumn((prev) =>
        prevActiveButtons[option.key]
          ? prev.filter((item: string) => item !== option.dataIndex)
          : [...prev, option.dataIndex],
      );
      return {
        ...prevActiveButtons,
        [option.key]: !prevActiveButtons[option.key],
      };
    });
  };

  useEffect(() => {
    setIsOpenDrawer(false);
    if (pathname === pathsDepot.parent) {
      dispatch(resetState());
      updateEffectDate();
      const row = location?.state?.[RELEASE];
      if (row) {
        if (row?.edit) {
          setTypeForm(EDIT);
          dispatch(setOneRelease(row.edit));
          setIsOpenDrawer(true);
          return;
        }
        setTypeForm(CREATE);
        dispatch(
          setOneRelease({
            ...defaultValuesDeal,
            ...row,
          }),
        );
        setIsOpenDrawer(true);
        return;
      }
    }
    return history.replace(RouteNames.RELEASES);
  }, [pathname]);

  const closeContainerDrawer = () => {
    dispatch(setIsContainerDrawer(false));
    dispatch(resetContainer(null));
    setIsCreateDeal(false);
  };

  const openCreateDeal = ({ path, state }: { path: string; state: any }) => {
    history.push(path, state);
    closeContainerDrawer();
  };

  const onFinish = (data: any) => {
    const handlerNewContainer = (response: any) => {
      if (JSON.parse(response)?.message) {
        Utils.InfoOpenNotification(
          'topRight',
          JSON.parse(response).message,
          3,
          api,
        );
        return;
      }
      if (JSON.parse(response)?.data?.[0]) {
        Utils.InfoOpenNotification('topLeft', 'Добавлено', 3, api);
        dispatch(resetContainer(JSON.parse(response).data[0]));
        dispatch(setIsContainerDrawer(false));
        form.resetFields();
      }
    };
    socket.on('release-set-container', handlerNewContainer);
    data.notification = 'release-set-container';
    dispatch(setContainer(data)).finally(() => {
      socket.off('set-container');
      socket.off('release-set-container');
    });
  };

  const onRow = (row: IRelease) => {
    setTypeForm(VIEW);
    dispatch(setOneRelease(row));
    setIsOpenDrawer(true);
  };

  const handleShowEditForm = (row: IRelease) => {
    setTypeForm(EDIT);
    dispatch(setOneRelease(row));
    setIsOpenDrawer(true);
  };

  const onCloseDrawer = (res?: boolean) => {
    setIsOpenDrawer(false);
    setTypeForm(CREATE);
    dispatch(setOneRelease(defaultValues));
    if (res) {
      updateDate();
    }
  };
  const onClose = () => {
    onCloseDrawer(false)
  };

  const onCreate = () => {
    dispatch(setOneRelease(defaultValues));
    setTypeForm(CREATE);
    setIsOpenDrawer(true);
  };

  const onHistory = () => {
    setTypeForm(HISTORY);
  };

  const onEdit = () => {
    setTypeForm(EDIT);
  };

  const onView = () => {
    setTypeForm(VIEW);
  };

  const onPdf = (lang: 'ru' | 'en') => {
    setLang(lang);
    setIsOpenModal(true);
  };

  const getForm = useMemo(() => {
    if (!isOpenDrawer) return null;
    switch (typeForm) {
      case VIEW:
        return (
          <ViewRelease
            isCopy
            initialValue={release}
            typeForm={typeForm}
            isOpen={isOpenDrawer}
            onPdf={onPdf}
            onClose={onCloseDrawer}
            onDeal={openCreateDeal}
            onEdit={onEdit}
            onHistory={() => {
              onHistory();
              setReturnTypeForm(VIEW);
            }}
          />
        );
      case HISTORY:
        return (
          <CustomHistory
            initialValue={release?.history}
            defaultData={[defaultValues]}
            configList={historyConfigList}
            title=''
            onReturn={() => setTypeForm(returnTypeForm)}
          />
        );
      default:
        return (
          <FormRelease
            isCopy
            initialValue={release}
            typeForm={typeForm}
            isOpen={isOpenDrawer}
            onPdf={onPdf}
            onClose={onCloseDrawer}
            onDeal={openCreateDeal}
            onEdit={onView}
            onHistory={() => {
              onHistory();
              setReturnTypeForm(EDIT);
            }}
          />
        );
    }
  }, [typeForm, isOpenDrawer]);

  return (
    <Layout>
      <div className={style.container}>
        <Flex
          gap={20}
          justify={'space-between'}
          align={'flex-start'}
          style={{ width: '100%' }}
          className={'container'}
        >
          <Flex align={'center'} gap={10}>
            <div className={'drawer-title'}>{t('Релизы')}</div>
          </Flex>
          <Flex gap={16}>
            <Button
              icon={<PlusOutlined />}
              style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
              onClick={onCreate}
            />
            <AutoComplete
              allowClear
              style={{ width: 300 }}
              options={columns}
              defaultValue={searchValue}
              popupMatchSelectWidth={false}
              onSearch={handleSearch}
              dropdownRender={() => (
                <>
                  <div
                    className={'text-card-12'}
                    style={{ padding: '10px 10px' }}
                  >
                    {t('Выбор колонок по которым будет производиться поиск')}
                  </div>
                  <Flex
                    gap={5}
                    style={{ width: 300, flexWrap: 'wrap', padding: 10 }}
                  >
                    {columns.map(
                      (option) =>
                        !!option.title && (
                          <Button
                            size={'small'}
                            key={option.key}
                            type={
                              activeButtons[Number(option.key)]
                                ? 'default'
                                : 'primary'
                            }
                            onClick={() => handleButtonClickSearch(option)}
                          >
                            {option.title}
                          </Button>
                        ),
                    )}
                  </Flex>
                </>
              )}
            >
              <Input placeholder={t('Введите текст')} />
            </AutoComplete>
          </Flex>
        </Flex>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Table
            {...optionsTable}
            className={style.container__table}
            editRow={handleShowEditForm}
            columns={columns}
            dataSource={dataTable}
            style={{ width: '100%' }}
            height={'calc(100vh - 200px)'}
            onRow={onRow}
            onClickLink={onRow}
          />

          <CustomDrawer
            isHeight
            minWidth={700}
            open={isOpenDrawer}
            onClose={onClose}
          >
            {getForm}
          </CustomDrawer>
          <CustomDrawer
            isHeight
            open={isContainerDrawer}
            onClose={closeContainerDrawer}
          >
            {isCreateDeal ? (
              <FormDeal
                typeForm={CREATE}
                title={t('Создать сделку')}
                isOpen={isContainerDrawer}
                onClose={closeContainerDrawer}
                initialValue={{
                  ...defaultValuesDeal,
                  containers: containersDeal,
                }}
              />
            ) : (
              <FormContainer
                dataRowContainer={newContainer}
                form={form}
                typeForm={CREATE}
                onFinishForm={onFinish}
                title={t('Добавить контейнер')}
              />
            )}
          </CustomDrawer>
          {!isCreateDeal && isContainerDrawer ? contextHolder : null}
          <Modal
            open={isOpenModal}
            closeIcon
            centered
            width={'60vw'}
            footer={null}
            onCancel={() => setIsOpenModal(false)}
          >
            <div style={{ scale: '0.95' }}>
              {lang === 'en' ? (
                <PdfReleaseEN data={release} />
              ) : (
                <PdfReleaseRU data={release} />
              )}
            </div>
          </Modal>
        </Space>
      </div>
    </Layout>
  );
};
