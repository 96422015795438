import * as CryptoJS from 'crypto-js';
import { NotificationPlacement } from 'antd/es/notification/interface';
import moment from 'moment/moment';
export const TOK_EN = '_in';
const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY || '';

export class Utils {
  static clearStorage() {
    localStorage.removeItem(TOK_EN);
    localStorage.removeItem('_inr');
    localStorage.removeItem('ent');
    localStorage.removeItem('vd');
  }
  static getStorage(key: string) {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return e;
    }
  }
  static removeStorage(key: string) {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      return e;
    }
  }

  static getStorageRoles() {
    try {
      return localStorage.getItem('_inr');
    } catch (e) {
      return e;
    }
  }

  static async setStorage(key: string, payload: any) {
    try {
      return localStorage.setItem(key, payload);
    } catch (e) {
      return e;
    }
  }

  static setStorageRoles(payload: string) {
    try {
      return localStorage.setItem('_inr', payload);
    } catch (e) {
      return e;
    }
  }

  static yandexCity(payload: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        'https://geocode-maps.yandex.ru/1.x/?apikey=d02fa342-535c-4955-bfd8-651249e6795b&geocode=' +
        payload +
        '&kind=district&results=10&format=json';
      fetch(url)
        .then((response) => response.text())
        .then((result) => {
          resolve(JSON.parse(result));
          localStorage.setItem('vd', result);
        })
        .catch((error) => console.error('error', error));
    });
  }
  static getCourse(): Promise<{options: {[key: string]: any}}> {
    return new Promise((resolve, reject) => {
      const url = 'https://www.cbr-xml-daily.ru/daily_json.js';
      return fetch(url)
        .then((response) => response.text())
        .then((res) => {
          const result = JSON.parse(res);
          if (result?.Valute) {
            const arr = Object.entries(result?.Valute).reduce(
              (ac : any, [key, data]: [key: string, data: any]) => {
                ac[key] = (data?.Value / data?.Nominal)?.toFixed(2);
                return ac;
              }, {}
            );
            resolve({ options: arr});
          } else {
            resolve({options: {}});
          }
        })
        .catch((error) => console.error('error', error));
    });
  }

  static requisites(inn: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party';
      let token = '0b93ed269718d155cd1f34f003fd0dcae136d5fb ';
      let options: any = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: JSON.stringify({ query: inn }),
      };

      fetch(url, options)
        .then((response) => response.text())
        .then((result) => {
          resolve(result);
          localStorage.setItem('vd', result);
        })
        .catch((error) => console.error('error', error));
    });
  }

  static requisitesBank(inn: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/bank';
      let token = '0b93ed269718d155cd1f34f003fd0dcae136d5fb ';
      let options: any = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: JSON.stringify({ query: inn }),
      };

      fetch(url, options)
        .then((response) => response.text())
        .then((result) => {
          resolve(result);
          localStorage.setItem('bn', result);
        })
        .catch((error) => console.error('error', error));
    });
  }

  static decrypt(hex: any) {
    if (CRYPTO_KEY) {
      const bytes = CryptoJS.AES.decrypt(
        hex,
        '02aab0f70eca03ff2e2397cb91076262',
      );
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return '';
  }

  static encrypt(hex: string) {
    if (CRYPTO_KEY) {
      return CryptoJS.AES.encrypt(
        hex,
        '02aab0f70eca03ff2e2397cb91076262',
      ).toString();
    }
    return '';
  }

  static getUserInfo() {
    try {
      return this.decrypt(this.getStorage('_in'));
    } catch (e) {
      return e;
    }
  }

  static ErrorOpenNotification = (
    placement: NotificationPlacement,
    duration?: number | null,
    message?: string,
    description?: string | null,
    api?: any,
  ) => {
    api.error({
      message: message,
      description: description,
      duration,
      placement,
    });
  };
  static InfoOpenNotification = (
    placement: NotificationPlacement,
    message?: string,
    duration?: number | null,
    api?: any,
    key?: React.Key,
  ) => {
    api.info({
      message: message,
      placement,
      duration: duration,
      key,
    });
  };

  static openNotification = (
    placement: NotificationPlacement,
    message: string,
    api: any,
  ) => {
    api.info({
      message: message,
      placement,
    });
  };

  static localeCity() {
    return [
      { value: 'ru', label: 'ru' },
      { value: 'en', label: 'en' },
      { value: 'de', label: 'de' },
    ];
  }

  static role() {
    return this.getUserInfo().group_id > 0
      ? { id: this.getUserInfo().group_id, type: 'group' }
      : { id: this.getUserInfo().role_id, type: 'role' };
  }

  static isValidContainerNumber = (value: string): boolean => {
    const regex = /^[A-Z]{4}\d{7}$/;
    return regex.test(value);
  };

  static isValidDate = (value: string): boolean => {
    return moment(
      value,
      ['DD.MM.YYYY HH:mm:ss', 'DD.MM.YYYY', moment.ISO_8601],
      true,
    ).isValid();
  };

  static convertToEnglishUppercase(text: string): string {
    const keyboardMap: Record<string, string> = {
      а: 'F',
      б: ',',
      в: 'D',
      г: 'U',
      д: 'L',
      е: 'T',
      ё: '~',
      ж: ':',
      з: 'P',
      и: 'B',
      й: 'Q',
      к: 'R',
      л: 'K',
      м: 'V',
      н: 'Y',
      о: 'J',
      п: 'G',
      р: 'H',
      с: 'C',
      т: 'N',
      у: 'E',
      ф: 'A',
      х: '{',
      ц: 'W',
      ч: 'X',
      ш: 'I',
      щ: 'O',
      ъ: '}',
      ы: 'S',
      ь: 'M',
      э: '"',
      ю: '>',
      я: 'Z',
      А: 'F',
      Б: '<',
      В: 'D',
      Г: 'U',
      Д: 'L',
      Е: 'T',
      Ё: '~',
      Ж: ':',
      З: 'P',
      И: 'B',
      Й: 'Q',
      К: 'R',
      Л: 'K',
      М: 'V',
      Н: 'Y',
      О: 'J',
      П: 'G',
      Р: 'H',
      С: 'C',
      Т: 'N',
      У: 'E',
      Ф: 'A',
      Х: '{',
      Ц: 'W',
      Ч: 'X',
      Ш: 'I',
      Щ: 'O',
      Ъ: '}',
      Ы: 'S',
      Ь: 'M',
      Э: '"',
      Ю: '>',
      Я: 'Z',
    };

    return text
      .split('')
      .map((char) => {
        const upperChar = char.toUpperCase();
        return keyboardMap[char] || upperChar;
      })
      .join('');
  }

  static defaultTable = () => {
    return Array.from({ length: 20 }, (_, index) => ({
      id: index + 1,
      avatar: null,
    }));
  };
}
