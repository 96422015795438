import React, { useState } from 'react';
import {
  useBreadcrumb,
  useGettingDataForTables,
  useMenu,
  useParamsHistory,
} from '../../../hooks';
import { useTranslation } from 'react-i18next';
import Layout from '../../../components/Layouts';
import { Button, Flex, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Table } from '../../../components/ui/Table/Table';
import { columns } from './columns';
import { RouteNames } from '../../../router/names';
import Course from '../../../components/Course';
import { FINANCE_PAYMENT } from '../../../constants';
import { RootState, useStoreDispatch } from '../../../store';
import { CREATE, EDIT, VIEW } from '../../../helpers/string-helpers';
import { useSelector } from 'react-redux';
import { defaultValuesPayment, IPayment, setOnePaymentDate } from '../../../store/finance';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import { FormPayment } from './FormPayment';
import { IParamRestDelete } from '../../../types';

const FinancePaymentTable = () => {
  useBreadcrumb(['Финансы', 'Оплата счета']);
  useMenu({
    openKey: RouteNames.FINANCE,
    activeKeys: [RouteNames.FINANCE, RouteNames.FINANCE_PAYMENT],
  });
  const { t } = useTranslation();
  const { location } = useParamsHistory();
  const dispatch = useStoreDispatch();
  const [typeForm, setTypeForm] = useState(EDIT);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const initialValue = useSelector<RootState>(
    (state) => state.finance.onePaymentData,
  ) as IPayment;

  // HACK: удалить после доработок
   const fetchTables = ({
     data,
     current,
     pageSize,
   }: {
     data: any[];
     current: number;
     pageSize: number;
   }) => Promise.resolve({data: []});
   const fetchDelete = ({
     ids,
     isDelete
   }: IParamRestDelete) => Promise.resolve({data: []});

  const { dataTable, optionsTable, updateDate, updateEffectDate } =
    useGettingDataForTables({
      table: FINANCE_PAYMENT,
      location,
      columns,
      fetchTables,
      fetchDelete
    });

  const onRow = (row: IPayment) => {
    setTypeForm(VIEW);
    dispatch(setOnePaymentDate(row));
    setIsOpenDrawer(true);
  };

  const handleShowEditForm = (row: IPayment) => {
    setTypeForm(EDIT);
    dispatch(setOnePaymentDate(row));
    setIsOpenDrawer(true);
  };

  const onCloseDrawer = (res = false) => {
    setIsOpenDrawer(false);
    setTypeForm(CREATE);
    dispatch(setOnePaymentDate(defaultValuesPayment));
    if (res) {
      updateDate();
    }
  };

  const onCreate = () => {
    dispatch(setOnePaymentDate(defaultValuesPayment));
    setTypeForm(CREATE);
    setIsOpenDrawer(true);
  };

  return (
    <Layout>
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('Заявки на оплату счета')}</div>
        </Flex>
        <Flex gap={20} align="center">
          <Course />
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={onCreate}
          />
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          {...optionsTable}
          onClickLink={onRow}
          editRow={handleShowEditForm}
          onRow={onRow}
          columns={columns}
          dataSource={dataTable}
          height={'calc(-162px + 100vh)'}
        />

        <CustomDrawer
          minWidth={1000}
          isHeight
          onClose={onCloseDrawer}
          open={isOpenDrawer}
        >
          <FormPayment
            isOpen={isOpenDrawer}
            typeForm={typeForm}
            initialValue={initialValue}
            onClose={onCloseDrawer}
          />
        </CustomDrawer>
      </Space>
    </Layout>
  );
};

export default FinancePaymentTable;
