import { useEffect, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Divider, Flex, Layout, Typography } from 'antd';

import { delay } from 'lodash';
import Icon, {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import Headers from '../Header';
import Menus from '../Menu/Menu';
import socket from '../../socket';
import { TOK_EN, Utils } from '../../utils';
import { Helpers } from '../../helpers';
import style from './style.module.scss';
import { RouteNames } from '../../router/names';
import { setSourceContact } from '../../store/contact';
import DrawerRight from '../ui/DrawerRight/DrawerRight';
import { setFindMyCompany } from '../../store/contractors';
import { RootState, useStoreDispatch } from '../../store';
import { entitiesInfo, setAuth, setUser } from '../../store/users';
import { setTerminalDataCity } from '../../store/terminals';
import { setLang } from '../../store/store';
import TooltipButton from '../ui/TooltipButton/TooltipButton';
import { ExitIconDefault, notificationIcon } from '../../assets/image/svg';
import { setStatusContainer } from '../../store/containers';
import { setDrawer, setFetchedResources } from '../../store/store';
import {
  setTypePayment,
  setTypeCompany,
  setTypeContainers,
  setTypeEmail,
  setTypeMessenger,
  setTypePhone,
  setTypeQuality,
  setTypeContact,
  setTypeDeal,
  setTypeDealStatus,
  setTypeDealContainerStatus,
  setTypeTerminalStorage,
  setTypeCurrency,
  setTypePercent,
  setTypePriority,
  setTypeExpense,
} from '../../store/types';
import { useParamsHistory } from '../../hooks';
import { Rest_types } from '../../services/rest_types';
import useModalMessage from '../../hooks/useModalMessage';
import Profile from '../Profile';
import { IUsers } from '../../types/users';

const { Sider, Content } = Layout;

export const Layouts = (props: any) => {
  const message = useModalMessage();
  const { t } = useTranslation();
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const isDrawer: boolean = useSelector(
    (state: RootState) => state.stores.isDrawer,
  );
  const dataMenu: any = useSelector(
    (state: RootState) => state.stores.dataMenu,
  );
  const hasFetchedResources = useSelector(
    (state: RootState) => state.stores.hasFetchedResources,
  );
  const profile: IUsers | undefined = useSelector(
    (state: RootState) => state.users.user,
  );

  const [isDisabled, setIsDisabled] = useState(false);
  const [isTerm, setIsTerm] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isCompanys, setIsCompanys] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isTerminalTable, setIsTerminalTable] = useState(false);
  const [isContainers, setIsContainers] = useState(false);
  const [isListTerminals, setIsListTerminals] = useState(false);
  const [isLoad, setLoad] = useState(false);

  useEffect(() => {
    const getErrorMessage = () => {
      message.error(
        <Typography.Text type="danger">Ошибка соединения!</Typography.Text>,
        0,
      );
    };
    if (socket.disconnected) {
      if (!isDisabled) {
        setIsDisabled(true);
        getErrorMessage();
      }
    }
    socket.on('disconnect', (err) => {
      setIsDisabled(true);
      getErrorMessage();
    });
    socket.on('connect', () => {
      setIsDisabled(false);
      message.destroy();
      if (Utils.getStorage(TOK_EN) !== null) {
        const user = Utils.decrypt(Utils.getStorage(TOK_EN));
        dispatch(setUser(user));

        const userName =
          user.name === null ? 'Гость' : user.name + ' ' + user.last_name;
        message.success(`Добро пожаловать! ${userName}`, 2);
      }
    });
  }, []);

  useEffect(() => {
    const loadMenuLocal = async () => {
      setIsCompanys(await Helpers.localGroupEntities('companys'));
      setIsContact(await Helpers.localGroupEntities('contacts'));
      setIsStaff(await Helpers.localGroupEntities('staff'));
      setIsTerm(await Helpers.localGroupEntities('term'));
      setIsContainers(await Helpers.localGroupEntities('containers'));
      setIsTerminalTable(await Helpers.localGroupEntities('terminalTable'));
      setIsListTerminals(await Helpers.localGroupEntities('terminals'));
      setLoad(true);
    };
    if (Utils.getStorage('ent') === null) {
      dispatch(entitiesInfo(Utils.role())).then(async (response: any) => {
        if (Utils.role().id === response.payload.role_id) {
          await Utils.setStorage(
            'ent',
            await Utils.encrypt(JSON.stringify(response.payload.res_client)),
          );
          const resLoc = await Utils.getStorage('ent');
          if (resLoc !== null) {
            loadMenuLocal();
          }
        }
      });
    } else {
      loadMenuLocal();
    }
    socket.once('res-update-group-entities', async (response: any) => {
      if (Utils.role().type === 'group') {
        if (Utils.role().id === response.role_id) {
          await Utils.setStorage(
            'ent',
            await Utils.encrypt(JSON.stringify(response.res_client)),
          );
          const resLoc = await Utils.getStorage('ent');
          if (resLoc !== null) {
            loadMenuLocal();
          }
        }
      }
    });

    socket.once('res-update-entities', async (response: any) => {
      if (Utils.role().type === 'group') {
        const loc = await Utils.decrypt(Utils.getStorage('ent'));
        loc.map((item: any) => {
          response.res_client.map((el: any) => {
            if (el.entities === item.entities && el.status) {
              item.status = el.status;
            }
          });
        });
        await Utils.setStorage('ent', await Utils.encrypt(JSON.stringify(loc)));
        const resLoc = await Utils.getStorage('ent');
        if (resLoc !== null) {
          loadMenuLocal();
        }
      } else {
        await Utils.setStorage(
          'ent',
          await Utils.encrypt(JSON.stringify(response.res_client)),
        );
        const resLoc = await Utils.getStorage('ent');
        if (resLoc !== null) {
          loadMenuLocal();
        }
      }
    });

    if (!hasFetchedResources) {
      Rest_types.getTypes()
        .then((res) => {
          const response = res?.data;
          // HACK: пока все типы записываются по одному до удаления лишних socket, потом можно в один метод перенести
          dispatch(setTypeCompany(response?.arCompany));
          dispatch(setTypeContainers(response?.arContainers));
          dispatch(setTypeCurrency(response?.arCurrency));
          dispatch(
            setTypeDealContainerStatus(response?.arDealContainerStatusService),
          );
          dispatch(setTypeDeal(response?.arDealTypeService));
          dispatch(setTypeEmail(response?.arEmail));
          dispatch(setTypeMessenger(response?.arMessenger));
          dispatch(setTypePayment(response?.arPayment));
          dispatch(setTypePhone(response?.arPhone));
          dispatch(setTypeQuality(response?.arQuality));
          dispatch(setSourceContact(response?.arSourceContact));
          dispatch(setStatusContainer(response?.arStatusContainer));
          dispatch(setTerminalDataCity(response?.arTerminalChildCity));
          dispatch(setTypeContact(response?.arTypeContact));
          dispatch(setFindMyCompany(response?.arFindMyCompany));
          dispatch(setTypePercent(response?.arPercent));
          dispatch(setTypeDealStatus(response?.arDealStatuses));
          dispatch(setTypeTerminalStorage(response?.arTerminalStorage));
          dispatch(setTypeExpense(response?.arTypeExpense));
          dispatch(setTypePriority(response?.arTypePriority));

          dispatch(setFetchedResources(true));
        })
        .catch((err) => {
          dispatch(setFetchedResources(false));
        });
    }
  }, []);

  useEffect(() => {
    if (!Utils.getStorage(TOK_EN)) {
      exit();
    }
  }, [pathname]);

  const exit = () => {
    dispatch(setLang('ru'));
    delay(() => {
      Utils.clearStorage();
      dispatch(setAuth(false));
      history.push(RouteNames.LOGIN);
    }, 0);
  };

  return (
    <Layout
      className={classnames(style.layout, {
        [style.layout_disabled]: isDisabled,
      })}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={isDrawer}
        collapsedWidth={60}
        className={'sider'}
        width={252}
      >
        <Flex justify="space-between" align="center" className={'sider avatar'}>
          <Flex
            justify="space-between"
            align="center"
            gap={10}
            style={
              !isDrawer
                ? { marginLeft: 8, marginTop: 8, marginBottom: 13, flex: 1}
                : {
                    marginTop: 8,
                    marginBottom: 7,
                    width: '100%',
                    justifyContent: 'center',
                  }
            }
          >
            <Profile user={profile} isDrawer={isDrawer} />
          {!isDrawer && (
            <Button
              type="text"
              style={{ marginRight: 10 }}
              icon={isDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => dispatch(setDrawer(true))}
            />
          )}
          </Flex>
        </Flex>
        <Flex className={style.layout__menu}>
          <Badge count={5} size="small">
            <Icon component={notificationIcon} />
          </Badge>
          {!isDrawer && (
            <div className={'text-notification'}>{t('Уведомления')}</div>
          )}
        </Flex>
        {isLoad && (
          <Menus
            data={dataMenu}
            isContact={isContact}
            isTerm={isTerm}
            isCompanys={isCompanys}
            isStaff={isStaff}
            isTerminalTable={isTerminalTable}
            isContainers={isContainers}
            isListTerminals={isListTerminals}
          />
        )}
        <Divider style={{ marginTop: 6, marginBottom: 6 }} />
        <Flex
          className={classnames(style.layout__menu, {
            [style.layout__menu_icon]: isDrawer,
          })}
          justify={'flex-start'}
          align={'center'}
          style={{ cursor: 'pointer' }}
          gap={8}
        >
          <TooltipButton
            propsTooltip={{
              title: !isDrawer ? null : t('Выход'),
              placement: 'right',
            }}
            propsButton={{
              type: 'text',
              icon: (
                <Icon
                  className={style.layout__icon}
                  component={ExitIconDefault}
                />
              ),
              iconPosition: 'start',
              className: style.layout__link,
              onClick: exit,
            }}
          >
            {!isDrawer ? t('Выход') : null}
          </TooltipButton>
        </Flex>
      </Sider>
      <Layout>
        <Flex
          justify={'space-between'}
          align={'center'}
          style={{ width: '100%' }}
          gap={8}
        >
          <Headers setCollapsed={setCollapsed} collapsed={collapsed} />
        </Flex>
        <Content className={'content'}>
          <div>{props.children}</div>
        </Content>
        <DrawerRight col={8} />
      </Layout>
    </Layout>
  );
};
