import { URL_USER } from "../api/config";
import { Utils } from "../utils";
import { VIEW_SEARCH } from "./string-helpers";

// открывает ссылку в новом окне
export const openInNewTab = (url?: string, focus = true) => {
  if (!url) return;

  const win = window.open(url, '_blank');
  if (win && focus) {
    win.focus();
  }
}
// создает строку для страницы просмотра
export const getSearchLink = ({key, id, noUrl, noSearch}: {key: string, id?: number | string | null, noUrl?: boolean, noSearch?: boolean}) => {
    if (!id) return '';
    const stringId = Utils.encrypt(`${id}`);
    if (!noSearch) {
      const searchParams = new URLSearchParams();
      searchParams.set('id', stringId);
      return `${noUrl ? '' : URL_USER}${VIEW_SEARCH}${key}?${searchParams.toString()}`;
    } return stringId;
};