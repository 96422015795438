import { apiAxios } from '../api/config';
import socket from '../socket';

export class UserRequest {
  static registration(payload: []): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('registration', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static userInfo(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('user-info', payload, (response: any) => {
        try {
          resolve(response[0]);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static entitiesInfo(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('info-entities', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static refUserInfo(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('update-user', payload, (response: any) => {
        try {
          if (response.success === 1) {
            resolve(response.data[0]);
          } else {
            resolve(JSON.parse(response));
          }
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static loginAxios(data: any): Promise<any> {
    return apiAxios.post('/users/login', data);
  }
  static registrationAxios(data: any): Promise<any> {
    return apiAxios.post('/users/registration', data);
  }
  static resetPasswordAxios(data: {email: string}): Promise<any> {
    return apiAxios.post('/users/reset-password', data);
  }
}
