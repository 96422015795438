import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { DatePicker, DatePickerProps } from "antd";

const checkValue = (value: any, format: string, isString?: boolean) => {
  if (isString) {
    return value
      ? dayjs(value, format)?.isValid()
        ? dayjs(value, format)
        : null
      : null;
  }
  return value ? (dayjs(value)?.isValid() ? dayjs(value) : null) : null;
};
interface IDate extends DatePickerProps {
  isString?: boolean;
  format?: string;
  id?: string;
  value?: any;
  onChange?: (...props: any) => void;
  handlerChange?: (str: string | null, data?: any) => void;
}
const FormDatePicker: React.FC<IDate> = ({
  value,
  handlerChange,
  onChange,
  format = "DD.MM.YYYY",
  isString = true,
  ...props
}) => {
  const [data, setData] = useState<any | null>(() => null);

  useEffect(() => {
    setData(checkValue(value, format, isString));
  }, [value]);

  return (
    <DatePicker
      {...props}
      value={data}
      format={format}
      onChange={(current, str) => {
        if (onChange) {
          onChange(isString ? str : current);
        }
        setData(current);
        if (handlerChange) {
          handlerChange(str as string, current);
        }
      }}
      style={{ width: "100%" }}
    />
  );
};

export default FormDatePicker;
