import React, { useState, useMemo, useEffect } from "react";
import { Form, Button, Flex, App } from "antd";
import {
  BASE_TAB,
  APPROVAL_TAB,
  SIGNING_TAB,
  RELEASE_TAB,
  CHAT_TAB,
} from "../constants";
import RequestTab from "./tabs/RequestTab";
import ApprovalTab from "./tabs/ApprovalTab";
import SigningTab from "./tabs/SigningTab";
import ReleaseTab from "./tabs/ReleaseTab";
import ChatTab from "./tabs/ChatTab";
import ModalConfirm from "../../../../components/ui/ModalConfirm/ModalConfirm";
import { messagesModal, RENT_REQUEST } from "../../../../constants";
import { ITab, ITabs } from "../../../../components/ui/Tabs/Tabs";
import styles from "./style.module.scss";
import { useDispatch } from "react-redux";
import {
  setRentRequestData,
  setTableRequests,
  setRentRequests,
  setOneRequest,
  findOneRequest,
} from "../../../../store/rent";
import {
  IRequest,
  IDeal,
  setEditRequest,
  setOneDeal,
  defaultValuesDeal,
} from "../../../../store/rent";
import {
  Rest_rent,
  SET_RENT_REQUESTS,
  UPDATE_RENT_REQUESTS,
} from "../../../../services/rest_rent";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState, useStoreDispatch } from "../../../../store";
import { useParamsHistory } from "../../../../hooks";
import { CREATE, EDIT, VIEW } from "../../../../helpers/string-helpers";
import { useTranslation } from "react-i18next";
import { Utils } from "../../../../utils";
import { purchaseColumns } from "./tabs/columns";
import { FormDeal } from "../../deals/components";
import { setResetState } from "../../../../store/rent";
import CustomDrawer from "../../../../components/CustomDrawer/CustomDrawer";
import { useGettingDataForTables } from "../../../../hooks";
import { RouteNames } from "../../../../router/names";
import { RELEASE, RENT_DEAL } from "../../../../constants";
import { defaultLocationReturn } from "../../../../store/rent";

const { title: warningTitle, subtitle } = messagesModal.warning;

interface Props {
  onFinishAddTerminal?: (data: any, open: boolean) => void | undefined;
  setIsModalOpenForm?: (
    value?: ((prevState: boolean) => boolean) | boolean
  ) => void;
  form?: any;
  title?: string;
  isOpen?: boolean;
  initialValue: any;
  onClose?: (isClear?: boolean) => void;
  onFinish?: (requestData: any, action: string) => void;
  onEdit?: () => void;
  typeForm?: string;
  disabled?: boolean;
}

const FormModalRentRequest: React.FC<Props> = ({
  form: externalForm,
  initialValue,
  isOpen,
  onClose,
  onFinish,
  onEdit,
  title = "Редактировать запрос",
  typeForm,
  disabled,
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const dispatch = useStoreDispatch();

  const [form] = Form.useForm(externalForm);
  const [isErrorsBase, setIsErrorsBase] = useState<boolean | undefined>(false);
  const [activeKey, setActiveKey] = useState(() => BASE_TAB);
  const [tableName, setTableName] = useState<string>("");
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [data, setData] = useState(null);
  const [openDrawerDeal, setOpenDrawerDeal] = useState<boolean>(false);

  const [selectedRowsPurchase, setSelectedRowsPurchase] = useState<Set<number>>(
    new Set()
  );
  const [selectedRowsSublease, setSelectedRowsSublease] = useState<Set<number>>(
    new Set()
  );
  const [selectedRowsKtk, setSelectedRowsKtk] = useState<Set<number>>(
    new Set()
  );
  const isDisabled = !!initialValue?.id;
  const id = useMemo(() => {
    const pathSegments = window.location.pathname.split("/");
    return pathSegments[pathSegments.length - 1];
  }, [window.location.pathname]);

  const [requestId, setRequestId] = useState<number | null>(null);

  const [isSaved, setIsSaved] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isReleased, setIsReleased] = useState(false);
  useEffect(() => {
    if (isOpen && initialValue) {
      setIsSaved(!!initialValue.id); // Доступность второй вкладки
      setIsApproved(!!initialValue.approved); // Доступность третьей вкладки
    }
  }, [isOpen, initialValue]);
  const handleTransferToSupply = () => {
    setIsReleased(true); 
  };

  const requestOneData = useSelector<RootState>(
    (state) => state.rent.requestOneData
  ) as IRequest;

  const generateTemporaryId = () => `${Math.floor(Math.random() * 1000)}`;
  const formattedTodayDisplay = dayjs().format("YYYY-MM-DD");

  const { email } = Utils.getUserInfo();
  const [start] = typeof email === "string" ? email.split("@") : ["SM"];
  const user_release_id = start.toUpperCase(); 

  useEffect(() => {
    if (isOpen && initialValue?.id) {
      setRequestId(initialValue.id);
      form.setFieldsValue(initialValue);
    }

    if (requestOneData?.id) {
      setRequestId(requestOneData.id);
    }

    if (typeForm === "edit" && isOpen && id) {
      dispatch(findOneRequest(Number(id)));
    }

    if (isOpen && typeForm === EDIT && requestOneData?.id) {
      form.setFieldsValue(requestOneData);
    }

    if (typeForm === CREATE) {
      form.setFieldsValue({
        ...defaultValues,
        type_request_char: user_release_id,
      });
      console.log('юзер ид:', user_release_id);
      
    }

    if (isOpen && initialValue) {
      form.setFieldsValue(initialValue);
    }
    if (isOpen && initialValue) {
      const purchase = ensureMinRows(initialValue.purchase);
      const sublease = ensureMinRows(initialValue.sublease);
      const ktk = ensureMinRows(initialValue.ktk);

      const patchedValues = {
        ...initialValue,
        purchase,
        sublease,
        ktk,
      };

      form.setFieldsValue(patchedValues);
      console.log("Установили значения:", patchedValues);
    }
  }, [
    isOpen,
    initialValue,
    typeForm,
    requestOneData,
    requestId,
    id,
    form,
    dispatch,
  ]);

  const defaultValues: IRequest = {
    id: undefined,
    type_request_char: user_release_id || "",
    name_request: "",
    my_company: "",
    company: "",
    agreement: "",
    location_release: [],
    location_return: [defaultLocationReturn],
    type_container: "",
    condition: "",
    count_ktk: "",
    bet: "",
    currency: "",
    count_day: "",
    snp: "",
    signer: "",
    fine: "",
    contractor_inn: "",
    customer_inn: "",
    date_action_start: undefined,
    date_action_end: undefined,
    id_request: '',
    date_request: new Date(),
    request_author: "",
    supply_department: "",
    director: "",
    operational_department: "",
    purchase_mail_theme: "",
    purchase_release_number: "",
    purchase_fine: "",
    purchase_add_info: "",
    sublease_mail_theme: "",
    sublease_release_number: "",
    sublease_fine: "",
    sublease_add_info: "",
    ktk_mail_theme: "",
    ktk_release_number: "",
    ktk_fine: "",
    ktk_add_info: "",
    return_rent_reason: "",
    approved: false,
    responsible_id: null,
    status: undefined,
    responsible: "",
    purchase_type_id: 6,
    ktk_type_id: 5,
    // sublease_type_id: null,
    purchase: [
      {
        company: "1",
        from: "",
        quantity: "",
        type_container: "",
        condition: "",
        price: "",
        paymentOption: "",
      },
      {
        company: "2",
        from: "",
        quantity: "",
        type_container: "",
        condition: "",
        price: "",
        paymentOption: "",
      },
    ],
    sublease: [
      {
        company: "",
        from: "",
        to: "",
        type_container: "",
        quantity: "",
        condition: "",
        bet: "",
        count_day: "",
        snp: "",
        type_table: false,
      },
      {
        company: "",
        from: "",
        to: "",
        type_container: "",
        quantity: "",
        condition: "",
        bet: "",
        count_day: "",
        snp: "",
        type_table: false,
      },
    ],
    ktk: [
      {
        number: "",
        from: "",
        quantity: "",
        type_container: "",
        condition: "",
        remainingCost: "",
        terminal: "",
      },
    ],
  };

  const setApprovalState = (value: boolean) => {
    setIsAgreed(value);
  };

  const toggleApproval = () => {
    const newIsAgreed = !isAgreed;
    setIsAgreed(newIsAgreed);
    form.setFieldsValue({ approved: newIsAgreed });
  };

  const returnToRevision = () => setIsAgreed(false);

  const handleRowSelectionChange = (
    type: string,
    selectedRows: Set<number>
  ) => {
    if (type === "purchase") setSelectedRowsPurchase(selectedRows);
    if (type === "sublease") setSelectedRowsSublease(selectedRows);
    if (type === "ktk") setSelectedRowsKtk(selectedRows);
  };

  useEffect(() => {
    if (isOpen && initialValue) {
      const approvedValue = initialValue.approved || false;
      setIsAgreed(!!approvedValue);
    }
  }, [isOpen, initialValue]);

  const collectData = async () => {
    const allData = await form.validateFields();
    const { purchase, sublease, ktk, ...otherData } = allData;
    const filterFilledRows = (rows: any) =>
      rows.filter((row: any) =>
        Object.keys(row).some(
          (key) => key !== "type_table" && key !== "id_rent_requests" && row[key]
        )
      );
      // Фильтруем только строки, отмеченные чекбоксами
    const filteredSublease = sublease
    .filter((entry: any) => entry.type_table !== undefined) // Берем только отмеченные чекбоксы
    .filter((entry: any) =>
      Object.keys(entry).some(
        (key) => key !== "type_table" && key !== "id_rent_requests" && entry[key]
      )
    );

    const updatedSublease = sublease.map((entry: any) => ({
      ...entry,
      type_table: entry.type_table ?? false,
    }));

    return {
      id: requestId,
      type_request_char: user_release_id,
      ...otherData,
      date_action_start: otherData.date_action_start
        ? dayjs(otherData.date_action_start).isValid()
          ? dayjs(otherData.date_action_start).format("YYYY-MM-DD")
          : null
        : null,
      date_action_end: otherData.date_action_end
        ? dayjs(otherData.date_action_end).isValid()
          ? dayjs(otherData.date_action_end).format("YYYY-MM-DD")
          : null
        : null,
      date_request: otherData.date_request
        ? dayjs(otherData.date_request).isValid()
          ? dayjs(otherData.date_request).format("YYYY-MM-DD")
          : null
        : null,
      purchase: filterFilledRows(purchase || []),
      sublease: filteredSublease, 
      ktk: filterFilledRows(ktk || []),
    };
  };

  const saveAllData = async () => {
    try {
      const requestDataToSubmit = await collectData();
      let response;
      console.log("начало отправки данных:", requestDataToSubmit);

      if (typeForm === "create") {
        response = await Rest_rent.setRentRequests(requestDataToSubmit);
      } else if (typeForm === "edit") {
        response = await Rest_rent.updateRentRequests(requestDataToSubmit);
      }

      if (response) {
        console.log("Отправка данных:", requestDataToSubmit);
        message.success("Данные успешно сохранены!");
        console.log("htc", response);
        setIsSaved(true); // Делаем вторую вкладку доступной
        dispatch(setOneRequest(response));
        handleClose();
      }
    } catch (error) {
      console.error("Ошибка сохранения данных:", error);
      message.error("Ошибка сохранения данных. Проверьте форму.");
    }
  };

  const ensureMinRows = (rows = []) => {
    const filledRows = rows.filter((row) =>
      Object.values(row).some((val) => val)
    );
    const emptyRowsCount = Math.max(0, 7 - filledRows.length);
    return [...filledRows, ...Array(emptyRowsCount).fill({})];
  };

  useEffect(() => {
    dispatch(setEditRequest(true));
    return () => {
      dispatch(setEditRequest(false));
      form.resetFields();
    };
  }, [dispatch, form]);

  useEffect(() => {
    if (isOpen && initialValue) {
      const purchase = initialValue.purchase?.length
        ? initialValue.purchase
        : ensureMinRows([]);
      const sublease = initialValue.sublease?.length
        ? initialValue.sublease
        : ensureMinRows([]);
      const ktk = initialValue.ktk?.length
        ? initialValue.ktk
        : ensureMinRows([]);

      const patchedValues = {
        ...initialValue,
        purchase,
        sublease,
        ktk,
      };

      form.setFieldsValue(patchedValues);
    }
  }, [isOpen, initialValue, form]);

  const handleClose = (isClear?: boolean) => {
    if (onClose) onClose(isClear);
    form.resetFields();
    dispatch(setOneRequest({}));
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  const handleTableNameChange = (name: string) => {
    setTableName(name);
  };

  const [loading, setLoading] = useState<boolean>(false);
  const { location, history } = useParamsHistory();
  const [typeFormDeal, setTypeFormDeal] = useState<string>(CREATE);
  const dealOneData = useSelector<RootState>(
    (state) => state.rent.dealOneData
  ) as IDeal;

  const onCloseFormDeal = (res?: boolean) => {
    dispatch(setResetState());
    setOpenDrawerDeal(false);
  };

  const onRelease = () => {
    history.push(RouteNames.RELEASES, { [RELEASE]: {} });
  };

  const handleShowDrawerDeal = (tableName: string) => {
    setTypeFormDeal(CREATE);
  
    const dealTypeId = (() => {
      switch (tableName) {
        case "КТК МК":
          return 5; // Аренда
        case "Закуп":
          return 6; // Закуп
        case "Субаренда":
          return 10; // Субаренда
        case "Эвакуация":
          return 9; // Эвакуация
        default:
          return null;
      }
    })();
  
    const newDealData = {
      ...defaultValuesDeal,
      type_deal_id: dealTypeId,
      id: undefined,
      name_request: initialValue.name_request,
      my_company: initialValue.my_company,
      company: initialValue.company,
      agreement: initialValue.agreement,
      location_release: initialValue.location_release,
      location_return: initialValue.location_return,
      type_container: initialValue.type_container,
      condition: initialValue.condition,
      count_ktk: initialValue.count_ktk,
      bet: initialValue.bet,
      currency: initialValue.currency,
      count_day: initialValue.count_day,
      snp: initialValue.snp,
      signer: initialValue.signer,
      fine: initialValue.fine,
    };
  
    dispatch(setOneDeal({ ...newDealData, isFromRequest: true }));
    setOpenDrawerDeal(true);
  };

  // useEffect(() => {
  //   if (isOpen && initialValue) {
  //     // Шаг 1. Достаём все нужные массивы
  //     const purchase = ensureMinRows(initialValue.purchase);
  //     const sublease = ensureMinRows(initialValue.sublease);
  //     const ktk = ensureMinRows(initialValue.ktk);
  
  //     // Шаг 2. Мапим request_location в тот формат, который ждёт FormList.
  //     // Если у вас может быть несколько локаций возврата, вы их все переводите,
  //     // иначе оставляйте один элемент массива.
  //     const location_return = initialValue.request_location?.map((loc: any) => ({
  //       location: loc.location_return || "",
  //       bet: loc.bet || "",
  //       options: loc.options || [],
  //     })) || [defaultLocationReturn];

  //     const location_release = initialValue.request_release?.map((rel: any) => ({
  //       location: rel.location_release || "",
  //       options: rel.options || [],
  //     })) || [defaultLocationReturn];
  
  
  //     // Шаг 3. Собираем итоговый объект полей
  //     const patchedValues = {
  //       ...initialValue,
  //       purchase,
  //       sublease,
  //       ktk,
  //       location_return, 
  //       location_release, // <= подменяем
  //     };
  
  //     // Шаг 4. Засетить в форму
  //     form.setFieldsValue(patchedValues);
  //   }
  // }, [isOpen, initialValue, form]);
  useEffect(() => {
    if (isOpen && initialValue) {
      const purchase = ensureMinRows(initialValue.purchase);
      const sublease = ensureMinRows(initialValue.sublease);
      const ktk = ensureMinRows(initialValue.ktk);
  
      // Берём массив из initialValue.request_release
      // и мапим к { location: ..., options: ... } — если нужно
      const location_release = initialValue.request_release?.map((rel: any) => ({
        location_release: rel.location_release || "", // или просто location
        options: rel.options || [],
      })) || [defaultLocationReturn];
  
      // Аналогично для location_return (если нужно)
      const location_return = initialValue.request_location?.map((loc: any) => ({
        location: loc.location_return || "",
        bet: loc.bet || "",
        options: loc.options || [],
      })) || [defaultLocationReturn];
  
      // Собираем объект
      const patchedValues = {
        ...initialValue,
        purchase,
        sublease,
        ktk,
        location_release,   // <= кладём сюда
        location_return,   // <= и сюда
      };
  
      form.setFieldsValue(patchedValues);
    }
  }, [isOpen, initialValue, form]);

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: BASE_TAB,
        label: "Запрос аренды",
        forceRender: true,
        isError: isErrorsBase,
        disabledText: "",
        disabled: false, 
        content: (
          <RequestTab
            form={form}
            initialValue={initialValue}
            dataRowContainer={{}}
            onFinish={saveAllData}
            isAgreed={isAgreed}
            tableName={tableName}
            onTableNameChange={handleTableNameChange}
            onRequestDataSubmit={saveAllData}
            onRowSelectionChange={handleRowSelectionChange}
            selectedRows={{
              purchase: selectedRowsPurchase,
              sublease: selectedRowsSublease,
              ktk: selectedRowsKtk,
            }}
            saveAllData={saveAllData}
          />
        ),
      },
      {
        key: APPROVAL_TAB,
        label: "Согласование",
        forceRender: true,
        isError: false,
        disabled: !isSaved, // Доступна только после сохранения
        content: (
          <ApprovalTab
            form={form}
            initialValue={initialValue}
            data={data}
            isAgreed={isAgreed}
            toggleApproval={toggleApproval}
            setApprovalState={setApprovalState}
            returnToRevision={returnToRevision}
            tableName={tableName}
          />
        ),
      },
      {
        key: SIGNING_TAB,
        label: "Подписание заявки с клиентом",
        forceRender: true,
        isError: false,
        disabled: !isApproved, 
        content: (
          <SigningTab form={form} isOpen={isOpen} initialValue={initialValue} />
        ),
      },
      {
        key: RELEASE_TAB,
        label: "Релиз",
        forceRender: true,
        isError: false,
        disabled: !isApproved,
        content: (
          <ReleaseTab
            form={form}
            initialValue={initialValue}
            tableName={tableName}
            onCreateDeal={handleShowDrawerDeal}
          />
        ),
      },
      {
        key: CHAT_TAB,
        label: "Чат",
        forceRender: true,
        isError: false,
        disabled: false, // Всегда доступна
        content: <ChatTab form={form} initialValue={initialValue} />,
      },
    ],
    [
      isErrorsBase,
      isAgreed,
      tableName,
      selectedRowsPurchase,
      selectedRowsSublease,
      selectedRowsKtk,
      form,
      defaultValues,
      saveAllData,
      initialValue,
    ]
  );

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        className={styles.formModalRequest}
        onFinish={saveAllData}
        scrollToFirstError
        initialValues={
          isDisabled ? { ...defaultValues, ...initialValue } : defaultValues
        }
      >
        <div className={styles.formModalRentRequest}>
          <ITabs
            className={styles["tabs-form__tab"]}
            isErrorTabs
            activeKey={activeKey}
            tabs={tabs}
            onChange={changeClickTab}
          />
        </div>
        <Flex
          gap={8}
          style={{ marginTop: 10 }}
          className={styles.buttonContainer}
        >
          <Form.Item name="id" initialValue={requestId} noStyle />
          <Button
            type="primary"
            style={{ width: "57%", height: "35px" }}
            onClick={saveAllData}
          >
            Сохранить
          </Button>
          <Button
            type={"text"}
            onClick={() => setConfirmation(true)}
            style={{ width: "30%", color: "#E14453" }}
          >
            Отменить
          </Button>
        </Flex>
      </Form>
      <ModalConfirm
        title={warningTitle}
        subtitle={subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={isModalOpen ? handleCancel : onClose}
      />
      <CustomDrawer
        open={openDrawerDeal && !loading}
        onClose={onCloseFormDeal}
        isHeight
        minWidth={1000}
      >
        <FormDeal
          title={
            typeForm === CREATE ? "Создать сделку" : "Создать сделку"
          }
          initialValue={dealOneData}
          isOpen={openDrawerDeal}
          onClose={onCloseFormDeal}
          onRelease={onRelease}
          onEdit={() => setTypeFormDeal(CREATE)}
          isFromRequest={typeFormDeal === CREATE}
        />
      </CustomDrawer>
    </>
  );
};

export default FormModalRentRequest;
