import React from 'react';
import { Avatar, Tooltip } from 'antd';
import classnames from 'classnames';
import style from '../style.module.scss';
import { UserOutlined } from '@ant-design/icons';
import { IUsers } from '../../../types/users';


interface IAvatarProfile {
  user?: IUsers;
  tooltipInfo?: any;
  isHiddenInfo?: boolean;
  className?: string;
  size: number;
}

export const AvatarProfile: React.FC<IAvatarProfile> = ({user, tooltipInfo, isHiddenInfo, size = 32, className = ''}) => {
  const {
    avatar,
    fio: title,
  } = user || { avatar: null, fio: '', };
  const result = typeof title === 'string' ? title.split(' ') : [];
  const alt = (result?.length > 1 ? (result[0]?.[0] ?? '') + (result.at(-1)?.[0] ?? ''): (result?.[0]?.[0] ?? '')).toUpperCase();
  const fixStyle = {maxWidth: `${size}px`, minWidth: `${size}px`, maxHeight: `${size}px`, minHeight: `${size}px`};
  return (
    <Tooltip
          title={tooltipInfo}
          arrow={false}
          placement="bottomRight"
          overlayStyle={
            isHiddenInfo ? { visibility: 'visible' } : { visibility: 'hidden' }
          }
          overlayInnerStyle={
            isHiddenInfo ? { background: 'transparent', padding: 0 } : {}
          }
        >
          <Avatar
            className={classnames(style['profile__avatar'], {
              [className]: className,
            })}
            style={fixStyle}
            size={size}
            src={avatar}
            icon={alt ? null : <UserOutlined />}
            alt={alt || 'icon'}
          >
            {alt}
          </Avatar>
        </Tooltip>
  )
}
