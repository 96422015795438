import React from 'react';
import { List, Input, Button } from 'antd';

interface Props {
    initialValue: any;
    form?: any;
  }

const ChatTab: React.FC<Props> = ({ initialValue, form }) => {
  const messages = Array(10).fill({
    name: 'Марина Степанюк',
    message: 'Счет, пожалуйста',
    date: '18.11.2025',
    time: '16:20',
  });

  return (
    <div>
      hello 5
    </div>
  );
};

export default ChatTab;
