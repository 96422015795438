import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Flex, Row, Space } from 'antd';
import Icon from '@ant-design/icons';
import { CopyBufferIconDefault, copyIcon } from '../../assets/image/svg';
import './style.scss';
import { RouteNames } from '../../router/names';
import { useHistory } from 'react-router-dom';
import { useLink } from '../../hooks';
import { TERMINAL } from '../../constants';
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import IconButton from '../../components/ui/IconButton';

interface Props {
  dataRow?: any;
  isCopy?: boolean;
  onClose: () => void;
  onEdit: () => void;
}

const FormCardModalTerminal: React.FC<Props> = ({
  dataRow,
  isCopy,
  onClose,
  onEdit,
}) => {
  const { t } = useTranslation();
  const router = useHistory();
  const link = useLink({ key: TERMINAL, id: dataRow?.id as number });

  const onClickStock = (val: string) => {
    router.push({
      pathname: RouteNames.CONTAINERS,
      search: `?stock=${encodeURIComponent(val)}`,
    });
  };

  return (
    <Flex vertical className={'modal-content'} style={{padding: '5px 20px'}}>
      <Space direction={'vertical'}>
        <Flex justify={'space-between'} align={'center'}>
          <h2>{t('О терминале')}</h2>
          <Flex gap={20} align={'center'}>
            <Flex gap={5} align={'center'}>
              <IconButton iType="edit-form" onClick={onEdit} />
              {isCopy && (
                <CopyButton
                  text={link}
                  textSuccess={t('Ссылка скопирована')}
                  icon={<Icon component={CopyBufferIconDefault} />}
                />
              )}
            </Flex>
            <IconButton
              iType="close"
              onClick={onClose}
              style={{ color: 'currentcolor', outline: 'node', border: 'none' }}
            />
          </Flex>
        </Flex>
        <Divider style={{ marginTop: 0, marginBottom: 16 }} />
        <Row>
          <Col span={12}>
            <div className={'label-card-14'}>{t('NameTerminal')}</div>
            <div className={'text-card-14'}>{dataRow?.city_child.name}</div>
          </Col>
          <Col span={12}>
            <div className={'label-card-14'}>{t('Parent')}</div>
            <div className={'text-card-14'}>
              {dataRow?.city_child.city_terminals.name}
            </div>
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Row>
          <Col span={24}>
            <div className={'label-card-14'}>{t('Address')}</div>
          </Col>
          <Col span={24}>
            <div className={'text-card-14'}>{dataRow?.address_terminal}</div>
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Row>
          <Col span={24}>
            <div className={'label-card-14'}>{t('Contacts')}</div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className={'text-card-14'}>{t('Director')}</div>
          </Col>
          <Col span={12}>
            <Flex vertical gap={5}>
              <Flex gap={120}>
                {dataRow?.phone_director !== null && (
                  <>
                    <div className={'text-card-14'}>
                      {dataRow?.phone_director}
                    </div>
                    <CopyButton
                      disabled={!dataRow?.phone_director}
                      text={dataRow?.phone_director}
                      icon={<Icon component={copyIcon} />}
                    />
                  </>
                )}
              </Flex>
              {dataRow?.last_phone_director !== null && (
                <Flex vertical gap={5}>
                  {' '}
                  {dataRow?.last_phone_director?.map(
                    (item: any, index: number) => (
                      <Flex key={index} gap={120}>
                        <div className={'text-card-14'}>
                          {item.add_phone_director}
                        </div>
                        <CopyButton
                          disabled={!item.add_phone_director}
                          text={item.add_phone_director}
                          icon={<Icon component={copyIcon} />}
                        />
                      </Flex>
                    ),
                  )}
                </Flex>
              )}
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className={'text-card-14'}>{t('Dispatcher')}</div>
          </Col>
          <Col span={12}>
            <Flex vertical gap={5}>
              <Flex gap={120}>
                {dataRow?.phone_dispatcher !== null && (
                  <>
                    <div className={'text-card-14'}>
                      {dataRow?.phone_dispatcher}
                    </div>
                    <CopyButton
                      disabled={!dataRow?.phone_dispatcher}
                      text={dataRow?.phone_dispatcher}
                      icon={<Icon component={copyIcon} />}
                    />
                  </>
                )}
              </Flex>
              {dataRow?.last_phone_dispatcher !== null && (
                <Flex vertical gap={5}>
                  {' '}
                  {dataRow?.last_phone_dispatcher?.map(
                    (item: any, index: number) => (
                      <Flex key={index} gap={120}>
                        <div className={'text-card-14'}>
                          {item.add_phone_dispatcher}
                        </div>
                        <CopyButton
                          disabled={!item.add_phone_dispatcher}
                          text={item.add_phone_dispatcher}
                          icon={<Icon component={copyIcon} />}
                        />
                      </Flex>
                    ),
                  )}
                </Flex>
              )}
            </Flex>
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Row>
          <Col span={24}>
            <div className={'label-card-14'}>{t('Capacity')}</div>
          </Col>
          <Col span={24}>
            <div className={'text-card-14'}>{dataRow?.capacity}</div>
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Row>
          <Col span={24}>
            <div className={'label-card-14'}>{t('Stock')}</div>
          </Col>
          <Col span={24}>
            {dataRow?.stock_array?.map((item: any, index: number) => (
              <div
                className={'text-card-14'}
                key={index}
                style={{ color: 'blue', cursor: 'pointer', padding: 4 }}
                onClick={() => onClickStock(item?.add_stock)}
              >
                {item?.add_stock}
              </div>
            ))}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={'label-card-14'}>{t('StockInstruction')}</div>
          </Col>
          <Col span={24}>
            <div className={'text-card-14'}>{dataRow?.stock_model?.text}</div>
          </Col>
        </Row>
      </Space>
    </Flex>
  );
};

export default FormCardModalTerminal;
