import React, { useEffect, useState } from 'react';
import {
  Divider,
  Flex,
  Form,
  notification,
  Space,
  Typography,
  Button,
} from 'antd';
import { useStoreDispatch } from '../../store';
import {
  saveContractors,
  updateContractors,
} from '../../store/contractors';
import { columns } from './columns';
import { Utils } from '../../utils';
import { typeSite } from '../../store/types';
import {
  getCustomsServices,
  getGeographyPresence,
  getRentKTK,
  getSaleKTK,
} from '../../store/store';
import FormEditContractors from './formEditContractors';
import { entitiesInfo } from '../../store/users';
import socket from '../../socket';
import {
  Table,
} from '../../components/ui/Table/Table';
import FormCardContractors from './formCardContractors';
import {
  useGettingDataForTables,
  useParamsHistory,
} from '../../hooks';
import { RouteNames } from '../../router/names';
import { useTranslation } from 'react-i18next';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import { IContractors } from '../../types/contractors';
import { COMPANY } from '../../constants';
import { Rest_contractors } from '../../services/rest_contractors';

const { Title } = Typography;

interface IContractorPage {
  title: string;
  activeKey?: string;
}

const Contractors: React.FC<IContractorPage> = ({ title, activeKey }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { location, history } = useParamsHistory();
  const { pathname } = location;
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dataRequisites, setDataRequisites] = useState<any>([]);
  const [dataContractor, setDataContractor] = useState<any>({});

  const [isAddCompany, setIsAddCompany] = useState(false);

  const {
    dataTable,
    optionsTable,
    updateDate,
    updateEffectDate,
  } = useGettingDataForTables({
    table: COMPANY,
    location,
    columns,
    fetchTables: Rest_contractors.getContractors,
    fetchDelete: Rest_contractors.deleteContractors
  });

  useEffect(() => {
    if (activeKey !== COMPANY) return;

    if (pathname === RouteNames.CRM_COMPANY) {
      updateEffectDate();
      const row = location?.state?.[COMPANY];
      if (row) {
        if (row?.edit) {
          setDataContractor(row.edit);
          setOpenEdit(true);
          setOpen(true);
          return;
        }
        form.resetFields();
        setDataContractor({});
        setOpen(true);
        setOpenEdit(true);
        Utils.removeStorage('vd');
        return;
      }
      return;
    }
    return history.replace(RouteNames.CRM_COMPANY);
  }, [pathname, activeKey]);

  const onClose = () => {
    setOpen(false);
    setOpenEdit(false);
    form.resetFields();
  };

  const handleUpdateContractors = (response: any) => {
    if (JSON.parse(response).success === 0) {
      Utils.InfoOpenNotification(
        'topRight',
        JSON.parse(response).message,
        20,
        api,
      );
    } else {
      if (Utils.getUserInfo().id === JSON.parse(response).data[0].user_id) {
        Utils.openNotification('topLeft', JSON.parse(response).message, api);
      }
      form.resetFields();
    }
    onClose();
    updateDate();
  };

  useEffect(() => {
    if (activeKey !== COMPANY) return;
    dispatch(typeSite());
    dispatch(getRentKTK());
    dispatch(getSaleKTK());
    dispatch(getCustomsServices());
    dispatch(getGeographyPresence());
    if (Utils.getStorage('ent') === null) {
      dispatch(entitiesInfo(Utils.role())).then((response: any) => {
        response.payload.res_client.map(async (item: any) => {
          if (item.entities === 'addCompany') {
            if (Utils.role().id === response.payload.role_id) {
              setIsAddCompany(item.status);
              Utils.setStorage(
                'ent',
                await Utils.encrypt(
                  JSON.stringify(response.payload.res_client),
                ),
              );
            }
          }
        });
      });
    } else {
      Utils.decrypt(Utils.getStorage('ent')).map((item: any) => {
        if (item.entities === 'addCompany') {
          setIsAddCompany(item.status);
        }
      });
    }
    socket.on('res-update-contractors', handleUpdateContractors);
    socket.on('res-create-contractors', handleCreateContractors);
    socket.on('res-update-entities', handleUpdateEntities);
    socket.on('res-update-group-entities', handleUpdateGroupEntities);
    socket.on('res-delete-contractors', resHandleDeleteRowContractors);

    return () => {
      socket.off('res-update-contractors', handleUpdateContractors);
      socket.off('res-create-contractors', handleCreateContractors);
      socket.off('res-update-entities', handleUpdateEntities);
      socket.off('res-update-group-entities', handleUpdateGroupEntities);
      socket.off('res-delete-contractors', resHandleDeleteRowContractors);
    };
  }, []);

  const resHandleDeleteRowContractors = (response: any) => {
    updateDate();
  };

  const handleUpdateGroupEntities = (response: any) => {
    if (Utils.role().type === 'group') {
      if (Utils.role().id === response.role_id) {
        response.res_client.map(async (item: any) => {
          item.entities === 'addCompany' && setIsAddCompany(item.status);
        });
      }
    }
  };

  const handleUpdateEntities = (response: any) => {
    if (Utils.role().type === 'role') {
      if (Utils.role().id === response.role_id) {
        response.res_client.map((item: any) => {
          item.entities === 'addCompany' && setIsAddCompany(item.status);
        });
      }
    }
  };

  const handleCreateContractors = (response: any) => {
    if (response.success === 0) {
      Utils.InfoOpenNotification('topRight', response.message, 20, api);
    } else {
      updateDate();
      form.resetFields();
      Utils.openNotification('topLeft', 'Добавлено', api);
    }
  };

  const onCreate = () => {
    form.resetFields();
    setDataContractor({});
    setOpen(true);
    setOpenEdit(true);
    Utils.removeStorage('vd');
  };

  const onFinish = async (data: any, type: string) => {
    data.short_address =
      dataRequisites.length > 0 ? dataRequisites[10]?.meaning : null;
    data.contractors_id = data?.id;

    setDataContractor(data);
    setOpen(false);
    setOpenEdit(false);

    if (!data?.id) {
      await dispatch(saveContractors(data));
    } else {
      await dispatch(updateContractors(data));
    }
    Utils.removeStorage('vd');
  };

  const onRow = (row: IContractors) => {
    setDataContractor(row);
    setOpenEdit(false);
    setOpen(true);
  };

  const handleShowEditForm = (row: IContractors) => {
    setDataContractor(row);
    setOpenEdit(true);
    setOpen(true);
  };

  return (
    <>
      {contextHolder}
      <Flex
        justify={'space-between'}
        align={'flex-start'}
        style={{ margin: '20px 28px 0px' }}
      >
        <Title level={2}>{t(title)}</Title>
        <Flex gap={16}>
          <Button onClick={onCreate} type={'primary'}>
            {t('Создать')}
          </Button>
        </Flex>
      </Flex>
      <Divider style={{ margin: 0 }} />
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          {...optionsTable}
          dataSource={dataTable}
          columns={columns}
          editRow={handleShowEditForm}
          height={'calc(100vh - 309px)'}
          style={{ overflow: 'auto hidden' }}
          onRow={onRow}
          onClickLink={onRow}
        />
      </Space>

      <CustomDrawer open={open} onClose={onClose}>
        {openEdit ? (
          <FormEditContractors
            isCopy
            initialValue={dataContractor}
            form={form}
            setDataRequisites={setDataRequisites}
            onFinishEdit={onFinish}
            onCloseEdit={onClose}
            title={
              dataContractor?.id
                ? 'Редактировать компанию'
                : t('Новая компания')
            }
            onEdit={() => {
              setOpenEdit(false);
            }}
          />
        ) : (
          <FormCardContractors
            isCopy
            onClose={onClose}
            onEdit={() => {
              setOpenEdit(true);
            }}
            initialValue={dataContractor}
          />
        )}
      </CustomDrawer>
    </>
  );
};

export default Contractors;
