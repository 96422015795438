import React, { useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Avatar, Card, Flex, Tooltip, Typography } from 'antd';

import { UserOutlined } from '@ant-design/icons';

import style from './style.module.scss';
import { IUsers } from '../../types/users';
import { FormProfile } from './components/FormProfile';
import CustomDrawer from '../CustomDrawer/CustomDrawer';
import { EDIT, VIEW } from '../../helpers/string-helpers';
import { AvatarProfile } from './components/AvatarProfile';

interface IProfile {
  user?: IUsers;
  isDrawer: boolean;
}

export const Profile: React.FC<IProfile> = ({ isDrawer, user }) => {
  const {t} = useTranslation();
  const [typeForm, setTypeForm] = useState<'edit' | 'view'>(VIEW);
  const [isOpen, setIsOpen] = useState(false);
  const {
    fio,
    description,
  } = user || { avatar: null, fio: '', description: '' };

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const onEdit = () => setTypeForm(EDIT);
  const onView = () => setTypeForm(VIEW);

  const tooltipInfo = (
    <Card size="small" title={fio}>
      <Typography.Paragraph
        className={style['profile__description']}
        ellipsis={{
          rows: 1,
          tooltip: { placement: 'bottomLeft', title: description },
        }}
      >
        {description || ''}
      </Typography.Paragraph>
    </Card>
  );

  return (
    <>
      <div className={style['profile']} onClick={onOpen}>
        <AvatarProfile size={isDrawer ? 42 : 36} isHiddenInfo={isDrawer} user={user} tooltipInfo={tooltipInfo}/>
        <Flex
          hidden={isDrawer}
          vertical
          gap={2}
          style={{
            alignSelf: description ? 'baseline' : 'center',
            marginLeft: '10px'
          }}
        >
          <Typography.Paragraph
            className={style['profile__title']}
            ellipsis={{ rows: 1, tooltip: { placement: 'bottomRight', title: t(fio || 'Гость') } }}
          >
            {t(fio || 'Гость')}
          </Typography.Paragraph>
          {!!description && (
            <Typography.Paragraph
              className={style['profile__description']}
              ellipsis={{
                rows: 1,
                tooltip: { placement: 'bottomLeft', title: description },
              }}
            >
              {description}
            </Typography.Paragraph>
          )}
        </Flex>
      </div>
      <CustomDrawer isHeight minWidth={1000} open={isOpen} onClose={onClose} placement="left">
        <FormProfile isOpen={isOpen} typeForm={typeForm} initialValue={user} title={fio} onClose={onClose} isEdit onEdit={onEdit} onView={onView} />
      </CustomDrawer>
    </>
  );
};
