import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Rest_deal } from '../services/rest_deal';
import { IAgreement, ITypeFindAgreements } from './agreement';
import { Rest_agreement } from '../services/rest_agreement';

export interface ITabView {
  deal: IDeal;
  isOpen: boolean;
}
export interface IValues {
  [key: string]: any;
}

export interface IFormDeal {
  onEdit?: () => void;
  onView?: () => void;
  onClose: (bol?: boolean) => void;
  isOpen: boolean;
  isCopy?: boolean;
  isDisabled?: boolean;
  width?: string;
  title?: string;
  initialValue: IDeal;
  typeForm: string;
}

export interface ITabForm {
  isDisabled?: boolean;
  isOpen?: boolean;
  onOpenFormDeal?: () => void;
  setChangeSaveFiles?: (fuc: any) => void;
}

export interface IContainer {
  id?: number;
  num_container: string;
  container_id?: number;
  status: string | null;
  status_id?: number | null;
  issue_date?: string | null;
  departure_date?: string | null;
  bet?: number | string | null;
  payment?: string;
  gtd?: boolean;
  price?: number | string | null;
  type_container?: string | number;
  deal?: {
    id: number;
    id_deal: string;
    status: string;
    status_id: number;
    issue_date?: string | null;
    departure_date?: string | null;
    bet?: number | string | null;
    payment?: string;
    gtd?: boolean;
    price?: number | string | null;
  } | null;
  release?: { id: number; release_id: string } | null;
  delete?: boolean;
  edit?: boolean;
}

interface IResponsible {
  avatar: string;
  name: string;
  id: number;
  isResponsible: boolean;
}

export interface IComments {
  comment: string;
  id_deal: string;
  user_id: number;
  user?: { avatar: string; fio: string; id: number; name: string };
  edit?: boolean;
  createdAt?: string;
}

export interface IDeal {
  parentId?: number;
  id?: number;
  id_deal?: string;
  account_num: string;
  type_deal?: string;
  type_deal_id: number;
  type_deal_char: string;
  name_deal?: string;
  company?: string;
  company_id?: number;
  containers_ids: number[];
  phone?: string;
  my_company?: string;
  my_company_id?: number;
  contact_id?: number;
  contact?: any;
  bet?: string;
  currency?: string;
  payment?: string;
  amount_client?: string;
  amount_us?: string;
  total_repair?: string;
  total_ktk?: string;
  balance_ktk?: string;
  currency_id?: number;
  agreement_id?: number;
  agreement?: string | null;
  nds?: string;
  containers: IContainer[];
  comments: IComments[];
  comment?: string;
  comment_last?: string;
  responsible: IResponsible[];
  responsible_id?: number;
  responsible_name?: string;
  docs: number[];
  route: string[];
  include_bill?: boolean;
  delete?: boolean;
  edit?: boolean;
  saveFiles?: any[];
  releases?: any;
}
interface IDealState {
  count: number;
  currentPage: number;
  page: number;
  loading: boolean;
  loadingOneDeal: boolean;
  locations: any[];
  idDeal: string;
  isEditDeal: boolean;
  isEditDealContainers: boolean;
  responsible: IResponsible[];
  docs: number[];
  dealData: IDeal[];
  dealOneData: IDeal | {};
  containers: IContainer[];
  comments: IComments[];
  agreements: IAgreement[];
}

export const defaultValuesDeal: IDeal = {
  parentId: undefined,
  id: undefined,
  id_deal: '',
  type_deal: 'Закуп',
  type_deal_id: 3,
  type_deal_char: 'ПЗ',
  name_deal: '',
  phone: '',
  account_num: '', // номер счета
  my_company: '',
  my_company_id: undefined,
  company: '',
  company_id: undefined,
  contact_id: undefined,
  currency: '',
  currency_id: undefined,
  payment: '',
  agreement_id: undefined,
  agreement: null,
  nds: undefined,
  bet: undefined,
  responsible: [],
  containers: [],
  containers_ids: [],
  comment: '',
  comment_last: '',
  comments: [],
  docs: [],
  route: ['', ''],
  delete: false,
  edit: true,
  amount_client: '',
  amount_us: '',
  total_repair: '',
  total_ktk: '',
  balance_ktk: '',
  include_bill: false,
  saveFiles: [],
};

const initialState: IDealState = {
  loading: false,
  loadingOneDeal: false,
  count: 0,
  currentPage: 0,
  page: 20,
  locations: [],
  idDeal: '',
  isEditDeal: false,
  isEditDealContainers: false,
  responsible: [],
  docs: [],
  dealData: [],
  dealOneData: {},
  containers: [],
  comments: [],
  agreements: [],
};
export const generateIdDeal = createAsyncThunk(
  'generateIdDeal',
  async (payload: string) => {
    return await Rest_deal.generateIdDeal(payload);
  },
);

export const findAgreementsCompany = createAsyncThunk(
  'findAgreementsCompany',
  async (param: ITypeFindAgreements) => {
    return await Rest_agreement.findAgreementsCompany(param);
  },
);
export const getDealsIds = createAsyncThunk(
  'getDealsIds',
  async (param: any) => {
    return await Rest_deal.getDealsIds(param);
  },
);
export const setDeals = createAsyncThunk(
  'setDeals',
  async (payload: {
    deal: IDeal;
    containers: IContainer[];
    comments: IComments[];
  }) => {
    return await Rest_deal.setDeals(payload);
  },
);

export const findAllCommentsDeal = createAsyncThunk(
  'findAllCommentsDeal',
  async (id: number) => {
    return await Rest_deal.findAllCommentsDeal(id);
  },
);

const dealSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    resetState(state) {
      const {dealData, ...rest} = initialState;
      state = {...state, ...rest};
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setLocations(state, action) {
      state.locations = action.payload;
    },
    setResponsible(state, action) {
      state.responsible = action.payload;
    },
    setEditDeal(state, action) {
      state.isEditDeal = action.payload;
    },
    setOneDeal(state, action) {
      state.dealOneData = action.payload;
    },
    setTableDeals(state, action) {
      state.dealData = action.payload;
    },
    setDocsDeal(state, action) {
      state.docs = action.payload;
    },
    setIsEditDealContainers(state, action) {
      state.isEditDealContainers = action.payload;
    },
    editDealContainerList(state, action) {
      state.containers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateIdDeal.fulfilled, (state, action) => {
      state.idDeal = action.payload;
    });

    builder.addCase(findAllCommentsDeal.fulfilled, (state, action) => {
      state.comments = action.payload?.rows;
    });
  },
});
export default dealSlice.reducer;
export const {
  setResponsible,
  setEditDeal,
  setDocsDeal,
  setIsEditDealContainers,
  setOneDeal,
  editDealContainerList,
  setLocations,
  setCount,
  setPage,
  setCurrentPage,
  setLoading,
  setTableDeals,
  resetState,
} = dealSlice.actions;
