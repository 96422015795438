import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Table, Upload, Button, Row, Col, Divider } from "antd";
import EditableTable from "../table/Table";
import UploaderDocs from "../../../../../components/ui/UploaderDocs";
import { RootState, useStoreDispatch } from "../../../../../store";
import { Rest_rent } from "../../../../../services/rest_rent";
import { setDeals } from "../../../../../store/rent";
import { purchaseColumns, subleaseColumns, ktkColumns } from "./columns";
import { fileTypes } from "../../../../../helpers/input-helpers";
import { RouteNames } from "../../../../../router/names";
import style from "../style.module.scss";
import { create } from "domain";
import { CREATE } from "../../../../../helpers/string-helpers";

interface ReleaseTabProps {
  initialValue?: any;
  form?: any;
  tableName: string;
  onCreateDeal: (dealData: any) => void;
}

const ReleaseTab: React.FC<ReleaseTabProps> = ({
  initialValue,
  form,
  tableName,
  onCreateDeal
}) => {
  const history = useHistory();
  const [hidePurchase, setHidePurchase] = useState(false);
  const [hideSublease, setHideSublease] = useState(false);
  const [hideKtk, setHideKtk] = useState(false);

  const docs = Form.useWatch("docs", {
    form,
    preserve: true,
  });

  const handlerSaveFiles = (files: any[]) => {
    form.setFieldsValue({ saveFiles: files });
  };

  const handleCreateDeal = (typeId: number) => {
    onCreateDeal(typeId); 
  };

  const filteredPurchase = initialValue?.purchase?.filter((row: any) =>
    Object.values(row).some((val) => val)
  ) || [];
  const filteredSublease = initialValue?.sublease?.filter((row: any) =>
    Object.values(row).some((val) => val)
  ) || [];
  const filteredKtk = initialValue?.ktk?.filter((row: any) =>
    Object.values(row).some((val) => val)
  ) || [];

  return (
    <Form
      layout="vertical"
      form={form}
      className={style.requestTab}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Form.Item label="Название" style={{ width: "100%" }} name="name_request">
        <Input
          disabled
          className={style.inputName}
          style={{ color: "#0E1117", backgroundColor: "#0e111700" }}
        />
      </Form.Item>
      {initialValue?.purchase?.length > 0 && (
        <>
          <div
            className={style["deals"]}
            style={{
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
            }}
          >
            <div className={style.ApprovalTable}>
              <Divider className={style.RequestTablesDivider}>Закуп</Divider>
              <Form.List
                name="purchase"
                initialValue={filteredPurchase}
              >
                {(fields, operation) => (
                  <EditableTable
                    columns={purchaseColumns}
                    fields={fields}
                    rowCount={filteredPurchase.length}
                    columnWidths="15% 11% 9% 13% 15% 17% 20%"
                    isEditable={false}
                    additionalElement={
                      <Button
                        type="link"
                        onClick={() => onCreateDeal("Закуп")}
                      >
                        Сформировать сделку
                      </Button>
                    }
                  />
                )}
              </Form.List>
              {!hidePurchase && (
                <div className={style["release__info"]}>
                  <Form.Item
                    name="purchase_docs"
                    label="Релиз и заявка с поставщиком"
                  >
                    <UploaderDocs
                      multiple
                      ids={docs}
                      isText
                      customAddComponent={
                        <Button
                          type="default"
                          style={{ borderColor: "#05F", marginBottom: "7px" }}
                          className={style["pdf__buttons__btn"]}
                        >
                          Загрузить
                        </Button>
                      }
                      accept={[
                        fileTypes.doc,
                        fileTypes.xls,
                        fileTypes.pdf,
                        fileTypes.jpeg,
                      ].join(",")}
                      setDataIds={(files) => {
                        form.setFieldValue("docs", files);
                      }}
                      handlerSaveFiles={handlerSaveFiles}
                    />
                  </Form.Item>
                  <Form.Item
                    name="purchase_mail_theme"
                    label="Тема письма с подрядчиком"
                    // rules={[{ required: true }]}
                  >
                    <Input placeholder="Введите название" />
                  </Form.Item>
                  <Form.Item
                    name="purchase_release_number"
                    label="Номер релиза"
                    // rules={[{ required: true }]}
                  >
                    <Input placeholder="Введите номер" />
                  </Form.Item>
                  <Form.Item
                    name="purchase_fine"
                    label="Штраф за отмену"
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="purchase_add_info"
                    label="Дополнительная информация"
                    rules={[{ required: false }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
          <Button
            type="link"
            onClick={() => setHidePurchase(!hidePurchase)}
            className={style["hide__btn"]}
          >
            {hidePurchase ? "Развернуть" : "Свернуть"}
          </Button>
        </>
      )}

      {initialValue?.sublease?.length > 0 && (
        <>
          <div
            className={style["deals"]}
            style={{
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
            }}
          >
            <div className={style.ApprovalTable}>
              <Divider className={style.RequestTablesDivider}>
                {tableName}
              </Divider>
              <Form.List name="sublease" initialValue={filteredSublease}>
                {(fields, operation) => (
                  <EditableTable
                    columns={subleaseColumns}
                    fields={fields}
                    rowCount={filteredSublease.length}
                    columnWidths="15% 11% 8% 13% 11% 16% 11% 7% 8%"
                    isEditable={false}
                    additionalElement={
                      <Button
                        type="link"
                        onClick={() => onCreateDeal(tableName)}
                      >
                        Сформировать сделку
                      </Button>
                    }
                  />
                )}
              </Form.List>
              {!hideSublease && (
                <div className={style["release__info"]}>
                  <Form.Item
                    name="sublease_docs"
                    label="Релиз и заявка с поставщиком"
                    // rules={[{ required: true }]}
                  >
                    <UploaderDocs
                      multiple
                      ids={docs}
                      isText
                      customAddComponent={
                        <Button
                          type="default"
                          style={{ borderColor: "#05F", marginBottom: "7px" }}
                          className={style["pdf__buttons__btn"]}
                        >
                          Загрузить
                        </Button>
                      }
                      accept={[
                        fileTypes.doc,
                        fileTypes.xls,
                        fileTypes.pdf,
                        fileTypes.jpeg,
                      ].join(",")}
                      setDataIds={(files) => {
                        form.setFieldValue("docs", files);
                      }}
                      handlerSaveFiles={handlerSaveFiles}
                    />
                  </Form.Item>
                  <Form.Item
                    name="sublease_mail_theme"
                    label="Тема письма с подрядчиком"
                    // rules={[{ required: true }]}
                  >
                    <Input placeholder="Введите название" />
                  </Form.Item>
                  <Form.Item
                    name="sublease_release_number"
                    label="Номер релиза"
                    // rules={[{ required: true }]}
                  >
                    <Input placeholder="Введите номер" />
                  </Form.Item>
                  <Form.Item
                    name="sublease_fine"
                    label="Штраф за отмену"
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="sublease_add_info"
                    label="Дополнительная информация"
                    rules={[{ required: false }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
          <Button
            type="link"
            onClick={() => setHideSublease(!hideSublease)}
            className={style["hide__btn"]}
          >
            {hideSublease ? "Развернуть" : "Свернуть"}
          </Button>
        </>
      )}
      {initialValue?.ktk?.length > 0 && (
        <>
          <div
            className={style["deals"]}
            style={{
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
            }}
          >
            <div className={style.ApprovalTable}>
              <Divider className={style.RequestTablesDivider}>КТК МК</Divider>
              <Form.List name="ktk" initialValue={filteredSublease}>
                {(fields, operation) => (
                  <EditableTable
                    columns={ktkColumns}
                    fields={fields}
                    rowCount={filteredSublease.length}
                    columnWidths="14% 11% 11% 13% 15% 19% 17%"
                    isEditable={false}
                    additionalElement={
                      <Button
                        type="link"
                        onClick={() => onCreateDeal("КТК МК")} 
                      >
                        Сформировать сделку
                      </Button>
                    }
                  />
                )}
              </Form.List>
              {!hideKtk && (
                <div className={style["release__info"]}>
                  <Form.Item
                    name="ktk_docs"
                    label="Релиз и заявка с поставщиком"
                    // rules={[{ required: true }]}
                  >
                    <UploaderDocs
                      multiple
                      ids={docs}
                      isText
                      customAddComponent={
                        <Button
                          type="default"
                          style={{ borderColor: "#05F", marginBottom: "7px" }}
                          className={style["pdf__buttons__btn"]}
                        >
                          Загрузить
                        </Button>
                      }
                      accept={[
                        fileTypes.doc,
                        fileTypes.xls,
                        fileTypes.pdf,
                        fileTypes.jpeg,
                      ].join(",")}
                      setDataIds={(files) => {
                        form.setFieldValue("docs", files);
                      }}
                      handlerSaveFiles={handlerSaveFiles}
                    />
                  </Form.Item>
                  <Form.Item
                    name="ktk_mail_theme"
                    label="Тема письма с подрядчиком"
                    // rules={[{ required: true }]}
                  >
                    <Input placeholder="Введите название" />
                  </Form.Item>
                  <Form.Item
                    name="ktk_release_number"
                    label="Номер релиза"
                    // rules={[{ required: true }]}
                  >
                    <Input placeholder="Введите номер" />
                  </Form.Item>
                  <Form.Item
                    name="ktk_fine"
                    label="Штраф за отмену"
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="ktk_add_info"
                    label="Дополнительная информация"
                    rules={[{ required: false }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
          <Button
            type="link"
            onClick={() => setHideKtk(!hideKtk)}
            className={style["hide__btn"]}
          >
            {hideKtk ? "Развернуть" : "Свернуть"}
          </Button>
        </>
      )}
    </Form>
  );
};

export default ReleaseTab;
