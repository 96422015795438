import {
  ICustomsServices,
  IGeographyPresence,
  IPhoneMask,
  IRentKTK,
  ISaleKTK,
  ITypeCity,
  ITypeOwner,
} from '../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Rest } from '../services/rest';

interface IStoreState {
  dataCity: Array<ITypeCity>;
  getDataCity: Array<ITypeCity>;
  dataTypeOwner: Array<ITypeOwner>;
  dataRentKTK: Array<IRentKTK>;
  dataSaleKTK: Array<ISaleKTK>;
  dataCustomsServices: Array<ICustomsServices>;
  dataGeographyPresence: Array<IGeographyPresence>;
  dataPhoneMask: Array<IPhoneMask>;
  isMenu: boolean;
  dataMenu: [];
  isDrawer: boolean;
  breadcrumb: { title: string }[];
  hasFetchedResources: boolean;
  lang: 'ru' | 'en';
}
const initialState: IStoreState = {
  lang: 'ru',
  dataCity: [],
  getDataCity: [],
  dataTypeOwner: [],
  dataRentKTK: [],
  dataSaleKTK: [],
  dataCustomsServices: [],
  dataGeographyPresence: [],
  dataPhoneMask: [],
  isMenu: true,
  dataMenu: [],
  isDrawer: false,
  breadcrumb: [{ title: 'CRM' }],
  hasFetchedResources: false,
};

export const findOwner = createAsyncThunk(
  'findOwner',
  async (payload: string) => {
    return await Rest.getOwner(payload);
  },
);
export const findTerminalList = createAsyncThunk(
  'findTerminalList',
  async (payload: string) => {
    return await Rest.findTerminalList(payload);
  },
);
export const saveCity = createAsyncThunk('saveCity', async (payload: {}) => {
  return await Rest.saveCity(payload);
});
export const getCityTab = createAsyncThunk('getCityTab', async () => {
  return await Rest.getCityTab();
});
export const getCityInput = createAsyncThunk('getCityInput', async () => {
  return await Rest.getCityInput();
});
export const getRentKTK = createAsyncThunk('getRentKTK', async () => {
  return await Rest.getRentKTK();
});
export const getSaleKTK = createAsyncThunk('getSaleKTK', async () => {
  return await Rest.getSaleKTK();
});
export const getCustomsServices = createAsyncThunk(
  'getCustomsServices',
  async () => {
    return await Rest.getCustomsServices();
  },
);
export const getGeographyPresence = createAsyncThunk(
  'getGeographyPresence',
  async () => {
    return await Rest.getGeographyPresence();
  },
);
export const getPhoneMask = createAsyncThunk('getPhoneMask', async () => {
  return await Rest.getPhoneMask();
});
const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setLang(state, action) {
      state.lang = action.payload;
    },
    setMenu(state, action) {
      state.isMenu = action.payload;
    },
    setDataMenu(state, action) {
      state.dataMenu = action.payload;
    },
    setDrawer(state, action) {
      state.isDrawer = action.payload;
    },
    setBreadcrumb(state, action: { type: string; payload: string[] }) {
      state.breadcrumb = action.payload.map((title: string) => ({ title }));
    },
    setFetchedResources(state, action) {
      state.hasFetchedResources = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findOwner.fulfilled, (state, action) => {
      state.dataTypeOwner = action.payload;
    });
    builder.addCase(saveCity.fulfilled, (state, action) => {
      state.dataCity = action.payload;
    });
    builder.addCase(getCityTab.fulfilled, (state, action) => {
      state.getDataCity = action.payload;
    });
    builder.addCase(getCityInput.fulfilled, (state, action) => {
      state.getDataCity = action.payload;
    });
    builder.addCase(getRentKTK.fulfilled, (state, action) => {
      state.dataRentKTK = action.payload;
    });
    builder.addCase(getSaleKTK.fulfilled, (state, action) => {
      state.dataSaleKTK = action.payload;
    });
    builder.addCase(getCustomsServices.fulfilled, (state, action) => {
      state.dataCustomsServices = action.payload;
    });
    builder.addCase(getGeographyPresence.fulfilled, (state, action) => {
      state.dataGeographyPresence = action.payload;
    });
    builder.addCase(getPhoneMask.fulfilled, (state, action) => {
      state.dataPhoneMask = action.payload;
    });
  },
});
export default storeSlice.reducer;
export const {
  setMenu,
  setDataMenu,
  setDrawer,
  setBreadcrumb,
  setFetchedResources,
  setLang,
} = storeSlice.actions;
