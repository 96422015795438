import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { PdfSigningRU, PdfSigningEN } from "../pdf/pdfRent";
import UploaderDocs from "../../../../../components/ui/UploaderDocs";
import CancelReasonModal from "./CancelReasonModal";

import { getDateString } from "../../../../../helpers/date-helpers";
import { FormDatePicker } from "../../../../../components/ui/Form";
import { fileTypes } from "../../../../../helpers/input-helpers";
import { disableDateBefore, disableDateAfter } from "../../../../../helpers/date-helpers";
import { Rest_rent } from "../../../../../services/rest_rent";

import {
  Form,
  Input,
  Col,
  Row,
  Button,
  Checkbox,
  Modal,
  Typography,
} from "antd";
import { SideArrows } from "../../../../../assets/image/svg";
import style from "../style.module.scss";

interface SigningTabProps {
  initialValue: any;
  form?: any;
  isOpen?: any
}

type PdfOption = "standard" | "editable" | "signature";

const SigningTab: React.FC<SigningTabProps> = ({ initialValue, form, isOpen  }) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [pdfType, setPdfType] = useState<"ru" | "en" | null>(null);
  const [isPdfModalVisible, setPdfModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

  const [selectedOption, setSelectedOption] = useState<PdfOption>("standard");

  const handleCheckboxChange = (option: PdfOption) => {
    setSelectedOption(option);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const formattedToday = getDateString({
    date: dayjs(),
    format: "DD.MM.YYYY",
  });

  const generateTemporaryId = () => `${Math.floor(Math.random() * 1000)}`;

  const formattedTodayDisplay = dayjs().format("DD.MM.YYYY"); 
  useEffect(() => {
    const temporaryId = generateTemporaryId();
    const formattedTodayDisplay = dayjs().format("DD.MM.YYYY");
    form.setFieldsValue({
      date_request: formattedTodayDisplay,
    });
  }, [form]);

  useEffect(() => {
    const formattedToday = dayjs().format("DD.MM.YYYY");
    form.setFieldsValue({ date_request: formattedToday });
    console.log("Initial form values:", form.getFieldsValue());
  }, [form]);

  const onPdf = (type: "ru" | "en") => {
    setPdfType(type);
    setPdfModalVisible(true);
  };

  const handlePdfModalClose = () => {
    setPdfModalVisible(false);
    setPdfType(null);
  };

  const formPdfData = form.getFieldsValue();

  const docs = Form.useWatch("docs", {
    form,
    preserve: true,
  });

  const handlerSaveFiles = (files: any[]) => {
    form.setFieldsValue({ saveFiles: files });
  };

  const handleOpenCancelReasonModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleSaveReasons = (reasons: string[]) => {
    setSelectedReasons(reasons);
  };
  

  const date_action_start = Form.useWatch('date_action_start', {
      form,
      preserve: true,
    });
    const date_action_end = Form.useWatch('date_action_end', {
      form,
      preserve: true,
    });

    useEffect(() => {
      const temporaryId = `${Math.floor(Math.random() * 1000)}`;
      const formattedToday = dayjs().format("DD.MM.YYYY");
  
      form.setFieldsValue({
        date_request: formattedToday,
      });
  
      console.log("Form initialized with values:", form.getFieldsValue());
    }, [form]);

  return (
    <Form
      layout="vertical"
      form={form}
      className={style.requestTab}
      initialValues={initialValue}
    >
      <Form.Item label="Название" name="name_request">
        <Input
          disabled
          className={style.inputName}
          style={{
            color: "#0E1117",
            backgroundColor: "#0e111700",
          }}
        />
      </Form.Item>
      <Row gutter={24}>
        <Col
          span={isCollapsed ? 1 : 9}
          className={`${style.approvalInputs} ${
            isCollapsed ? style.collapsed : ""
          }`}
          style={{
            marginTop: "10px",
            marginBottom: "55px",
          }}
        >
          {!isCollapsed && (
            <>
              <Form.Item label="Наше юридическое лицо" name="my_company">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Клиент" name="company">
                <Input disabled />
              </Form.Item>
              <Form.Item label="№ Договора" name="agreement">
                <Input disabled />
              </Form.Item>
              {/* <Form.Item label="Локация релиза" name="location_release">
                <Input disabled />
              </Form.Item> */}
              <Row gutter={16} align="middle">
                <Col span={12}>
                  {/* <Form.Item label="Локация возврата" name="location_return">
                    <Input disabled />
                  </Form.Item> */}
                </Col>
                <Col span={12}>
                  <Form.Item label="Ставка" name="bet">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Тип КТК" name="type_container">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Состояние" name="condition">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Количество КТК" name="count_ktk">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Ставка" name="bet">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Валюта" name="currency">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Количество дней" name="count_day">
                <Input disabled />
              </Form.Item>
              <Form.Item label="СНП" name="snp">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Подписант" name="signer">
                <Input />
              </Form.Item>
              <Form.Item label="Штраф за отмену" name="fine">
                <Input />
              </Form.Item>

              <Row justify="space-between">
                <Col style={{ display: "flex", marginTop: "15px" }}>
                  <Button type="default" style={{ height: "36px" }} >
                    Передать снабжению
                  </Button>
                  <Button
                    type="link"
                    danger
                    onClick={handleOpenCancelReasonModal}
                  >
                    Отменить запрос
                  </Button>
                  <CancelReasonModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSaveReasons}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>

        <div className={style.divider} onClick={toggleCollapse}>
          <div
            className={`${style.icon} ${
              isCollapsed ? style.iconRight : style.iconLeft
            }`}
          >
            <SideArrows />
          </div>
        </div>

        <Col span={isCollapsed ? 23 : 14} className={style["right-column"]}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={style["date-block"]}>
              <div className={style["date-block__item"]}>
                <Form.Item
                  label={t("Валидность ставки")}
                  className={style["form__label"]}
                  name="date_action_start"
                >
                  <FormDatePicker
                    isString={false}
                    handlerChange={(str, data) => {
                      form.setFieldValue('date_action_start_string', str);
                      if (data > date_action_end) {
                        form.setFieldsValue({
                          date_action_end_string: '',
                          date_action_end: null,
                        });
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className={style["date-block__separator"]} />
              <div className={style["date-block__item"]}>
                <Form.Item
                  className={classnames(
                    style["form__label"],
                    style["label__hidden"]
                  )}
                  label="Валидность ставки"
                  name="date_action_end"
                >
                  <FormDatePicker
                  isString={false}
                  disabledDate={disableDateBefore(date_action_start)}
                  handlerChange={(str, dataString) =>
                    form.setFieldValue('date_action_end_string', str)
                  }
                  disabled={!date_action_start}
                />
                </Form.Item>
              </div>
            </div>
            <div className={style["request-info"]}>
              <Form.Item label="ID заявки" name="id_request" >
                <Input disabled />
              </Form.Item>
              <Form.Item label="Дата заявки" name="date_request">
                {/* <Input disabled/> */}
                <FormDatePicker
                  isString={false}
                  disabledDate={disableDateBefore(date_action_start)}
                  handlerChange={(str, dataString) =>
                    form.setFieldValue('date_action_end_string', str)
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className={style["pdf"]}>
            <div className={style["pdf__buttons"]}>
              <Button
                type="default"
                style={{ marginRight: "10px", borderColor: "#05F" }}
                onClick={() => onPdf("ru")}
                className={style["pdf__buttons__btn"]}
              >
                Сгенерировать заявку РФ
              </Button>
              <Button
                type="default"
                onClick={() => onPdf("en")}
                style={{ borderColor: "#05F" }}
                className={style["pdf__buttons__btn"]}
              >
                Сгенерировать заявку EN
              </Button>
            </div>

            <div className={style["pdf__options"]}>
              <Checkbox
                className={style["pdf__options__check"]}
                checked={selectedOption === "standard"}
                onChange={() => handleCheckboxChange("standard")}
              >
                Стандартные условия
              </Checkbox>
              <Checkbox
                className={style["pdf__options__check"]}
                checked={selectedOption === "editable"}
                onChange={() => handleCheckboxChange("editable")}
              >
                Условия по заявке
              </Checkbox>
              <Checkbox
                className={style["pdf__options__check"]}
                checked={selectedOption === "signature"}
                onChange={() => handleCheckboxChange("signature")}
              >
                Подпись/печать
              </Checkbox>
            </div>
          </div>
          <Typography.Text
            className={style["pdf__text"]}
            style={{ marginLeft: "5px", fontSize: "15px", fontWeight: "500" }}
          >
            Файлы
          </Typography.Text>
          <Form.Item name="docs" className={style["file-box"]}>
            <UploaderDocs
              multiple
              ids={docs}
              accept={[fileTypes.doc, fileTypes.xls, fileTypes.pdf].join(",")}
              setDataIds={(files) => {
                form.setFieldValue("docs", files);
              }}
              handlerSaveFiles={handlerSaveFiles}
            />
          </Form.Item>
          <Typography.Text
            className={style["pdf__text"]}
            style={{ marginLeft: "5px", fontSize: "15px", fontWeight: "500" }}
          >
            Сделка
          </Typography.Text>
          <div className={style["deals"]}>
            Сделка с поставщиком сформирована: 
          </div>
        </Col>
      </Row>
      <Modal
        open={isPdfModalVisible}
        footer={null}
        onCancel={handlePdfModalClose}
        width="fit-content"
      >
        {pdfType === "ru" && (
          <PdfSigningRU
            formPdfData={formPdfData}
            onClose={handlePdfModalClose}
            selectedOption={selectedOption}
            defaultValues={initialValue}
          />
        )}
        {pdfType === "en" && (
          <PdfSigningEN
            formPdfData={formPdfData}
            onClose={handlePdfModalClose}
            selectedOption={selectedOption}
            defaultValues={initialValue}
          />
        )}
      </Modal>
    </Form>
  );
};

export default SigningTab;
