import { useSelector } from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import { privateRoutes, publicRoutes } from '../router';
import { RootState } from '../store';

const AppRouter = () => {
    const isAuth = useSelector((state: RootState) => state.users.auth);
    const routes = isAuth ? privateRoutes : publicRoutes;
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                />
            ))}
        </Switch>
    );
};

export default AppRouter;
