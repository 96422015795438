// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_crm__rWU-k .ant-tabs-top .ant-tabs-nav::before {
  border-bottom: 0 !important;
}
.style_crm__rWU-k .ant-tabs-tab {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  height: 24px !important;
  margin: 0 0 0 12px !important;
  padding: 7px 18px !important;
  background: #fff !important;
  border-radius: 10px !important;
}
.style_crm__rWU-k .ant-tabs-tab:hover {
  background: #E1E9FA !important;
  color: #E1E9FA !important;
}
.style_crm__rWU-k .ant-tabs-ink-bar {
  background: #fff !important;
}
.style_crm__rWU-k .ant-tabs-ink-bar:hover {
  background: #E1E9FA !important;
}
.style_crm__rWU-k .ant-tabs-tab-btn {
  color: #0E1117 !important;
}
.style_crm__rWU-k .ant-tabs-tab-active {
  background: #E1E9FA !important;
  color: #0148D6 !important;
}
.style_crm__rWU-k .ant-tabs-tab-active:hover {
  background: #E1E9FA !important;
  color: #0148D6 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/crm/style.module.scss"],"names":[],"mappings":"AAKI;EACE,2BAAA;AAJN;AAOI;EACE,wBAAA;EACA,kCAAA;EACA,8BAAA;EACA,yBAAA;EACA,uBAAA;EACA,6BAAA;EACA,4BAAA;EACA,2BAAA;EACA,8BAAA;AALN;AAOM;EACE,8BAAA;EACA,yBAAA;AALR;AASI;EACE,2BAAA;AAPN;AASM;EACE,8BAAA;AAPR;AAWI;EACE,yBAAA;AATN;AAYI;EACE,8BAAA;EACA,yBAAA;AAVN;AAYM;EACE,8BAAA;EACA,yBAAA;AAVR","sourcesContent":["@import '../../assets/scss/variables';\n\n.crm {\n  :global {\n\n    .ant-tabs-top .ant-tabs-nav::before {\n      border-bottom: 0 !important;\n    }\n\n    .ant-tabs-tab {\n      display: flex !important;\n      justify-content: center !important;\n      align-items: center !important;\n      flex-shrink: 0 !important;\n      height: 24px !important;\n      margin: 0 0 0 12px !important;\n      padding: 7px 18px !important;\n      background: $white-color !important;\n      border-radius: 10px !important;\n\n      &:hover {\n        background: $tab-bg-active-color !important;\n        color: $tab-bg-active-color !important;\n      }\n    }\n\n    .ant-tabs-ink-bar {\n      background: $white-color !important;\n\n      &:hover {\n        background: $tab-bg-active-color !important;\n      }\n    }\n\n    .ant-tabs-tab-btn {\n      color: $color-text-default !important;\n    }\n\n    .ant-tabs-tab-active {\n      background: $tab-bg-active-color !important;\n      color: $tab-active-color !important;\n\n      &:hover {\n        background: $tab-bg-active-color !important;\n        color: $tab-active-color !important;\n      }\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crm": `style_crm__rWU-k`
};
export default ___CSS_LOADER_EXPORT___;
