import React, { useRef } from 'react';
import { Flex, Form, FormListFieldData, Input, Select, Typography } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import TooltipButton from '../../TooltipButton/TooltipButton';
import UploaderDocs from '../../UploaderDocs';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import style from './style.module.scss';
import FormSelectSearch from '../FormSelectSearch/FormSelectSearch';
import stylesRequest from '../../../../pages/rent/requests/components/style.module.scss'

interface IFormList {
  accept?: any;
  nameList: string;
  labelList?: string;
  title: string;
  file?: string;
  titleName?: string;
  titleNames?: string[];
  fileName?: string;
  defaultItem?: any;
  noStyleItems?: string[];
  type: 'input' | 'select' | 'search';
  typeOption: any;
  sizeFile?: number;
  titleAddFile?: string;
  disabled?: boolean;
  onDelete?: (item: any, index: number | string) => void;
  filterSelect?: (item: any, index: number | string) => boolean;
  additionalInput?: {
    name: string;            
    label?: string;          
    rules?: any[];           
    typeOption?: any;        
  };
  noScroll?: boolean;
}

export const FormList: React.FC<IFormList> = ({
  nameList,
  labelList,
  titleName,
  titleNames,
  title,
  file,
  fileName,
  defaultItem,
  type,
  typeOption,
  accept,
  onDelete,
  filterSelect,
  disabled,
  titleAddFile = 'Файл 1мб',
  sizeFile = 1,
  noStyleItems = [],
  additionalInput,
  noScroll,
}) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const fieldsRef = useRef<HTMLDivElement>(null);

  const items: any[] = Form.useWatch(nameList, {
    form,
    preserve: true,
  });

  const handlerSelect = typeOption?.onSelect;
  
  return (
    <Form.List name={nameList}>
      {(fields: FormListFieldData[], operation, meta: { errors: any }) => (
        <div
        className={classnames(
          style['list'],
          !noScroll && 'all-custom-v-scroll' 
        )}
          ref={fieldsRef}
        >
          {labelList && (
            <div className={style['list__label']}>
              <Typography.Text className={style['list__label-text']}>
                {t(labelList)}
              </Typography.Text>
            </div>
          )}
          {fields?.map(({...field}, index:number) => (
            <Flex key={field.key} gap={5} justify="space-between">
              {noStyleItems.map((itemName) => (
                <Form.Item name={[field.name, itemName]} noStyle key={itemName} />
              ))}

              {type === 'search' && (
                <Flex style={{ flex: 1, flexDirection: 'column'  }}>
                  <Form.Item
                    label={title}
                    name={titleName ? [field.name, titleName] : [field.name]}
                    style={{ flex: 1 }}
                  >
                    <FormSelectSearch
                      {...typeOption}
                      className={stylesRequest.locationReturnInput}
                      options={titleName ? items?.[field.name]?.options : undefined}
                      onSelect={(props: any, context?: any) => {
                        if (filterSelect && !filterSelect(props, field.name)) {
                          form.setFieldValue([nameList, field.name], defaultItem);
                          return;
                        }
                        if (handlerSelect) {
                          handlerSelect(props, context, field.name);
                        }
                        if (titleName) {
                          const result = { ...items[field.name] };
                          result[titleName] = props;
                          result.options = [context];
                          form.setFieldValue([nameList, field.name], result);
                        }
                      }}
                      disabled={disabled}
                    />
                  </Form.Item>

                  {additionalInput && (
                    <Form.Item
                      label={additionalInput.label || 'Ставка'}
                      name={[field.name, additionalInput.name]}
                      style={{ flex: 1 }}
                      rules={additionalInput.rules || []}
                      className={stylesRequest.betInput}
                    >
                      <Input
                        disabled={disabled}
                        {...additionalInput.typeOption}
                      />
                    </Form.Item>
                  )}
                </Flex>
              )}
              {type === 'select' && (
                <Form.Item
                  label={title}
                  name={titleName ? [field.name, titleName] : [field.name]}
                  style={{ flex: 1 }}
                >
                  <Select
                    optionLabelProp="label"
                    options={titleName ? items?.[field.name]?.options: undefined}
                    {...typeOption}
                    onSelect={(props: any, context?: any) => {
                      if (filterSelect) {
                        if (!filterSelect(props, field.name)) {
                          form.setFieldValue([nameList, field.name], defaultItem);
                          return;
                        }
                      }
                      if (handlerSelect) {
                        handlerSelect(props, context);
                      }
                      if (titleName) {
                        const result = { ...items[field.name] };
                        result[titleName] = props;
                        result['options'] = [context];
                        form.setFieldValue([nameList, field.name], result);
                      }
                    }}
                    disabled={disabled}
                  />
                </Form.Item>
              )}
              {type === 'input' && (
                <Form.Item
                  label={title}
                  name={titleName ? [field.name, titleName] : [field.name]}
                  style={{ flex: 1 }}
                >
                  <Input {...typeOption} disabled={disabled} />
                </Form.Item>
              )}
              <TooltipButton
                propsTooltip={
                  index + 1 === fields?.length && !disabled ? { title: t('Добавить') } : {}
                }
                propsButton={{
                  disabled,
                  type: 'text',
                  icon: <PlusOutlined />,
                  className: style['list__button'],
                  style:
                    index + 1 === fields?.length && !disabled
                      ? { color: '#00B288' }
                      : { opacity: 0, cursor: 'default' },
                  onClick: () => {
                    if (defaultItem) {
                      operation.add(defaultItem);
                    } else {
                      operation.add();
                    }
                  },
                }}
              />
              {!!fileName && (
                <Flex vertical align="stretch" style={{ flex: 1 }}>
                  <Form.Item name={[field.name, fileName]} noStyle />
                  <Form.Item name={[field.name, 'saveFiles']} noStyle />
                  {!!file && (
                    <div className={style['list__label']}>
                      <Typography.Text className={style['list__label-text']}>
                        {t(file)}
                      </Typography.Text>
                    </div>
                  )}
                  <UploaderDocs
                    isView={disabled}
                    className={classnames(style['list__upload'], {[style['list__upload_disabled']]: disabled})}
                    isOpen
                    isText
                    accept={accept}
                    ids={items?.[index]?.[fileName]}
                    limit={1}
                    size={sizeFile}
                    customAddComponent={
                      <div className={style['list__upload-button']}>
                        {titleAddFile}
                      </div>
                    }
                    setDataIds={(files) => {
                      const result = { ...items[field.name] };
                      if (
                        JSON.stringify(result?.[fileName]) !==
                        JSON.stringify(files)
                      ) {
                        result[fileName] = files;
                        form.setFieldValue([nameList, field.name], result);
                      }
                    }}
                    handlerSaveFiles={(files) => {
                      const result = { ...items[field.name] };
                      result['saveFiles'] = files;
                      form.setFieldValue([nameList, field.name], result);
                    }}
                  />
                </Flex>
              )}
              {items?.length > 1 && !disabled && (
                <TooltipButton
                  propsTooltip={{ title: t('Удалить') }}
                  propsButton={{
                    disabled,
                    type: 'text',
                    icon: <MinusOutlined />,
                    className: style['list__button'],
                    style: { color: '#E14453' },
                    onClick: () => {
                      if (onDelete) {
                        onDelete(items[field.name], field.name);
                      }
                      operation.remove(field.name);
                    },
                  }}
                />
              )}
            </Flex>
          ))}
        </div>
      )}
    </Form.List>
  );
};
