// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_upload-view__fR71A button {
  display: none;
  visibility: hidden;
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Uploader/style.module.scss"],"names":[],"mappings":"AAEI;EACE,aAAA;EACA,kBAAA;EACA,UAAA;AADN","sourcesContent":[".upload {\n  &-view {\n    button {\n      display: none;\n      visibility: hidden;\n      opacity: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload-view": `style_upload-view__fR71A`
};
export default ___CSS_LOADER_EXPORT___;
