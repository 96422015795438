import { useEffect, useMemo, useState } from 'react';

import { Flex, Tabs, TabsProps, Typography } from 'antd';

import Layout from '../../components/Layouts';
import { TOK_EN, Utils } from '../../utils';
import Contacts from '../contacts/Contacts';
import { RouteNames } from '../../router/names';
import Contractors from '../contractors/Contractors';
import { useBreadcrumb, useMenu, useParamsHistory } from '../../hooks';
import {
  COMPANY,
  CONTACT,
  crmNames,
  SALES_DEAL,
  REQUESTS,
  INTEGRATION,
  LEADS,
  AGREEMENT,
} from '../../constants';

import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import AgreementsCRM from '../agreements';

const { Title } = Typography;

export const CrmContainers = () => {
  const { t } = useTranslation();
  const { history, location, id } = useParamsHistory();
  const { pathname } = location;
  const [activeKey, setActiveKey] = useState('');
  const [name, setName] = useState('');
  const [activeTitle, setActiveTitle] = useState('');
  useBreadcrumb(['CRM', activeTitle]);
  useMenu({activeKeys: [RouteNames.CRM]});

  const items: TabsProps['items'] = useMemo(() => [
    { key: AGREEMENT, label: t(crmNames[AGREEMENT]), children: <AgreementsCRM title={t(crmNames[AGREEMENT])} activeKey={activeKey} /> },
    { key: SALES_DEAL, label: t(crmNames[SALES_DEAL]), children: 'Сделки'  },
    { key: COMPANY, label: t(crmNames[COMPANY]), children: <Contractors title={t(crmNames[COMPANY])} activeKey={activeKey} /> },
    { key: CONTACT, label: t(crmNames[CONTACT]), children: <Contacts title={t(crmNames[CONTACT])}  activeKey={activeKey} /> },
    { key: INTEGRATION, label: t(crmNames[INTEGRATION]), children: 'Интеграции'  },
  ], [activeKey]);

  const handleClickTab = (key: string) => {
    setActiveKey(key);
    setActiveTitle(crmNames[key]);
    history.replace(`${RouteNames.CRM}/${key}`);
  };

  useEffect(() => {
    const localUser = async () => {
      if (Utils.getStorage(TOK_EN) !== null) {
        const user = await Utils.decrypt(Utils.getStorage(TOK_EN));
        setName(user.name === null ? '' : user.name);
      }
    };
    localUser();
  }, []);

  useEffect(() => {
    switch (id) {
      case AGREEMENT:
        setActiveKey(AGREEMENT);
        setActiveTitle(crmNames[AGREEMENT]);
        return;
      case SALES_DEAL:
        setActiveKey(SALES_DEAL);
        setActiveTitle(crmNames[SALES_DEAL]);
        return;
      case REQUESTS:
        setActiveKey(REQUESTS);
        setActiveTitle(crmNames[REQUESTS]);
        return;
      case CONTACT:
        setActiveKey(CONTACT);
        setActiveTitle(crmNames[CONTACT]);
        return;
      case COMPANY:
        setActiveKey(COMPANY);
        setActiveTitle(crmNames[COMPANY]);
        return;
      case INTEGRATION:
        setActiveKey(INTEGRATION);
        setActiveTitle(crmNames[INTEGRATION]);
        return;
      default:
        setActiveKey(COMPANY);
        setActiveTitle(crmNames[COMPANY]);
    }
  }, [id]);

  return (
    <Layout>
      <Flex style={{ width: '100%' }} vertical className={style['crm']}>
        <Title level={2} style={{ padding: '20px 18px' }}>
          {t('Доброе утро')} {name ?? ''}!
        </Title>
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onChange={handleClickTab}
          items={items}
          style={{ width: '100%' }}
          indicator={{ size: (origin: any) => origin - 100, align: 'center' }}
        />
      </Flex>
    </Layout>
  );
};
