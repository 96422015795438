import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {UserRequest} from '../services/rest_users'
import {IProfile, IUsers} from "../types/users";

export interface IForm {
  title?: string;
  initialValue?: IProfile;
  isOpen: boolean;
  isCopy?: boolean;
  isEdit?: boolean;
  typeForm: 'edit' | 'view';
  onClose: (res?: boolean) => void;
  onEdit?: () => void;
  onView?: () => void;
}

interface IUsersState {
    user?: IUsers
    usersData: Array<IUsers>,
    userInfoData: Array<IUsers>
    auth: boolean,
    dataEntities: []
}

const initialState: IUsersState = {
    user: undefined,
    usersData: [],
    userInfoData: [],
    auth: false,
    dataEntities: []
};
export const UserInfo = createAsyncThunk(
    'UserInfo',
    async (payload: {}) => {
        return await UserRequest.userInfo(payload)
    }
)
export const refUserInfo = createAsyncThunk(
    'refUserInfo',
    async (payload: {}) => {
        const response: any = await UserRequest.refUserInfo(payload);
        return response
    }
)
export const entitiesInfo = createAsyncThunk(
    'entitiesInfo',
    async (payload: {}) => {
        const response: any = await UserRequest.entitiesInfo(payload);
        return response
    }
)
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setAuth(state,action) {
            state.auth = action.payload
        },
        setUser(state,action) {
            state.user = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(UserInfo.fulfilled, (state, action) => {
            state.userInfoData = action.payload
        })
        builder.addCase(refUserInfo.fulfilled, (state, action) => {
            state.userInfoData = action.payload
        })
        builder.addCase(entitiesInfo.fulfilled, (state, action) => {
            state.dataEntities = action.payload
        })
    },
});
export default usersSlice.reducer;
export const { setAuth, setUser } = usersSlice.actions
