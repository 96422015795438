import { apiAxios } from "../api/config";
import socket from "../socket";
import { IDeal } from "../store/rent";
import { IRequest } from "../store/rent";
import { IParamRestDelete } from "../types";

export const GENERATE_ID_DEAL_RENT = "generate-id-deal-rent";
export const GET_FILTERS_DEAL_RENT = "get-filters-deal-rent";
export const GET_DEALS_RENT = "get-deals-rent";
export const SET_DEAL_RENT = "set-deal-rent";
export const RES_SET_DEAL_RENT = "res-set-deal-rent";
export const DELETE_DEAL_RENT = "delete-deal-rent";
export const GET_REQUESTS_RENT = "get-requests-rent";
export const SET_RENT_REQUESTS = "set-rent-requests";
export const UPDATE_RENT_REQUESTS = "update-rent-requests";

export const FIND_ONE_REQUEST_RENT = "find-one-request-rent";

export const DELETE_REQUESTS_RENT = "delete-requests-rent";


export class Rest_rent {
  static generateIdDeal(payload: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(GENERATE_ID_DEAL_RENT, payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getFiltersDeal(param?: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(GET_FILTERS_DEAL_RENT, param, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static setDeals(payload: IDeal): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(SET_DEAL_RENT, payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static findOneRequest(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
        socket.emit(FIND_ONE_REQUEST_RENT, id, (response: any) => {
            try {
                resolve(response);
            } catch (e) {
                reject(e);
            }
        });
    });
  }

  static setRentRequests(payload: IRequest): Promise<any> {
    return new Promise(async(resolve, reject) => {
      socket.emit(SET_RENT_REQUESTS, payload, (response: any) => {
        try {
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  static updateRentRequests(payload: IRequest): Promise<any> {
    return new Promise(async(resolve, reject) => {
      socket.emit(UPDATE_RENT_REQUESTS, payload, (response: any) => {
        try {
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  static getRentRequests(param?: any): Promise<any> {
    console.log(param)
    return apiAxios.post("rent-requests/bet_rent", param);
  }

  static getDeals(param?: any): Promise<any> {
    return apiAxios.post("deal-rent/get-deal-rent", param);
  }

  static findOneDeal(id: string | number): Promise<any> {
    return apiAxios.get(`deal-rent/${id}`);
  }

  static deleteDeal(payload: IParamRestDelete): Promise<any> {
    return apiAxios.delete('deal-rent/delete-deal-rent', {data: payload})
  }
  static deleteRequest(payload: IParamRestDelete): Promise<any> {
    return apiAxios.delete('rent-requests/delete-rent-requests', {data: payload})
  }


}
