import { IColumnType } from '../../components/ui/Table/Table';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../router/names';
import { Typography } from 'antd';

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    filterIndex: 'id',
    checkbox: true,
    menu: true,
    key: '0',
  },
  {
    title: 'Наименование терминала',
    dataIndex: 'city_child.name',
    filterIndex: 'city_child.name',
    type: 'string',
    width: '20%',
    key: '1',
    render: (col, item) => {
      const id = item?.city_child_terminals_id;
      const name = item?.city_child?.name;
      return (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: { placement: 'top', title: item.contractor },
          }}
          style={{ maxWidth: '250px', margin: 0 }}
        >
          {id && name ? (
            <Link
              to={`${RouteNames.CONTAINERS}/?child=${encodeURIComponent(
                id,
              )}&city=${encodeURIComponent(0)}&name=${encodeURIComponent(
                ' - ' + name,
              )}`}
            >
              {name ?? ''}
            </Link>
          ) : (
            name || null
          )}
        </Typography.Paragraph>
      );
    },
  },
  {
    title: 'Родитель',
    dataIndex: 'city_child.city_terminals.name',
    filterIndex: 'city_child.city_terminals.name',
    width: '20%',
    type: 'string',
    key: '2',
    render: (col, item) => {
      const child = item?.city_child?.city_terminals;
      return child ? (
        <Link
          to={`${RouteNames.CONTAINERS}/?child=${encodeURIComponent(
            0,
          )}&city=${encodeURIComponent(child?.id)}&name=${encodeURIComponent(
            ' - ' + child?.name,
          )}`}
        >
          {child?.name ?? ''}
        </Link>
      ) : null;
    },
  },
  {
    title: 'Сток',
    dataIndex: 'stock',
    filterIndex: 'stock',
    type: 'string',
    width: '20%',
    key: '3',
    render: (col, item) => {
      const stockItem = Array.isArray(item?.stock)
        ? item?.stock?.[0]?.add_stock
        : item?.stock;
      return stockItem ? (
        <Link
          to={`${RouteNames.CONTAINERS}/?stock=${stockItem}&name=${stockItem}`}
        >
          {stockItem}
        </Link>
      ) : null;
    },
  },
  {
    title: 'Адрес',
    dataIndex: 'address_terminal',
    filterIndex: 'address_terminal',
    type: 'string',
    width: '60%',
    key: '4',
  },
  {
    title: 'Контакты',
    dataIndex: 'phone_director',
    filterIndex: 'phone_director',
    type: 'string',
    width: '20%',
    key: '5',
  },
  {
    title: 'Вместимость',
    dataIndex: 'capacity',
    filterIndex: 'capacity',
    type: 'string',
    width: '20%',
    key: '6',
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    width: '20%',
    key: '7',
  },
];
