import React, { useEffect, useState } from 'react';

import { GetProp, App, Upload, UploadProps, Typography, Button } from 'antd';

import { delay } from 'lodash';
import { UploadFile } from 'antd/lib/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { CameraOutlined, PlusOutlined } from '@ant-design/icons';
import {
  checkFileTypes,
  downloadContent,
  fileTypes,
} from '../../../helpers/input-helpers';
import { Rest_files } from '../../../services/rest_files';
import useModalMessage from '../../../hooks/useModalMessage';
import FullScreen from '../FullScreen/FullScreen';
import Carousel from '../FullScreen/Carousel';
import style from './style.module.scss';

interface IUploaderDocs {
  buttonDescription?: string;
  className?: string;
  ids?: any[];
  disabled?: boolean;
  isText?: boolean;
  setDataIds?: (value: number[]) => void;
  onChange?: (value: any) => void;
  accept?: string;
  name?: string;
  value?: any[];
  handlerSaveFiles?: (files: any[]) => void;
  isView?: boolean;
  isOpen?: boolean;
  multiple?: boolean;
  customAddComponent?: React.ReactNode;
  limit?: number;
  size?: number;
}

export const UploaderPreview: React.FC<IUploaderDocs> = ({
  name,
  ids,
  setDataIds,
  onChange,
  handlerSaveFiles,
  limit,
  size,
  accept = [fileTypes.image, fileTypes.jpeg].join(','),
  disabled = false,
  isView = false,
  isOpen = false,
  multiple = false,
}) => {
  const {message} = App.useApp();
  const [beforeFileList, setBeforeFileList] = useState<any[]>([]);
  const [fileListIds, setFileListIds] = useState<{ id: number; uid: string }[]>(
    [],
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewList, setPreviewList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFileList([]);
    setFileListIds([]);
    setBeforeFileList([]);
    if (!isOpen) return;
    if (ids?.length) {
      const fetchFiles = async () => {
        const getArrayFiles = (data: UploadFile[]) =>
          data.map((item: any) => ({
            id: item.id,
            uid: item.id,
            name: item.name,
            type: item.type,
            status: 'done' as UploadFileStatus,
            url: item.image,
          }));
        try {
          setLoading(true);
          const { data } = await Rest_files.getImageFiles(ids);
          const currentIds = data.map((file: any) => ({
            id: file.id,
            uid: file.id,
          }));
          const listIds = currentIds.map((file: any) => file.id);
          const images = getArrayFiles(data);
          setFileList(images);
          setPreviewList(images);
          setFileListIds(currentIds);
          if (setDataIds) {
            setDataIds(listIds);
          }
        } catch (error) {
          message.error('Не удалось загрузить файл');
        } finally {
          setLoading(false);
        }
      };
      fetchFiles();
    }
    return () => {
      setFileList([]);
      setFileListIds([]);
      setBeforeFileList([]);
    };
  }, [JSON.stringify(ids), isOpen]);

  const onCloseModal = () => setPreviewVisible(false);


  const handleUpload: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError,
    onProgress,
  }: any) => {
    try {
      if (accept !== '*' && !checkFileTypes(accept, file?.type ?? '')) {
        message.error(`Файлы типа ${file?.type} запрещены`, 5);
        return onError(file);
      }
      const isSize = size ? file.size / 1024 / 1024 <= size : true;
      if (!isSize) {
        message.error(`Файл слишком большой`, 5);
        return onError(file);
      }
      const checkFile = fileList?.find(
        (fl) => fl?.name === file?.name && fl?.status === 'done',
      );
      if (checkFile) {
        message.error(`Файл ${file?.name} уже присутствует!`, 5);
        return onError(file);
      }
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = reader.result as unknown as Buffer;
        Rest_files.submitImageFiles({
          file: base64,
          name: file.name,
          type: file?.type,
        })
          .then((res) => {
            if (setDataIds) {
              setDataIds([...fileListIds.map(({ id }) => id), res.data]);
            }
            setFileListIds((prev) => [
              ...prev,
              { id: res.data, uid: file.uid },
            ]);
            onSuccess(file);
            message.success('Файл сохранен');
          })
          .catch((error) => {
            onError(error);
          });
      };
      reader.onerror = (error) => {
        message.error('Ошибка при загрузке файла');
        onError(error);
      };
    } catch (error) {
      message.error('Ошибка при загрузке файла');
      onError(error);
    }
  };

  const dummyRequest: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError,
  }: any) => {
    try {
      if (accept !== '*' && !checkFileTypes(accept, file?.type ?? '')) {
        message.error(`Файлы типа ${file?.type} запрещены`, 5);
        return onError(file);
      }
      const isSize = size ? file.size / 1024 / 1024 <= size : true;
      if (!isSize) {
        message.error(`Файл слишком большой`, 5);
        return onError(file);
      }
      const checkList = [...beforeFileList, ...fileList];
      const checkFile = checkList?.find(
        (fl) => fl?.name === file?.name && fl?.status === 'done',
      );
      if (checkFile) {
        message.error(`Файл ${file.name} уже присутствует!`, 5);
        return onError(file);
      }
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = reader.result as unknown as Buffer;
        setBeforeFileList((prev) => {
          const result = [
            {
              url: base64,
              image: base64,
              name: file.name,
              type: file.type,
            },
            ...prev,
          ];
          return result;
        });

        delay(() => {
          onSuccess('ok');
        }, 1000);
      };
      reader.onerror = (error) => {
        message.error('Ошибка при загрузке файла');
        onError(error);
      };
    } catch (error) {
      message.error('Ошибка при загрузке файла');
      onError(error);
    }
  };

  const handleChange: UploadProps['onChange'] = ({
    fileList: newFileList,
    file,
  }) => {
    setFileList(newFileList);
    if (handlerSaveFiles) {
      handlerSaveFiles(beforeFileList);
    }

    if (file.status === 'uploading') {
      if (accept !== '*' && !checkFileTypes(accept, file?.type ?? '')) {
        message.error('Недопустимый тип файла');
        return;
      }
      if (fileList.find(({ uid }) => uid === file.uid)) {
        message.error('Такой файл уже есть');
        return;
      }
    }

    const result = newFileList.filter((file) => !file.error);

    if (file.status === 'done' || file.error) {
      setFileList(result);
    }
  };

  const handlePreview: UploadProps['onPreview'] = async (
    file: any,
    ...props
  ) => {
    const images = [...beforeFileList, ...fileList]?.filter((el) => el?.url);
    setPreviewList(images);
    const index = images?.findIndex((el) => el?.name === file?.name);
    setPreviewIndex(index >= 0 ? index : 0);
    setPreviewVisible(true);
  };

  const handleRemove = (file: any) => {
    const filesIds = fileListIds.filter(({ uid }) => uid !== file?.uid);
    const resultId = filesIds.map(({ id }) => id);
    setFileListIds((prev) => prev.filter(({ uid }) => uid !== file?.uid));
    if (onChange) {
      onChange(resultId);
    }
    if (setDataIds) {
      setDataIds(resultId);
    }
    setFileList((prev) => prev.filter(({ uid }) => uid !== file?.uid));
    const result = beforeFileList.filter(
      ({ name }) => name !== file?.name,
    );
    setBeforeFileList(result);
    if (handlerSaveFiles) {
      handlerSaveFiles(result);
    }
  };

  return (
    <>
      <Upload
        className={isView ? style['upload-view'] : ''}
        name={name}
        accept={accept}
        customRequest={handlerSaveFiles ? dummyRequest : handleUpload}
        listType={'picture-card'}
        fileList={fileList}
        onChange={handleChange}
        multiple={multiple}
        disabled={disabled}
        onPreview={handlePreview}
        onRemove={handleRemove}
      >
        {isView || (limit && fileList?.length >= limit) ? null : (
          <Button loading={loading} icon={<CameraOutlined />} />
        )}
      </Upload>
      <FullScreen isOpen={previewVisible}>
        <Carousel
          previewIndex={previewIndex}
          images={previewList}
          onClick={onCloseModal}
        />
      </FullScreen>
    </>
  );
};
