import React, { FocusEventHandler, useRef, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  Dropdown,
  Flex,
  Form,
  FormListFieldData,
  MenuProps,
  App,
  Select,
  Typography,
  Input,
} from 'antd';

import Icon from '@ant-design/icons/lib/components/Icon';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { CONTAINER, messagesModal, RELEASE } from '../../../../../../constants';
import { moreIcon } from '../../../../../../assets/image/svg';
import { findContainers } from '../../../../../../store/containers';
import {
  checkSearchString,
  fileTypes,
  regNumberKTK,
} from '../../../../../../helpers/input-helpers';
import { getSearchLink, openInNewTab } from '../../../../../../helpers/link-helper';
import { RootState, useStoreDispatch } from '../../../../../../store';
import UploaderDocs from '../../../../../../components/ui/UploaderDocs';
import { handleDownloadXlsx } from '../../../../../../helpers/xlsx-helper';
import {
  FORMAT_DATE_KTK,
  keyNamesKtk,
  nameColumns,
  nameColumnsKtk,
} from '../../../constants';
import ModalConfirm from '../../../../../../components/ui/ModalConfirm/ModalConfirm';
import {
  IContainer,
  ITabForm,
  IValues,
  setIsEditDealContainers,
} from '../../../../../../store/sales';
import {
  FormDatePicker,
  FormInputNumber,
} from '../../../../../../components/ui/Form';
import TooltipButton from '../../../../../../components/ui/TooltipButton/TooltipButton';
import UploadExcelFile from '../../../../../../components/ui/UploadExcelFile/UploadExcelFile';
import { Checkbox } from '../../../../../../components/ui/Checkbox/Checkbox';
import { calculationTotalContainer, filterResultKtk } from '../../../utils';

import styles from '../../../style.module.scss';
import table from '../../../table.module.scss';

const { title, subtitle } = messagesModal.delete;

const checkEditContainers = (containers: IContainer[]) =>
  containers.some((item) => !!item?.edit);

const TabTable: React.FC<ITabForm> = ({ isOpen }) => {
  const refMenu = useRef<HTMLDivElement>(null);
  const { message } = App.useApp();
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const dispatch = useStoreDispatch();

  const include_bill: boolean = Form.useWatch('include_bill', {
    form,
    preserve: true,
  });
  const id_deal: string = Form.useWatch('id_deal', {
    form,
    preserve: true,
  });
  const containers: IContainer[] = Form.useWatch('containers', {
    form,
    preserve: true,
  });
  const docs = Form.useWatch('docs', {
    form,
    preserve: true,
  });

  const [options, setOptions] = React.useState<IContainer[] | []>([]);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [formValues, setFormValues] = React.useState<IValues>({});

  const [values, setValues] = React.useState<IValues>({});
  const [value, setValue] = useState<string>('');
  const [indexCurrent, setIndexCurrent] = useState<number>(0);

  const containerDealStatuses = useSelector<RootState>(
    (state) => state.types.dataTypeDealContainerStatus,
  ) as { id: number; value: string }[];

  const onScrollTable = (evt: any) => {
    if (refMenu?.current && evt?.target?.scrollTop >= 0) {
      refMenu.current.scrollTo({top: evt.target.scrollTop + 5});
    }
  };

  const handlerUpload = () => {
    const rows = containers.map(
      ({
        release,
        num_container,
        type_container,
        status,
        bet,
        price,
        payment,
        issue_date,
        departure_date,
        gtd,
      }) => ({
        release: release?.release_id || '',
        num_container: num_container ?? '',
        type_container: type_container || '',
        status: status || '',
        bet: bet ?? '',
        price: price ?? '',
        payment: payment ?? '',
        issue_date: issue_date ?? '',
        departure_date: departure_date ?? '',
        gtd: gtd ? 'Да' : 'Нет',
      }),
    );
    handleDownloadXlsx({
      nameFile: `${t('ктк сделки')} ${id_deal ?? ''}`,
      nameColumns: nameColumnsKtk,
      nameList: `${t('Лист')} 1`,
      rows,
    });
  };

  const handlerReceivingData = (newContainers: IContainer[]) => {
    // HACK: функционал заполнения
    // const addResult = newContainers.map((item) => {
    //   const departure_date = item?.departure_date ? dayjs(item?.departure_date, FORMAT_DATE_KTK)?.isValid() ? item?.departure_date : '' : '';
    //   const {id, value} = item?.status ? containerDealStatuses.find(({value}) => value.toLowerCase() === `${item.status}`.toLowerCase()) || {id: null, value: null} : {id: null, value: null};
    //   return {...item, status: value, status_id: id, departure_date};
    // });
    const result = newContainers.filter(
      ({ num_container }) =>
        containers.findIndex(
          (cont) =>
            cont.num_container?.toLocaleLowerCase() ===
            num_container?.toLocaleLowerCase(),
        ) === -1 && regNumberKTK.test(num_container),
    );
    const ids = result?.map(({ num_container }) => num_container);
    dispatch(findContainers({ data: ids, key: 'num_container' }))
      .then((res) => {
        const resultContainers = res?.payload?.length
          ? filterResultKtk(res.payload)
          : [];
        console.log(resultContainers);

        form.setFieldsValue({
          containers: [...resultContainers, ...containers],
        });
        message.success(`${t('Добавлено')} ${resultContainers.length} ктк`);
      })
      .catch((err) => {
        message.error(`${t('Произошла ошибка')}, добавлено 0 ктк`);
        console.error(t('Произошла ошибка'), err);
      });
  };

  const handlerSaveFiles = (files: any[]) => {
    form.setFieldsValue({ saveFiles: files });
  };

  const removeField = () => {
    const result = [...containers];
    result[indexCurrent].delete = true;
    result[indexCurrent].edit = false;
    setIndexCurrent(0);
    form.setFieldsValue({
      containers: result,
    });
  };

  const editContainer = (
    edit: (defaultValue?: any, insertIndex?: number) => void,
    index: number,
  ) => {
    const fields = form.getFieldsValue();
    const { containers } = fields;
    const container = containers[index];

    setFormValues((prev) => ({
      ...prev,
      [`issue_date${index}`]: container?.issue_date,
      [`departure_date${index}`]: container?.departure_date,
      [`price${index}`]: container?.price,
      [`payment${index}`]: container?.payment,
    }));

    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        ...container,
      },
    }));

    const result = containers.map((item: any, i: number) =>
      i === index ? { ...item, ...values[index], edit: true } : item,
    );
    form.setFieldsValue({ containers: result });
    // проверка на открытие редактирования контейнера
    dispatch(setIsEditDealContainers(checkEditContainers(result)));
  };

  const getItems = (
    remove: () => void,
    edit: (defaultValue?: any, insertIndex?: number) => void,
    index: number,
  ): MenuProps['items'] => [
    {
      key: 1,
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: t('Редактировать') }}
          propsButton={{
            onClick: () => editContainer(edit, index),
            icon: <EditOutlined />,
            shape: 'circle',
          }}
        />
      ),
    },
    {
      key: 2,
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: t('Удалить') }}
          propsButton={{
            onClick: () => remove(),
            icon: <DeleteOutlined />,
            shape: 'circle',
          }}
        />
      ),
    },
  ];

  const addRow = ({
    value,
    id,
    addContainer,
  }: {
    value: string;
    id?: number;
    addContainer: (defaultValue?: any, insertIndex?: number) => void;
  }) => {
    const container: IContainer | undefined = options?.find(
      (opt: IContainer) => opt?.num_container === value,
    );
    const isUnique = !containers?.some(
      (item: any) => item?.num_container === value,
    );
    if (isUnique && container) {
      const newContainer = {
        id,
        num_container: value,
        container_id: id,
        type_container: container?.type_container,
        status: null,
        status_id: null,
        release: container?.release ?? null,
        deal: container?.deal ?? null,
        bet: null,
        payment: '',
        gtd: false,
        price: '',
        issue_date: null,
        departure_date: null,
        delete: container?.delete,
        edit: false,
      };
      setValues((prev) => ({ ...prev, [value]: {} }));
      setValue('');
      addContainer({ ...newContainer }, 0);
    } else {
      message.warning(t('Этот контейнер уже добавлен!'));
      setValue('');
    }
  };

  const handleContainer = (data: any) => {
    setOptions([]);
    setValue(data);
  };
  const handleBlurContainer = (evt: React.FocusEvent<HTMLInputElement>) => {
    const input = evt.target;
    setValue(input.value.trim());
  };

  const getFindContainer = (text: string) => {
    const currentString = checkSearchString(text);
    if (Array.isArray(currentString)) {
      const filterResult = currentString.filter((val) =>
        regNumberKTK.test(val),
      );
      if (!filterResult?.length) {
        setValue('');
        return;
      }
      dispatch(findContainers({ data: filterResult, key: 'num_container' }))
        .then((res) => {
          const resultContainers = filterResultKtk(res?.payload);
          setValue('');
          form.setFieldsValue({
            containers: [...resultContainers, ...containers],
          });
        })
        .catch((err) => console.log('Произошла ошибка', err));
      return;
    }
    if (text?.length >= 2) {
      dispatch(findContainers(text))
        .then((res) => {
          setOptions(filterResultKtk(res.payload));
        })
        .catch((err) => console.log('Произошла ошибка', err));
    }
  };

  const submitContainer = (index: number) => {
    const fields = form.getFieldsValue();
    const { containers } = fields;
    const container = containers[index];
    const payment = formValues[`payment${index}`] || '';
    const price = formValues[`price${index}`] || '';
    const departure_date = formValues[`departure_date${index}`] || null;
    const issue_date = formValues[`issue_date${index}`] || null;
    const status =
      containerDealStatuses.find(({ id }) => id === container?.status_id)
        ?.value || null;
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        price,
        status,
        payment,
        departure_date,
        issue_date,
        status_id: container?.status_id,
      },
    }));

    const result = containers.map((item: any, i: number) =>
      i === index
        ? {
            ...item,
            status,
            price,
            payment,
            departure_date,
            issue_date,
            edit: false,
          }
        : item,
    );
    form.setFieldsValue({ containers: result });
    const total_ktk = calculationTotalContainer(result);
    form.setFieldValue('total_ktk', total_ktk);
    // проверка на открытие редактирования контейнера
    dispatch(setIsEditDealContainers(checkEditContainers(result)));
  };
  const closeEditContainer = (index: number) => {
    const fields = form.getFieldsValue();
    const { containers } = fields;
    setFormValues((prev) => ({}));
    setValues((prev) => ({}));

    const result = containers.map((item: any, i: number) =>
      i === index ? { ...item, ...values[index], edit: false } : item,
    );
    form.setFieldsValue({ containers: result });
    // проверка на открытие редактирования контейнера
    dispatch(setIsEditDealContainers(checkEditContainers(result)));
  };

  return (
    <Flex gap={30} vertical>
      <Form.Item name="docs" className={styles['file-box']}>
        <UploaderDocs
          multiple
          isOpen={isOpen}
          buttonDescription={t('Загрузить файл')}
          ids={docs}
          accept={[
            fileTypes.doc,
            fileTypes.xls,
            fileTypes.pdf,
            fileTypes.jpeg,
          ].join(',')}
          setDataIds={(files) => {
            form.setFieldValue('docs', files);
          }}
          handlerSaveFiles={handlerSaveFiles}
        />
      </Form.Item>
      <Flex vertical gap={10}>
        <Flex justify="end" style={{paddingRight: '10px'}}>
          <Form.Item name="include_bill">
            <Checkbox className={table['checkbox']} isChoice={include_bill}>
              {t('Включаем в счет? За доставку КТК')}
            </Checkbox>
          </Form.Item>
        </Flex>
        <Form.List name="containers">
          {(fields: FormListFieldData[], operation, meta: { errors: any }) => (
            <div className={table['two-columns']}>
              <div
                onScroll={onScrollTable}
                className={table['overlap-group']}
              >
                <table className={table['table']}>
                  <thead className={table['table__header-fixed']}>
                    <tr className={table['table__header-fixed-row']}>
                      <th
                        key={title}
                        className={classnames(
                          table['table__header-fixed-index'],
                          table['label-card-16'],
                        )}
                      >
                        №
                      </th>
                      {nameColumns.map(({ title, style }) => (
                        <th
                          key={title}
                          className={classnames(
                            table['table__header-fixed-cell'],
                            table['label-card-16'],
                          )}
                          style={style}
                        >
                          {t(title)}
                        </th>
                      ))}
                    </tr>
                    <tr className={table['table__header-fixed-row']}>
                      <th
                        className={table['table__header-fixed-cell']}
                        rowSpan={1}
                        colSpan={2}
                      >
                        <AutoComplete
                          value={value}
                          placeholder={`${t('Введите')} ${t('№КТК')}`}
                          style={{ width: '100%', minWidth: '110px' }}
                          options={options?.map((opt: IContainer) => ({
                            id: opt?.id,
                            value: opt?.num_container,
                          }))}
                          onBlur={handleBlurContainer}
                          onSelect={(_, { value, id }) =>
                            addRow({ value, id, addContainer: operation?.add })
                          }
                          onChange={handleContainer}
                          onSearch={(text: string) => getFindContainer(text)}
                          // maxLength={11}
                          allowClear
                        />
                      </th>
                      <th
                        className={table['table__header-fixed-cell']}
                        rowSpan={1}
                        colSpan={nameColumnsKtk?.length}
                      />
                    </tr>
                  </thead>
                  <tbody className={table['table__body']}>
                    {fields.map(({ ...field }, index) => (
                      <tr
                        key={field.key}
                        className={table['table__row']}
                        data-row="row"
                        hidden={containers?.[field.name]?.['delete']}
                      >
                        <td
                          className={classnames(
                            table['table__index'],
                            table['text-card-14'],
                          )}
                        >
                          <Typography.Text
                            ellipsis={{
                              tooltip: {
                                placement: 'topLeft',
                                title: index + 1,
                              },
                            }}
                          >
                            {index + 1}
                          </Typography.Text>
                        </td>
                        <td className={classnames(table['table__cell'])}>
                          <Typography.Link
                            className={classnames(table['text-card-14'], {
                              [table['default-link']]:
                                !containers?.[field.name]?.['release']?.id,
                            })}
                            ellipsis
                            onClick={() => {
                              if (containers?.[field.name]?.['release']?.id) {
                                openInNewTab(
                                  getSearchLink({key: RELEASE, id: containers?.[field.name]?.['release']?.id})
                                );
                              }
                            }}
                          >
                            {containers?.[field.name]?.['release']?.release_id}
                          </Typography.Link>
                        </td>

                        <td className={classnames(table['table__cell'])}>
                          <Typography.Link
                            className={classnames(table['text-card-14'], {
                              [table['default-link']]:
                                !containers?.[field.name]?.['id'],
                            })}
                            ellipsis
                            onClick={() => {
                              if (!containers?.[field.name]?.['id']) return;
                              openInNewTab(
                                getSearchLink({key: CONTAINER, id: containers?.[field.name]?.['id']})
                              );
                            }}
                          >
                            {containers?.[field.name]?.['num_container']}
                          </Typography.Link>
                        </td>

                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                        >
                          <Typography.Text
                            ellipsis={{
                              tooltip: {
                                placement: 'topLeft',
                                title:
                                  containers?.[field.name]?.[
                                    'type_container'
                                  ] || '',
                              },
                            }}
                          >
                            {containers?.[field.name]?.['type_container'] || ''}
                          </Typography.Text>
                        </td>

                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                        >
                          {containers?.[field.name]?.['edit'] ? (
                            <Form.Item name={[field.name, 'status_id']}>
                              {
                                <Select
                                  optionLabelProp="label"
                                  placeholder={t('Добавьте статус')}
                                  style={{ width: '100%' }}
                                  options={containerDealStatuses?.map(
                                    ({ id, value }) => ({
                                      value: id,
                                      label: value,
                                    }),
                                  )}
                                />
                              }
                            </Form.Item>
                          ) : (
                            <Typography.Text
                              ellipsis={{
                                tooltip: {
                                  placement: 'leftBottom',
                                  title:
                                    containers?.[field.name]?.status ||
                                    t('Добавьте статус'),
                                },
                              }}
                            >
                              {containers?.[field.name]?.status ||
                                t('Добавьте статус')}
                            </Typography.Text>
                          )}
                        </td>

                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                        >
                          <Typography.Text
                            className={table['text-card-14']}
                            ellipsis={{
                              tooltip: {
                                placement: 'topLeft',
                                title: containers?.[field.name]?.['bet'] ?? '',
                              },
                            }}
                          >
                            {containers?.[field.name]?.['bet'] ?? ''}
                          </Typography.Text>
                        </td>

                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                        >
                          <Form.Item name={[field.name, 'price']} noStyle />
                          {containers?.[field.name]?.['edit'] ? (
                            <FormInputNumber
                              name={`${field.name}`}
                              placeholder={t('Добавьте цену')}
                              value={formValues?.[`price${field.name}`]}
                              min={0}
                              onChange={(val) => {
                                setFormValues((prev) => ({
                                  ...prev,
                                  [`price${field.name}`]: val,
                                }));
                              }}
                            />
                          ) : (
                            <Typography.Text
                              ellipsis={{
                                tooltip: {
                                  placement: 'topLeft',
                                  title:
                                    containers?.[field.name]?.price ||
                                    t('Добавьте цену'),
                                },
                              }}
                            >
                              {containers?.[field.name]?.price ||
                                t('Добавьте цену')}
                            </Typography.Text>
                          )}
                        </td>

                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                        >
                          <Form.Item name={[field.name, 'payment']} noStyle />
                          {containers?.[field.name]?.['edit'] ? (
                            <FormInputNumber
                              name={`${field.name}`}
                              placeholder={t('Добавьте данные')}
                              value={formValues?.[`payment${field.name}`]}
                              min={0}
                              onChange={(val) => {
                                setFormValues((prev) => ({
                                  ...prev,
                                  [`payment${field.name}`]: val,
                                }));
                              }}
                            />
                          ) : (
                            <Typography.Text
                              className={table['text-card-14']}
                              ellipsis={{
                                tooltip: {
                                  placement: 'topLeft',
                                  title:
                                    containers?.[field.name]?.['payment'] ||
                                    t('Добавьте данные'),
                                },
                              }}
                            >
                              {containers?.[field.name]?.['payment'] ||
                                t('Добавьте данные')}
                            </Typography.Text>
                          )}
                        </td>

                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                          style={{ minWidth: '140px' }}
                        >
                          {containers?.[field.name]?.['edit'] ? (
                            <FormDatePicker
                              format={FORMAT_DATE_KTK}
                              value={
                                containers?.[field.name]?.['issue_date'] || null
                              }
                              handlerChange={(value: string | null) => {
                                setFormValues((prev) => ({
                                  ...prev,
                                  [`issue_date${field.name}`]: value,
                                }));
                              }}
                            />
                          ) : (
                            <Typography.Text
                              ellipsis={{
                                tooltip: {
                                  placement: 'topLeft',
                                  title:
                                    containers?.[field.name]?.['issue_date'] ||
                                    t('Добавьте дату'),
                                },
                              }}
                            >
                              {containers?.[field.name]?.['issue_date'] ||
                                t('Добавьте дату')}
                            </Typography.Text>
                          )}
                        </td>

                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                          style={{ minWidth: '140px' }}
                        >
                          {containers?.[field.name]?.['edit'] ? (
                            <FormDatePicker
                              format={FORMAT_DATE_KTK}
                              value={
                                containers?.[field.name]?.['departure_date'] ||
                                null
                              }
                              handlerChange={(value: string | null) => {
                                setFormValues((prev) => ({
                                  ...prev,
                                  [`departure_date${field.name}`]: value,
                                }));
                              }}
                            />
                          ) : (
                            <Typography.Text
                              ellipsis={{
                                tooltip: {
                                  placement: 'topLeft',
                                  title:
                                    containers?.[field.name]?.[
                                      'departure_date'
                                    ] || t('Добавьте дату'),
                                },
                              }}
                            >
                              {containers?.[field.name]?.['departure_date'] ||
                                t('Добавьте дату')}
                            </Typography.Text>
                          )}
                        </td>

                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                          style={{ minWidth: 'auto' }}
                        >
                          <Form.Item name={[field.name, 'gtd']} noStyle>
                            <Checkbox
                              isChoice={containers?.[field.name]?.gtd}
                              disabled={!containers?.[field.name]?.['edit']}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                ref={refMenu}
                className={classnames(
                  table['overlap-group'],
                  table['overlap-group_last'],
                )}
              >
                <table className={table['table']}>
                  <thead className={table['table__header-fixed']}>
                    <tr className={table['table__header-fixed-row']}>
                      <th
                        className={classnames(
                          table['table__header-fixed-cell'],
                          table['table__header-fixed-menu-icon'],
                        )}
                        style={{ padding: '15px 5px' }}
                      >
                        <div className={table['label-card-16']}>
                          <Dropdown
                            overlayClassName={table.drop}
                            menu={{
                              items: [
                                {
                                  key: 'link1',
                                  label: (
                                    <UploadExcelFile
                                      keyNames={keyNamesKtk}
                                      data-id="link"
                                      classNameLink={table['menu-icon__link']}
                                      onChange={handlerReceivingData}
                                    />
                                  ),
                                },
                                {
                                  key: 'link2',
                                  label: (
                                    <Typography.Text
                                      data-id="link"
                                      className={classnames(
                                        table['menu-icon__link'],
                                      )}
                                      onClick={handlerUpload}
                                      data-disabled={!containers?.length}
                                      disabled={!containers?.length}
                                    >
                                      {t('Выгрузить в Excel')}
                                    </Typography.Text>
                                  ),
                                },
                              ],
                            }}
                            placement="bottom"
                            trigger={['click']}
                          >
                            <Typography.Link
                              onClick={(e) => e.preventDefault()}
                            >
                              <Icon component={moreIcon} />
                            </Typography.Link>
                          </Dropdown>
                        </div>
                      </th>
                    </tr>
                    <tr className={table['table__header-fixed-row']}>
                      <th
                        className={table['table__header-fixed-cell']}
                        rowSpan={1}
                        colSpan={1}
                        style={{ padding: '42px 5px 0 5px' }}
                      />
                    </tr>
                  </thead>
                  <tbody className={table['table__body']}>
                    {containers?.map((cont, index) => (
                      <tr
                        key={index}
                        className={table['table__row']}
                        data-row="row"
                        hidden={containers?.[index]?.['delete']}
                      >
                        <td
                          className={classnames(
                            table['table__cell'],
                            table['table__menu'],
                          )}
                        >
                          <div className={table['text-card-14']}>
                            {containers?.[index]?.['edit'] ? (
                              <Dropdown
                                overlayClassName={table.drop}
                                open
                                placement="bottom"
                                menu={{
                                  items: [
                                    {
                                      key: '1',
                                      label: (
                                        <TooltipButton
                                          propsTooltip={{
                                            title: t('Сохранить'),
                                          }}
                                          propsButton={{
                                            className: table['table__button'],
                                            shape: 'circle',
                                            icon: <CheckOutlined />,
                                            onClick: () =>
                                              submitContainer(index),
                                          }}
                                        />
                                      ),
                                    },
                                    {
                                      key: '2',
                                      label: (
                                        <TooltipButton
                                          propsTooltip={{ title: t('Закрыть') }}
                                          propsButton={{
                                            className: table['table__button'],
                                            shape: 'circle',
                                            icon: <CloseOutlined />,
                                            onClick: () =>
                                              closeEditContainer(index),
                                          }}
                                        />
                                      ),
                                    },
                                  ],
                                  style: {
                                    background: 'transparent',
                                    boxShadow: 'none',
                                  },
                                }}
                                trigger={['click']}
                              >
                                <Typography.Link
                                  className=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <Icon component={moreIcon} />
                                </Typography.Link>
                              </Dropdown>
                            ) : (
                              <Dropdown
                                overlayClassName={table.drop}
                                menu={{
                                  items: getItems(
                                    () => {
                                      if (containers[index]?.deal?.id) {
                                        setConfirmation(true);
                                        setIndexCurrent(index);
                                        return;
                                      }
                                      operation.remove(index);
                                    },
                                    operation.add,
                                    index,
                                  ),
                                  style: {
                                    background: 'transparent',
                                    boxShadow: 'none',
                                  },
                                }}
                                placement="bottom"
                                trigger={['click']}
                              >
                                <Typography.Link
                                  className=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <Icon component={moreIcon} />
                                </Typography.Link>
                              </Dropdown>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <ModalConfirm
                      title={title}
                      subtitle={subtitle}
                      isOpen={confirmation}
                      closeModal={() => setConfirmation(false)}
                      actionAfterConsent={() => removeField()}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Form.List>
      </Flex>
      <Flex vertical gap={30} style={{ marginBottom: '24px' }}>
        <Form.Item label={t('Итого')} name="total_ktk">
          <Input disabled placeholder="" />
        </Form.Item>
        <Form.Item label={t('Остаток долга')} name="balance_ktk">
          <Input disabled placeholder="" />
        </Form.Item>
      </Flex>
    </Flex>
  );
};

export default TabTable;
