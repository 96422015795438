import React from 'react';

import { useTranslation } from 'react-i18next';
import { Avatar, Col, Divider, Flex, Row, Typography } from 'antd';

import Icon, { UserOutlined } from '@ant-design/icons';

import { Icons } from './Icons';
import { useLink } from '../../hooks';
import { COMPANY } from '../../constants';
import Button from '../../components/ui/Button/Button';
import IconButton from '../../components/ui/IconButton';
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import {
  CopyBufferIconDefault,
  copyIcon,
  EmailIcon,
  flagRuIcon,
} from '../../assets/image/svg';

import './style.scss';
import { IContractors } from '../../types/contractors';

const { Text, Title } = Typography;

interface IViewContractor {
  initialValue: IContractors;
  isCopy?: boolean;
  onEdit: () => void;
  onClose: () => void;
}

const FormCardContractors: React.FC<IViewContractor> = ({
  initialValue,
  isCopy,
  onClose,
  onEdit,
}) => {
  const { t } = useTranslation();
  const link = useLink({ key: COMPANY, id: initialValue?.id as number });
  const internal_company: any = initialValue.internal_company ?? '';
  const address: any = initialValue.address ?? '';
  return (
    <Flex vertical>
      <Flex justify={'space-between'} className={'drawer-header'}>
        <Flex vertical style={{ width: '100%' }}>
          <Flex
            gap={4}
            justify={'space-between'}
            align={'center'}
            style={{ marginBottom: 12 }}
          >
            <div className={'drawer-title'}>
              {initialValue?.name_company ?? ''}
            </div>
            <Flex gap={5} align={'center'}>
              <IconButton
                iType="edit-form"
                onClick={onEdit}
                disabled={!!initialValue?.delete}
              />
              {isCopy && (
                <CopyButton
                  text={link}
                  textSuccess={t('Ссылка скопирована')}
                  icon={<Icon component={CopyBufferIconDefault} />}
                />
              )}
            </Flex>
          </Flex>
          <Flex gap={4} vertical>
            <h3>Внутреннее название компании</h3>
            <Text>{initialValue?.internal_company ?? ''}</Text>
          </Flex>
          <Divider style={{ marginTop: 15, marginBottom: 15 }} />
          <Flex gap={4} vertical>
            <h3>Тип компании</h3>
            <Text>{initialValue?.type_company ?? ''}</Text>
          </Flex>
          <Divider style={{ marginTop: 15, marginBottom: 15 }} />
          <Flex gap={4} vertical>
            <h3>Источник</h3>
            <Text>{initialValue?.type_source ?? ''}</Text>
          </Flex>
          <Divider style={{ marginTop: 15, marginBottom: 15 }} />
          <Flex gap={4} vertical>
            <Flex gap={4} vertical>
              <h3>Контакты</h3>
              <Row>
                <Col className="gutter-row" span={10}>
                  <Text>{initialValue?.type_phone_first ?? ''}</Text>
                </Col>
                {initialValue?.phone_first && (
                  <>
                    <Col className="gutter-row" span={12}>
                      <Flex gap={4}>
                        <Icon component={flagRuIcon} />
                        <Text>{initialValue?.phone_first ?? ''}</Text>
                      </Flex>
                    </Col>
                    <Col className="gutter-row" span={2}>
                      <CopyButton
                        text={initialValue?.phone_first ?? ''}
                        icon={<Icon component={copyIcon} />}
                      />
                    </Col>
                  </>
                )}
              </Row>
              {initialValue?.last_phone?.map((item: any, index: number) => {
                return (
                  <Row key={index}>
                    <Col className="gutter-row" span={10}>
                      <Text>{item.add_type_phone}</Text>
                    </Col>
                    {item?.add_phone && (
                      <>
                        <Col className="gutter-row" span={12}>
                          <Flex gap={4}>
                            <Icon component={flagRuIcon} />
                            <Text>{item?.add_phone}</Text>
                          </Flex>
                        </Col>
                        <Col className="gutter-row" span={2}>
                          <CopyButton
                            text={item?.add_phone}
                            icon={<Icon component={copyIcon} />}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                );
              })}
            </Flex>
            <Flex gap={4} vertical>
              {initialValue?.email_first !== null && (
                <Row>
                  <Col className="gutter-row" span={10}>
                    <Text>{initialValue?.type_email_first ?? ''}</Text>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Flex gap={4} align={'center'}>
                      <Button icon={<EmailIcon />} type={'icon'} />
                      <Text>{initialValue?.email_first ?? ''}</Text>
                    </Flex>
                  </Col>
                  <Col className="gutter-row" span={2}>
                    <CopyButton
                      text={initialValue?.email_first ?? ''}
                      icon={<Icon component={copyIcon} />}
                    />
                  </Col>
                </Row>
              )}
              {initialValue?.last_email?.map((item: any, index: number) => {
                return (
                  <Row key={index}>
                    <Col className="gutter-row" span={10}>
                      <Text>{item.add_type_email}</Text>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Flex gap={4}>
                        <Button icon={<EmailIcon />} type={'icon'} />
                        <Text>{item.add_email}</Text>
                      </Flex>
                    </Col>
                    <Col className="gutter-row" span={2}>
                      <CopyButton
                        text={item.add_email}
                        icon={<Icon component={copyIcon} />}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Flex>
            <Flex gap={4} vertical>
              {initialValue?.messenger_first && (
                <Row>
                  <Col className="gutter-row" span={10}>
                    <Text>{initialValue?.type_messenger_first ?? ''}</Text>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Flex gap={4}>
                      <Icons.arrIcon
                        type={initialValue?.type_messenger_first ?? ''}
                      />
                      <Text>{initialValue?.messenger_first}</Text>
                    </Flex>
                  </Col>
                  <Col className="gutter-row" span={2}>
                    <CopyButton
                      text={initialValue?.messenger_first ?? ''}
                      icon={<Icon component={copyIcon} />}
                    />
                  </Col>
                </Row>
              )}
              {initialValue?.last_messenger?.map(
                (item: any, index: number) => {
                  return (
                    <Row key={index}>
                      <Col className="gutter-row" span={10}>
                        <Text>{item.add_messenger}</Text>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <Flex gap={4}>
                          <Icons.arrIcon type={item.add_type_messenger} />
                          <Text>{item.add_type_messenger}</Text>
                        </Flex>
                      </Col>
                      <Col className="gutter-row" span={2}>
                        <CopyButton
                          text={item.add_type_messenger}
                          icon={<Icon component={copyIcon} />}
                        />
                      </Col>
                    </Row>
                  );
                },
              )}
            </Flex>
          </Flex>
          <Divider style={{ marginTop: 15, marginBottom: 15 }} />
          <Flex gap={4} vertical>
            <h3>Контакт</h3>
            <Text>
              {initialValue?.contact_name &&
                initialValue?.contact_name + ' '}
              {initialValue?.contact_phone &&
                initialValue?.contact_phone + ' '}
              {initialValue?.contact_email && initialValue?.contact_email}
            </Text>
          </Flex>
          <Divider style={{ marginTop: 15, marginBottom: 15 }} />
          <Flex gap={4} vertical>
            <h3>Реквизиты</h3>
            <Text>{internal_company + ' ' + address}</Text>
          </Flex>
          <Divider />
          <Flex gap={6} vertical>
            <Title level={4} style={{ marginBottom: 0 }}>
              Дополнительно
            </Title>
            <Text style={{ marginTop: 0 }}>Ответственный</Text>
            <Flex
              justify={'space-between'}
              align={'center'}
              style={{ paddingRight: 7 }}
            >
              {!!initialValue?.users?.fio && (
                <Flex gap={6} align={'center'}>
                  <Avatar size={28} icon={<UserOutlined />} />
                  <Text style={{ marginTop: 0, color: '#05F' }}>
                    {initialValue?.users?.fio}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex vertical style={{ marginTop: 10, marginBottom: 20 }}>
            <Title level={4} style={{ marginBottom: 6 }}>
              Комментарий
            </Title>
            <div>{initialValue?.comment ?? ''}</div>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={8} justify="end" style={{ margin: '32px' }}>
        <Button onClick={onClose} type="primary" style={{ width: '174px' }}>
          {t('Закрыть')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default FormCardContractors;
