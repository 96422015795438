import React, { useEffect, useState } from "react";
import { Form, Input, Col, Row, Divider, Button } from "antd";
import { purchaseColumns, subleaseColumns, ktkColumns } from "./columns";
import EditableTable from "../table/Table";
import Icon from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { successIcon } from "../../../../../assets/image/svg";
import { errorImportIcon } from "../../../../../assets/image/svg";
import style from "../style.module.scss";
import FormList from "antd/es/form/FormList";

interface ApprovalTabProps {
  form?: any;
  initialValue: any;
  isAgreed: boolean;
  toggleApproval: () => void;
  returnToRevision: () => void;
  tableName: string;
  data: any;
  setApprovalState: (value: boolean) => void; 
}

const ApprovalTab: React.FC<ApprovalTabProps> = ({
  initialValue,
  isAgreed,
  toggleApproval,
  returnToRevision,
  form,
  tableName,
  data,
  setApprovalState,
}) => {
  const [formData, setFormData] = useState(initialValue);

  const locationReturnList = form.getFieldValue("location_return") || [];

  useEffect(() => {
    if (initialValue) {
      setFormData({ ...initialValue, ...initialValue });
    }
  }, [initialValue]);

  

  useEffect(() => {
    if (form) {
      const approvedValue = form.getFieldValue("approved");
      if (approvedValue !== undefined) {
        setApprovalState(!!approvedValue); 
      }
    }
  }, [form, setApprovalState]);

  const filteredPurchase = initialValue?.purchase?.filter((row: any) =>
    Object.values(row).some((val) => val)
  ) || [];
  const filteredSublease = initialValue?.sublease?.filter((row: any) =>
    Object.values(row).some((val) => val)
  ) || [];
  const filteredKtk = initialValue?.ktk?.filter((row: any) =>
    Object.values(row).some((val) => val)
  ) || [];

  return (
    <Form layout="vertical" form={form} className={style.requestTab}>
      <Form.Item label="Название" name="name_request">
        <Input
          disabled
          value={data}
          className={style.inputName}
          style={{ color: "#0E1117", backgroundColor: "#0e111700" }}
        />
      </Form.Item>
      <Row gutter={24}>
        <Col span={9} className={style.approvalInputs}>
          <Form.Item label="Наше юридическое лицо" name="my_company">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Клиент" name="company">
            <Input disabled />
          </Form.Item>

          <Form.Item label="№ Договора" name="agreement">
            <Input disabled />
          </Form.Item>

          {/* <Form.Item label="Локация релиза" name="location_release">
            <Input disabled />
          </Form.Item> */}

          <Row gutter={16} align="middle" style={{ paddingLeft: '15px', width: '345px' }}>
            {locationReturnList.map((loc: any, index: number) => (
              <Row key={index} gutter={16} align="middle" className={style.locationReturnApprooval}>
                {/* <Col span={12}> */}
                  {/* <Form.Item label={`Локация возврата #${index + 1}`}> */}
                  <Form.Item label={`Локация возврата`}>
                    <Input value={loc.location} disabled style={{ width: '335px' }}/>
                  </Form.Item>
                {/* </Col> */}
                {/* <Col span={12}> */}
                  <Form.Item label="Ставка">
                    <Input value={loc.bet} disabled style={{ width: '335px' }}/>
                  </Form.Item>
                {/* </Col> */}
              </Row>
            ))}
            {/* <Col span={12}>
              <Form.Item label="Локация возврата" name="location_return">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ставка" name="bet">
                <Input disabled />
              </Form.Item>
            </Col> */}
          </Row>

          <Form.Item label="Тип КТК" name="type_container">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Состояние" name="condition">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Количество КТК" name="count_ktk">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Валюта" name="currency">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Количество дней" name="count_day">
            <Input disabled />
          </Form.Item>

          <Form.Item label="СНП" name="snp">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col
          span={15}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginTop: "10px",
          }}
        >
          <div className={style.ApprovalTableContainer}>
            <div className={style.ApprovalTable}>
              {filteredPurchase.length > 0 && (
                <>
                  <Divider className={style.RequestTablesDivider}>Закуп</Divider>
                  <Form.List name="purchase" initialValue={filteredPurchase}>
                    {(fields, operation) => (
                      <EditableTable
                        columns={purchaseColumns}
                        fields={fields}
                        rowCount={filteredPurchase.length}
                        columnWidths="15% 11% 9% 13% 15% 17% 20%"
                        isEditable={false}
                      />
                    )}
                  </Form.List>
                </>
              )}
            </div>

            <div className={style.ApprovalTable}>
              {filteredSublease.length > 0 && (
                <>
                  <Divider className={style.RequestTablesDivider}>
                    {tableName}
                  </Divider>
                  <Form.List name="sublease" initialValue={filteredSublease}>
                    {(fields, operation) => (
                      <EditableTable
                        columns={subleaseColumns}
                        fields={fields}
                        rowCount={filteredSublease.length}
                        columnWidths="15% 11% 8% 13% 11% 16% 11% 7% 8%"
                        isEditable={false}
                      />
                    )}
                  </Form.List>
                </>
              )}
            </div>

            <div className={style.ApprovalTable}>
              {filteredKtk.length > 0 && (
                <>
                  <Divider className={style.RequestTablesDivider}>КТК МК</Divider>
                  <Form.List name="ktk" initialValue={filteredKtk}>
                    {(fields, operation) => (
                      <EditableTable
                        columns={ktkColumns}
                        fields={fields}
                        rowCount={filteredKtk.length}
                        columnWidths="14% 11% 11% 13% 15% 19% 17%"
                        isEditable={false}
                      />
                    )}
                  </Form.List>
                </>
                )}
            </div>
          </div>
          <div className={style["additional"]}>
            <Form.Item
              name="approved"
              label="Название"
              rules={[{ required: false }]}
            >
              <div onClick={toggleApproval} style={{ cursor: "pointer" }}>
                {isAgreed ? (
                  <Form.Item className={style["agreed"]} name="approved">
                    <div className={style["icon-container"]}>
                      <Icon
                        component={successIcon}
                        className={style["success-icon"]}
                      />
                    </div>
                    Согласовано
                  </Form.Item>
                ) : (
                  <Form.Item className={style["not-agreed"]} name="approved">
                    <div className={style["error-icon-container"]}>
                      <Icon
                        component={errorImportIcon}
                        className={style["error-icon"]}
                      />
                    </div>
                    Не согласовано
                  </Form.Item>
                )}
              </div>
            </Form.Item>

            <div className={style.Return}>
              <div className="returnContainer">
                <p>Вернуть на доработку</p>
                <Button
                  className="reset"
                  icon={<ReloadOutlined />}
                  style={{ marginRight: "16px" }}
                  onClick={returnToRevision}
                ></Button>
              </div>
              <Form.Item
                name="return_to_revision"
                label="Причина"
                rules={[
                  {
                    // required: true,
                    // message: "Обязательное поле!",
                    whitespace: true,
                  },
                ]}
              >
                <Input.TextArea placeholder="Введите причину" />
              </Form.Item>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ApprovalTab;
