import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Select,
  Typography,
} from 'antd';

import Icon from '@ant-design/icons';

import { Status } from './Status';
import { useLink } from '../../../hooks';
import style from '../style.module.scss';
import { IForm } from '../../../store/users';
import IconButton from '../../ui/IconButton';
import { AvatarProfile } from './AvatarProfile';
import { Checkbox } from '../../ui/Checkbox/Checkbox';
import CopyButton from '../../ui/CopyButton/CopyButton';
import { Rest_files } from '../../../services/rest_files';
import { CONTACT, messagesModal } from '../../../constants';
import { RootState, useStoreDispatch } from '../../../store';
import ModalConfirm from '../../ui/ModalConfirm/ModalConfirm';
import TooltipButton from '../../ui/TooltipButton/TooltipButton';
import { CopyBufferIconDefault } from '../../../assets/image/svg';
import { BUSINESS_TRIP, VACATION, statusIcons } from '../constants';
import CustomDate from '../../CustomDate';
import { EDIT, VIEW } from '../../../helpers/string-helpers';

export const FormProfile: React.FC<IForm> = ({
  initialValue,
  isCopy,
  isEdit,
  title,
  typeForm,
  onClose,
  onEdit,
  onView,
}) => {
  const link = useLink({ key: CONTACT, id: initialValue?.id as number });
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [form] = Form.useForm();

  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [icons, setIcons] = useState<any[]>([]);

  const [departmentData, setDepartmentData] = useState<any[]>([]);
  const dataCompany = useSelector(
    (state: RootState) => state.companies.dataCompany,
  );
  const status: string = Form.useWatch('status', {
    form,
    preserve: true,
  });
  const director: string = Form.useWatch('director', {
    form,
    preserve: true,
  });

  const isDisabled = !!initialValue?.id;

  useEffect(() => {
    form.setFieldValue('director', 'ФИО');
  }, []);
  useEffect(() => {
    if (typeForm === VIEW) {
      form.setFieldsValue(initialValue);
    }
  }, [typeForm]);

  useEffect(() => {
    setIcons(statusIcons?.[status] ? [statusIcons?.[status]] : []);
  }, [status]);

  const handleClose = (res?: boolean) => {
    onClose(res);
    form.resetFields();
  };

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      if (values?.saveFiles?.length) {
        const res = await Rest_files.submitFiles(values.saveFiles);
        if (res.data?.length) {
          values.file_ids.push(...res.data);
        }
      }
      values.saveFiles = [];
      console.log(values);

      handleClose(true);
      form.resetFields();
    } catch (err) {
      console.error('submit', err);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async (changedValues: any, allValues: any) => {};

  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (!outOfDate && errorFields) {
      const isComplectCheckErrors = [];
      for (const errors of errorFields) {
        const title = errors?.[0];
        if (isComplectCheckErrors.length === 2) {
          break;
        }
      }
    }

    return;
  };

  const toggleStatus =
    (name: 'vacation' | 'business_trip') => (bol: boolean) => {
      switch (name) {
        case VACATION:
          if (bol) {
            form.setFieldValue('status', name);
          } else {
            if (status === name) {
              form.setFieldValue('status', undefined);
            }
          }
          return;
        case BUSINESS_TRIP:
          if (bol) {
            form.setFieldValue('status', BUSINESS_TRIP);
            return;
          } else {
            if (status === name) {
              form.setFieldValue('status', undefined);
            }
          }
      }
    };

  const disabledForm = typeForm === VIEW;

  return (
    <Form
      className={style['form']}
      autoComplete="off"
      noValidate
      layout="vertical"
      form={form}
      initialValues={initialValue}
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={onFinishError}
      onValuesChange={onUpdate}
      disabled={disabledForm}
    >
      <Flex vertical gap={24}>
        <Flex vertical gap={40}>
          <Flex justify="space-between" align="center">
            <Flex gap={20} align="center" style={{ marginTop: 15 }}>
              <Typography.Title level={2} className={style.title}>
                {title}
              </Typography.Title>
              <Status isActive={isActive} icons={icons} />
            </Flex>
            <Flex gap={5} align={'center'}>
              {isEdit && (
                <IconButton
                  disabled={false}
                  iType={`${disabledForm ? EDIT : VIEW}-form`}
                  onClick={disabledForm ? onEdit : onView}
                />
              )}
              {isDisabled && isCopy && (
                <CopyButton
                  text={link}
                  textSuccess={t('Ссылка скопирована')}
                  icon={<Icon component={CopyBufferIconDefault} />}
                />
              )}
            </Flex>
          </Flex>
          <Flex gap={5} vertical>
            <Typography.Title level={3} className={style['subtitle']}>
              {t('Контактная информация')}
            </Typography.Title>
            <Divider style={{ margin: 0 }} />
          </Flex>
        </Flex>
        <Flex gap={30}>
          <AvatarProfile size={90} user={initialValue} />
          <Flex gap={20} flex={1}>
            <Col flex={1} style={{ maxWidth: '33%' }}>
              <Flex vertical gap={15}>
                <Form.Item label={t('Должность')} name="position">
                  <Input allowClear />
                </Form.Item>
                <Form.Item label={t('Руководитель')} shouldUpdate>
                  <div className={style['input-block']}>
                    <Typography.Text
                      style={{ maxWidth: '100%' }}
                      ellipsis={{ tooltip: { title: director || '' } }}
                    >
                      {t(director || '')}
                    </Typography.Text>
                  </div>
                </Form.Item>
              </Flex>
            </Col>
            <Col flex={1} style={{ maxWidth: '33%' }}>
              <Flex vertical gap={15}>
                <Form.Item
                  label={'Email'}
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: 'Обязательное поле!',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item label={t('Отдел')} name="department">
                  <Select
                    options={departmentData}
                    onSelect={(id) => {
                      form.setFieldValue('department_id', id);
                    }}
                  />
                </Form.Item>
              </Flex>
            </Col>
            <Col flex={1}>
              <Flex vertical gap={15}>
                <Flex vertical style={{ minHeight: '55px' }}>
                  <Typography.Text className={style.label}>
                    {t('Локальное время')}
                  </Typography.Text>
                  <CustomDate format="HH:mm" duration={60000} />
                </Flex>
                <Flex vertical style={{ minHeight: '55px' }}>
                  <Typography.Text className={style.label}>
                    {t('Статус')}
                  </Typography.Text>
                  <Flex
                    justify="flex-start"
                    align="center"
                    gap={30}
                    style={{ minHeight: '32px' }}
                  >
                    <Checkbox
                      isChoice={status === VACATION}
                      customChange={toggleStatus(VACATION)}
                    >
                      <Typography.Text className={style.checkboxes__label}>
                        {t('Отпуск')}
                      </Typography.Text>
                    </Checkbox>
                    <Checkbox
                      isChoice={status === BUSINESS_TRIP}
                      customChange={toggleStatus(BUSINESS_TRIP)}
                    >
                      <Typography.Text className={style.checkboxes__label}>
                        {t('Командировка')}
                      </Typography.Text>
                    </Checkbox>
                  </Flex>
                </Flex>
              </Flex>
            </Col>
          </Flex>
        </Flex>
      </Flex>
      <Form.Item>
        <Flex
          gap={8}
          justify={'space-between'}
          style={{
            maxWidth: '550px',
            width: '100%',
            margin: 'auto auto 10px auto',
          }}
        >
          {!disabledForm && (
            <TooltipButton
              propsTooltip={{
                title: isDisabled ? t('Сохранить изменения') : t('Сохранить'),
              }}
              propsButton={{
                type: 'primary',
                htmlType: 'submit',
                style: { maxWidth: '290px', minWidth: '290px' },
                disabled: isLoading,
              }}
            >
              {isLoading
                ? '...Сохранение'
                : initialValue?.id
                ? t('Сохранить изменения')
                : t('Сохранить')}
            </TooltipButton>
          )}
          <Button
            onClick={() => setConfirmation(true)}
            type={'text'}
            style={{
              marginLeft: 'auto',
              maxWidth: '290px',
              width: '100%',
              color: '#E14453',
            }}
            disabled={isLoading}
          >
            {t('Отменить')}
          </Button>
        </Flex>
      </Form.Item>
      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </Form>
  );
};
