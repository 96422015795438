import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState, useStoreDispatch } from "../../../../../store";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Divider,
  Button,
  Checkbox,
  SelectProps,
  Flex,
  Tooltip,
  App
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { searchTerminalCity } from "../../../../../store/terminals";
import { purchaseColumns, subleaseColumns, ktkColumns } from "./columns";
import { IContainer } from "../../../../../store/releases";
import { getSizeKtk } from "../../../../releases/components/utils";
import { Utils } from "../../../../../utils";
import { findCompany } from "../../../../../store/contractors";
import { findExecutor } from "../../../../../store/contact";
import { ICurrency } from "../../../../../types";
import { SelectSearch } from "../../../../../components/ui/SelectSearch/SelectSearch";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "../style.module.scss";
import CancelReasonModal from "./CancelReasonModal";
import EditableTable from "../table/Table";
import { Rest_rent } from "../../../../../services/rest_rent";
import { findAgreementsCompany } from "../../../../../store/sales";
import socket from "../../../../../socket";
import { CREATE, EDIT, VIEW } from '../../../../../helpers/string-helpers';
import { FormSelectSearch } from "../../../../../components/ui/Form";
import { mapperSearchYandexLocation } from "../../../deals/utils";
import { FormList } from "../../../../../components/ui/Form/FormList/FormList";
import { defaultLocationReturn } from "../../../../../store/rent";

interface RequestTabProps {
  form?: any;
  initialValue: any;
  typeForm?: string;
  dataRowContainer?: any;
  onFinish: (requestData: any, action: string) => void;
  isAgreed: boolean;
  onTableNameChange: (name: string) => void;
  tableName: string;
  onRequestDataSubmit: (data: any) => void;
  onRowSelectionChange: (type: string, selectedRows: Set<number>) => void;
  selectedRows: {
    purchase: Set<number>;
    sublease: Set<number>;
    ktk: Set<number>;
  };
  saveAllData?: () => void;
  isDisabled?: boolean;
}

const RequestTab: React.FC<RequestTabProps> = ({
  typeForm,
  initialValue,
  dataRowContainer,
  onFinish,
  isAgreed,
  form,
  onTableNameChange,
  tableName,
  onRequestDataSubmit,
  onRowSelectionChange,
  selectedRows,
  saveAllData,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const dataTypeContainers = useSelector(
    (state: RootState) => state.types.dataTypeContainers
  );
  const containers = Form.useWatch("containers", {
    form,
    preserve: true,
  }) as IContainer[];
  const dataTypeQuality = useSelector(
    (state: RootState) => state.types.dataTypeQuality
  );
  const dataStatusContainer = useSelector(
    (state: RootState) => state.containers.dataStatusContainer
  );
  const dataCurrency = useSelector(
    (state: RootState) => state.types.dataTypeCurrency
  );
  const { message } = App.useApp();

  const dispatch = useStoreDispatch();
  const [isLeftColumnActive, setIsLeftColumnActive] = useState(true);
  const [returnReason, setReturnReason] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState<string[] | null>(
    null
  );
  const [companyData, setCompanyData] = useState<SelectProps["options"]>([]);
  const [changeCompany, setChangeCompany] = useState<string[]>([]);
  const [city, setCity] = useState<[]>([]);
  const [cityData, setDataCity] = useState<any>([]);
  const [searchCity, setSearchCity] = useState<string | null>(null);
  const [icons, setIcons] = useState<{ [key: string]: JSX.Element }>({});
  const [responsible, setResponsible] = useState(Utils.getUserInfo().fio);
  const [dataExecutor, setDataExecutor] = useState<[]>([]);
  const [responsibleChange, setResponsibleChange] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [columnName, setColumnName] = useState<string>("");
  const [dataAgreements, setDataAgreements] = useState<any>([]);
  const my_company_id = Form.useWatch("my_company_id", { form, preserve: true });
  const company_id = Form.useWatch("company_id", { form, preserve: true });
  const [selectedCheckbox, setSelectedCheckbox] = useState<string | null>(null);
  const [arHistory, setArHistory] = useState<any[]>([
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
  ]);

  const handleOpenCancelReasonModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSaveReasons = (reasons: string[]) => {
    setSelectedReasons(reasons);
  };

  const handleColumnActiveClick = async () => {
    try {
      await form.validateFields([
        "name_request",
        "my_company",
        "company",
        "count_day",
      ]);
      setIsLeftColumnActive(false);
      console.log("Передача снабжению: поля успешно прошли валидацию.");
    } catch (error) {
      console.error("Ошибка валидации формы для передачи снабжению:", error);
    }
  };

  const handleReturnClick = async () => {
    try {
      await form.validateFields(["reason"]);
      setIsLeftColumnActive(true);
      console.log("Возврат аренде: причина указана.");
    } catch (error) {
      console.error("Ошибка валидации поля 'Причина':", error);
    }
  };

  const selectedType = (type?: string) => {
    const result = (containers || [])
      .filter((cont) => cont?.type_container_id === type)
      .map((item) => ({
        ...item,
        size: getSizeKtk(type),
        type_container: type,
        type_container_id: type,
      }));
    form.setFieldsValue({
      containers: result,
    });
  };

  const selectQualityContainer = (val: string) => {
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["condition"] === val
      );
      if (!isFind) {
        arHistory[2].map((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer.condition;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Состояние"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = val;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Состояние"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[2].map((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const handleCurrency = (val: string, record: any) => {
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["currency"] === val
      );
      if (!isFind) {
        arHistory[6].map((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer.currency;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Валюта"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = val;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Валюта"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[6].map((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCity(event.target.value.toLocaleLowerCase());
    if (event.target.value === "") {
      setSearchCity(null);
    }
    dispatch(searchTerminalCity(event.target.value.toLocaleLowerCase())).then(
      (response) => {
        setCity(response.payload);
      }
    );
  };

  const changePerson = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result: any = await dispatch(findExecutor(event.target.value));
    if (result.payload.length > 0) {
      setDataExecutor(result.payload);
    }
  };

  const handleSearchCompany = async (val: string) => {
    const result: any = await dispatch(findCompany(val.toLocaleLowerCase()));
    if (result.payload.length > 0) {
      setCompanyData(result.payload);
    }
  };

  const handleChangeCompany = (data: any) => {
    setChangeCompany(data);
  };

  const onChangeReleaseLocation = (e: number, option: any) => {
    const value = option?.children || option?.label;
    form.setFieldsValue({ location_release: value });
  };

  const onChangeReturnLocation = (e: number, option: any) => {
    const value = option?.children || option?.label;
    form.setFieldsValue({ location_return: value });
  };

  const onChangeRequestAuthor = (e: number, option: any) => {
    let fio = "";

    if (option?.children?.props?.children) {
      fio = option.children.props.children;
    } else if (option?.label) {
      fio = option.label;
    }

    form.setFieldsValue({ request_author: fio });
  };

  const onChangeSupplyDepartment = (e: number, option: any) => {
    let fio = "";

    if (option?.children?.props?.children) {
      fio = option.children.props.children;
    } else if (option?.label) {
      fio = option.label;
    }
    form.setFieldsValue({ supply_department: fio });
  };

  const onChangeDirector = (e: number, option: any) => {
    let fio = "";

    if (option?.children?.props?.children) {
      fio = option.children.props.children;
    } else if (option?.label) {
      fio = option.label;
    }
    form.setFieldsValue({ director: fio });
  };

  const onChangeOperationalDepartment = (e: number, option: any) => {
    let fio = "";

    if (option?.children?.props?.children) {
      fio = option.children.props.children;
    } else if (option?.label) {
      fio = option.label;
    }
    form.setFieldsValue({ operational_department: fio });
  };

  const handleCheckboxChange = (type: string) => {
    setSelectedCheckbox(type);
    form.setFieldsValue({
        sublease: form.getFieldValue("sublease").map((entry: any) => ({
            ...entry,
            type_table: type === "Передислокация" ? false : type === "Аренда", // Обработка обоих чекбоксов
        })),
    });

    let name = type === "Аренда" ? "Субаренда" : "Эвакуация";
    onTableNameChange(name);
};

  const resetCheckboxes = () => {
    setColumnName("   ");
  };

  useEffect(() => {
    if (responsible) {
      form.setFieldsValue({ request_author: responsible });
    }
  }, [responsible, form]);

  const handleRowSelect = (
    type: "purchase" | "sublease" | "ktk",
    index: number,
    selected: boolean
  ) => {
    const updatedSet = new Set(selectedRows[type]);
    if (selected) updatedSet.add(index);
    else updatedSet.delete(index);
    onRowSelectionChange(type, updatedSet);
  };

  const handleSomeButtonClick = () => {
    if (saveAllData) {
      saveAllData();
    }
  };

  useEffect(() => {
    if (initialValue && initialValue.sublease) {
        const typeTableValue = initialValue.sublease[0]?.type_table;
        if (typeTableValue === true) {
            setSelectedCheckbox("Аренда");
            onTableNameChange("Субаренда");
        } else if (typeTableValue === false) {
            setSelectedCheckbox("Передислокация");
            onTableNameChange("Эвакуация");
        } else {
            setSelectedCheckbox(null);
            onTableNameChange("");
        }
    }
  }, [initialValue, onTableNameChange]);

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValue}
      className={styles.requestTab}
      autoComplete="off"
      style={{
        pointerEvents: isAgreed ? "none" : "auto",
        opacity: isAgreed ? 0.5 : 1,
      }}
    >
      <Button
        type="primary"
        className={styles.sendButton}
        onClick={handleSomeButtonClick}
      >
        Отправить на согласование
      </Button>
      <Form.Item
        name="name_request"
        label="Название"
        rules={[{ required: false }]}
      >
        <Input placeholder="Введите название" />
      </Form.Item>
      <Row gutter={24}>
        <Col
          span={9}
          style={{ pointerEvents: isLeftColumnActive ? "auto" : "none" }}
        >
          <Form.Item
            name="my_company"
            label="Наше юридическое лицо"
            rules={[{ required: true, message: "Обязательное поле!" }]}
          >
            <Input placeholder="Введите название юридического лица" />
          </Form.Item>

          <Form.Item
            label="Клиент"
            name="company"
            rules={[{ required: true, message: "Обязательное поле!" }]}
          >
            <Select
              value={selectedCompanies}
              showSearch
              placeholder={"Название компании"}
              filterOption={false}
              onSearch={handleSearchCompany}
              onChange={handleChangeCompany}
              notFoundContent={null}
              options={(companyData || []).map((d) => ({
                value: d.value,
                label: d.label,
              }))}
            />
          </Form.Item>

          <Form.Item name="agreement" label="№ Договора">
            <Input placeholder="Введите номер договора"/>
          </Form.Item>

          {/* <Form.Item
            name="location_release"
            label="Локация релиза"
            style={{ flex: 1 }}
          >
            <FormSelectSearch
              allowClear
              showSearch
              placeholder={'Поиск'}
              suffixIcon={null}
              filterOption={false}
              notFoundContent={null}
              api={Utils.yandexCity}
              mapResponse={mapperSearchYandexLocation}
            />
          </Form.Item> */}
          <Row gutter={16} align="middle" className={styles.locationRelease} >
            <Form.Item name="request_release">
              <FormList
                nameList="location_release"          // Массив в форме, где будут храниться все локации
                title="Локация релиза"
                titleName="location_release"        // Ключ поля (в каждом элементе массива)
                type="search"
                typeOption={{
                  style: { maxWidth: "135px" },
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Поиск",
                  suffixIcon: null,
                  filterOption: false,
                  notFoundContent: null,
                  api: Utils.yandexCity,
                  mapResponse: mapperSearchYandexLocation,
                }}
                defaultItem={{
                  release: "",            // Начальное значение поля
                }}
                noScroll
                disabled={isDisabled}
              />
            </Form.Item>
          </Row>

          <Row gutter={16} align="middle" className={styles.locationReturn}>
            <Col span={8} className={styles.checkboxesWrapper}>
              <Checkbox
                checked={selectedCheckbox === "Аренда"}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleCheckboxChange("Аренда");
                  } else {
                    setSelectedCheckbox(null);
                    onTableNameChange(""); 
                  }
                }}
              >
                Аренда
              </Checkbox>
              <Checkbox
                checked={selectedCheckbox === "Передислокация"}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleCheckboxChange("Передислокация");
                  } else {
                    setSelectedCheckbox(null);
                    onTableNameChange(""); 
                  }
                }}
              >
                Передислокация
              </Checkbox>
            </Col>
            <Form.Item name="request_location">
              <FormList
              nameList="location_return"
              title="Локация возврата"
              titleName="location"
              type="search"
              typeOption={{
                style: { maxWidth: '135px' },
                allowClear: true,
                showSearch: true,
                placeholder: 'Поиск',
                suffixIcon: null,
                filterOption: false,
                notFoundContent: null,
                api: Utils.yandexCity,
                mapResponse: mapperSearchYandexLocation,
              }}
                labelList=""          
                defaultItem={{       
                  location: defaultLocationReturn,
                  bet: ""
                }}
                additionalInput={{
                  name: "bet",
                  label: "Ставка",
                  rules: [{ required: false, message: "Укажите ставку" }],
                  typeOption: {
                    placeholder: "Введите ставку",
                  }
                }}
                noScroll={true}
                disabled={isDisabled}
              />
            </Form.Item>
          </Row> 


          <Form.Item
            label={t("Тип КТК")}
            name="type_container"
            style={{ width: "100%" }}
          >
            <Select
              allowClear
              placeholder={t("Выберите тип")}
              style={{ width: "100%" }}
              options={dataTypeContainers}
              onSelect={selectedType}
            />
          </Form.Item>

          <Form.Item name={"condition"} label="Состояние">
            <Select
              allowClear
              options={dataTypeQuality}
              onSelect={selectQualityContainer}
              placeholder={"Состояние"}
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item name="count_ktk" label="Количество КТК">
            <Input placeholder="Введите количество КТК" />
          </Form.Item>
          <Form.Item name={"currency"} label="Валюта">
            <Select
              style={{ width: "100%" }}
              onChange={handleCurrency}
              placeholder={"Валюта"}
              allowClear
            >
              {dataCurrency?.map((item: ICurrency) => (
                <Select.Option key={item.id} value={item.symbol}>
                  <Flex align={"center"} gap={5}>
                    {icons[item.id]}
                    {item.symbol} {item.value}
                  </Flex>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="count_day"
            label="Количество дней*"
            rules={[
              {
                required: true,
                message: "Обязательное поле!",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Введите количество дней" />
          </Form.Item>

          <Form.Item name="snp" label="СНП">
            <Input />
          </Form.Item>

          <Form.Item name="request_author" label="Автор заявки">
            <SelectSearch
              defaults={responsible}
              notFoundContent="Пользователь не найден."
              dataSource={dataExecutor}
              onChangeSelect={onChangeRequestAuthor}
              dropdownRender={changePerson}
              style={{ width: "100%" }}
              placeholder="Ответственный"
            />
          </Form.Item>

          <Form.Item
            name="supply_department"
            label="Сотрудник отдела снабжения"
          >
            <SelectSearch
              defaults={
                responsibleChange || form.getFieldValue("supply_department")
              }
              notFoundContent={"Пользователь не найден."}
              dataSource={dataExecutor}
              onChangeSelect={onChangeSupplyDepartment}
              dropdownRender={changePerson}
              style={{ width: "100%" }}
              placeholder={""}
            />
          </Form.Item>

          <Form.Item name="director" label="Директор для согласования">
            <SelectSearch
              defaults={responsibleChange || form.getFieldValue("director")}
              notFoundContent={"Пользователь не найден."}
              dataSource={dataExecutor}
              onChangeSelect={onChangeDirector}
              dropdownRender={changePerson}
              style={{ width: "100%" }}
              placeholder={""}
            />
          </Form.Item>

          <Form.Item
            name="operational_department"
            label="Сотрудник оперативного отдела"
          >
            <SelectSearch
              defaults={
                responsibleChange || form.getFieldValue("operational_department")
              }
              notFoundContent={"Пользователь не найден."}
              dataSource={dataExecutor}
              onChangeSelect={onChangeOperationalDepartment}
              dropdownRender={changePerson}
              style={{ width: "100%" }}
              placeholder={""}
            />
          </Form.Item>

          <Row justify="space-between">
            <Col style={{ display: "flex" }}>
              <Button
                type="default"
                onClick={handleColumnActiveClick}
                style={{ height: "36px" }}
              >
                Передать снабжению
              </Button>
              <Button type="link" danger onClick={handleOpenCancelReasonModal}>
                Отменить запрос
              </Button>
              {selectedReasons.length > 0 && (
                <Tooltip
                  title={
                    <div>
                      {selectedReasons.map((reason, index) => (
                        <div key={index}>{reason}</div>
                      ))}
                    </div>
                  }
                >
                  <QuestionCircleOutlined
                    style={{ marginLeft: 8, color: "#1890ff" }}
                  />
                </Tooltip>
              )}
              <CancelReasonModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSave={handleSaveReasons}
              />
            </Col>
          </Row>
        </Col>

        <Col
          span={15}
          style={{
            pointerEvents: isLeftColumnActive ? "none" : "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Item name="purchase_type_id" style={{minHeight: '10px', margin: 0, alignItems: 'flex-end'}}>
            <Divider className={styles.RequestTablesDivider}>Закуп</Divider>
          </Form.Item>
          <Form.List
            name="purchase"
            initialValue={initialValue } 
          >
            {(fields, operation) => (
              <EditableTable
                fields={fields}
                columns={purchaseColumns}
                type="purchase"
                rowCount={fields.length}
                onAddRow={() => operation.add({})}
                columnWidths="15% 11% 9% 13% 15% 17% 20%"
                selectedRows={selectedRows.purchase}
                onRowSelect={(index, selected) =>
                  handleRowSelect("purchase", index, selected)
                }
              />
            )}
          </Form.List>

            <Divider className={styles.RequestTablesDivider}>{tableName}</Divider>
          <Form.List
            name="sublease"
            initialValue={
              initialValue?.sublease?.length
                ? [
                    ...initialValue.sublease,
                    ...Array(
                      Math.max(0, 7 - initialValue.sublease.length)
                    ).fill({})
                  ]
                : Array(7).fill({})
            }
          >
            {(fields, operation) => (
              <EditableTable
                fields={fields}
                type="sublease"
                columns={subleaseColumns}
                rowCount={fields.length}
                onAddRow={() => operation.add({})}
                columnWidths="15% 11% 8% 13% 11% 16% 11% 7% 8%"
                selectedRows={selectedRows.sublease}
                onRowSelect={(index, selected) =>
                  handleRowSelect("sublease", index, selected)
                }
              />
            )}
          </Form.List>

          <Form.Item name="ktk_type_id" style={{minHeight: '10px', margin: 0, alignItems: 'flex-end'}}>
            <Divider className={styles.RequestTablesDivider}>КТК МК</Divider>
          </Form.Item>
          <Form.List
            name="ktk"
            initialValue={
              initialValue?.ktk?.length
                ? [
                    ...initialValue.ktk,
                    ...Array(Math.max(0, 7 - initialValue.ktk.length)).fill({})
                  ]
                : Array(7).fill({})
            }
          >
            {(fields, operation) => (
              <EditableTable
                fields={fields}
                type="ktk"
                columns={ktkColumns}
                rowCount={fields.length}
                onAddRow={() => operation.add({})}
                columnWidths="14% 11% 11% 13% 15% 19% 17%"
                selectedRows={selectedRows.ktk}
                onRowSelect={(index, selected) =>
                  handleRowSelect("ktk", index, selected)
                }
              />
            )}
          </Form.List>

          <div className={styles.Return}>
            <div className="returnContainer">
              <p>Вернуть аренде</p>
              <Button
                className="reset"
                icon={<ReloadOutlined />}
                style={{ marginRight: "16px" }}
                onClick={handleReturnClick}
              ></Button>
            </div>
            <Form.Item
              name="return_rent_reason"
              label="Причина"
            >
              <Input.TextArea
                placeholder="Введите причину"
                onChange={(e) => setReturnReason(e.target.value)}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default RequestTab;
