import React, {
    Key,
    useEffect,
    useState,
    CSSProperties,
    useCallback,
} from 'react';
import classnames from 'classnames';
import {Flex, MenuProps, App, Pagination, Spin} from 'antd';
import {TableHeader} from './TableHeader';
import {TableRow} from './TableRow';
import {ColPage, ColPageMenu, Footer, TableWrapper} from './styled';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '../Checkbox/Checkbox';
import styleTable from './style.module.scss';

export interface IColumnType<T> {
    key: Key;
    title?: string;
    dataIndex: string;
    filterIndex: string;
    subTitle?: string;
    date?: boolean;
    avatar?: string;
    checkbox?: boolean;
    link?: boolean;
    menu?: boolean;
    type?: string | undefined;
    width?: number | string;
    widthHeader?: number | string;
    repair?: boolean;
    render?: (column: IColumnType<T>, item: T, index: number) => JSX.Element | null;
    [key: number]: string;
}

export interface IHiddenColumn<T> {
    type?: string;
    column?: number;
}

export interface IPagination<T> {
    total?: number;
    current: number;
    pageSize: number;
    showSizeChanger?: boolean;
}

interface Props<T> {
    className?: string,
    dataSource: T[],
    columns: IColumnType<T>[],
    style?: CSSProperties,
    pagination: IPagination<T>,
    onChangePagination?: (pagination: IPagination<T>) => void,
    onRow?: (record: T, rowIndex: number) => void,
    editRow?: (item: T) => void,
    handlerDeleteRow?: (row: T) => void,
    onClickLink?: (record: T, rowIndex: number) => void,
    height?: string,
    loading?: boolean,
    handlerOrder?: (sort: string) => void,
    filtersFetch?: (text: string, column: string) => Promise<any>,
    filtersColumn?: ({data, current, pageSize, order, options}: {
        data: any;
        current: number;
        pageSize: number;
        order?: string;
        options?: { [key: string]: any };
      }) => void;
    handlerCopyAll?: (selectedRows: any) => void,
    handlerDeleteCopyAll?: (selectedRows: any) => void,
    subMenu?: {
        label: string;
        getSubMenu: ({
                         item,
                         rows,
                         data,
                         onClose,
                     }: {
            item: any;
            rows: Set<any>;
            data: T[];
            onClose: () => void;
        }) => MenuProps['items'];
    },
    setColumnFilters?: (value: (((prevState: {}) => {}) | {})) => void,
    columnFilters?: {},
    setAllSelected?: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    allSelected?: boolean,
    setSelectedRows: (value: (((prevState: Set<number>) => Set<number>) | Set<number>)) => void,
    selectedRows: Set<number>
}

export function Table<T>({
                             dataSource,
                             columns,
                             pagination,
                             style,
                             onChangePagination,
                             onRow,
                             editRow,
                             handlerDeleteRow,
                             onClickLink,
                             height,
                             loading,
                             handlerOrder,
                             filtersFetch,
                             filtersColumn,
                             handlerCopyAll,
                             handlerDeleteCopyAll,
                             subMenu,
                             className = '',
                             setColumnFilters,
                             columnFilters,
                             setAllSelected,
                             allSelected,
                             setSelectedRows,
                             selectedRows
                         }: Props<T>): JSX.Element {
    const {t} = useTranslation();
    const {message} = App.useApp();
    const [hiddenColumns, setHiddenColumns] = useState<number[]>([]);
    const [pageSize, setPageSize] = useState(pagination.pageSize || 10);
    const [sortedData, setSortedData] = useState<T[]>(dataSource);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [currentPage, setCurrentPage] = useState(pagination.current || 1);
    const [confirmation, setConfirmation] = useState<boolean>(false);

    const onChangeCheck = useCallback(
        (index: number) => {
            if (!index) return;
            setHiddenColumns(
                hiddenColumns.includes(index)
                    ? hiddenColumns.filter((i) => i !== index)
                    : [...hiddenColumns, index],
            );
        },
        [hiddenColumns],
    );

    const content = (
        <div>
            {columns.map(
                (column, index) =>
                    column.dataIndex !== 'hidden' && !!index && (
                        <ColPageMenu key={index}>
                            <Checkbox stopChange onChange={(e: any) => onChangeCheck(index)}/>{' '}
                            {t(column.title ?? '')}
                        </ColPageMenu>
                    ),
            )}
        </div>
    );

    const handlePageChange = useCallback(
        (current: number, pageSize: number) => {
            setCurrentPage(current);
            if (pageSize) {
                setPageSize(pageSize);
            }
            if (onChangePagination) {
                onChangePagination({...pagination, current: current, pageSize});
            }
        },
        [onChangePagination, pagination],
    );

    useEffect(() => {
        setSortedData(dataSource);
    }, [dataSource]);

    const getNestedValue = useCallback((obj: T, path: string) => {
        const parts = path.split('.');
        let value: any = obj;
        for (let part of parts) {
            if (value && value[part] !== undefined) {
                value = value[part];
            } else {
                return undefined;
            }
        }
        return value;
    }, []);

    const sortData = useCallback(
        (dataSource: T[], column: string, order: 'asc' | 'desc') => {
            return dataSource.sort((a, b) => {
                const aValue = getNestedValue(a, column);
                const bValue = getNestedValue(b, column);
                if (aValue === undefined || bValue === undefined) {
                    console.error('Undefined value found for column:', column);
                    return 0;
                }

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return order === 'asc'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                }

                if (aValue < bValue) {
                    return order === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return order === 'asc' ? 1 : -1;
                }
                return 0;
            });
        },
        [getNestedValue],
    );

    const handleSelectAll = (checked: boolean) => {
        if (setAllSelected) {
            setAllSelected(checked);
        }
        if (checked) {
            const ids = new Set(dataSource.map((item: any) => item.id));
            if (setSelectedRows) {
                setSelectedRows(ids);
            }
        } else {
            if (setSelectedRows) {
                setSelectedRows(new Set());
            }
        }
    };

    const handleRowSelect = (id: number, checked: boolean) => {
        const newSelectedRows = new Set(selectedRows);
        if (checked) {
            newSelectedRows.add(id);
        } else {
            newSelectedRows.delete(id);
        }
        if (setSelectedRows) {
            setSelectedRows(newSelectedRows);
        }
        if (setAllSelected) {
            setAllSelected(newSelectedRows.size === dataSource.length);
        }
    };

    const onCloseModalConfirm = () => {
        setConfirmation(false);
        if (setSelectedRows) {
            setSelectedRows(new Set());
        }
    };

    const onOkModalConfirm = () => {
        if (handlerDeleteCopyAll) {
            handlerDeleteCopyAll(selectedRows);
        }
        setConfirmation(false);
    };

    const handleDeleteAll = (selectedRows: any) => {
        if (selectedRows.size > 0) {
            setConfirmation(true);
        } else {
            message.warning(t('Нет выделенных ячеек'), 3);
        }
    };

    const onCopyAll = (row?: any) => {
        if (!selectedRows.size && !row) {
            message.warning(t('Нет выделенных ячеек'), 3);
            return;
        }
        if (handlerCopyAll){
            handlerCopyAll(row ? row : selectedRows);
            message.success(t('Данные скопированы'), 3);
        }
    }

    return (
        <div style={style}>
            <div
                className={classnames(styleTable.container__over, {
                    [className]: className,
                })}
                style={{
                    height,
                }}
            >
                <Spin tip={`${t('Загрузка')}...`} spinning={loading}>
                    <TableWrapper>
                        <thead>
                        <TableHeader
                            loading={loading}
                            setColumnFilters={setColumnFilters}
                            columnFilters={columnFilters}
                            columns={columns}
                            hiddenColumn={hiddenColumns}
                            content={content}
                            filtersFetch={filtersFetch}
                            filtersColumn={filtersColumn}
                            order={handlerOrder}
                            onSelectAll={handleSelectAll}
                            allSelected={allSelected}
                            pagination={pagination}/>
                        </thead>
                        <tbody>
                        <TableRow
                            deleteRow={handlerDeleteRow}
                            editRow={editRow}
                            data={sortedData}
                            columns={columns}
                            hiddenColumn={hiddenColumns}
                            onRow={onRow}
                            onClickLink={onClickLink}
                            selectedRows={selectedRows}
                            handleCopyAll={onCopyAll}
                            handleDeleteCopyAll={() => handleDeleteAll(selectedRows)}
                            handleRowSelect={handleRowSelect}
                            t={t}
                            subMenu={subMenu}
                        />
                        </tbody>
                    </TableWrapper>
                </Spin>
            </div>
            <Footer>
                <Flex
                    justify={'space-between'}
                    align={'flex-start'}
                    style={{width: '100%'}}
                >
                    <ColPage>
                        {currentPage} {t('страница из')}{' '}
                        {Math.ceil(Number(pagination.total || 0) / pageSize)}
                    </ColPage>
                    <div>
                        <Pagination
                            current={pagination.current}
                            pageSize={pagination.pageSize}
                            total={pagination.total}
                            onChange={handlePageChange}
                            showSizeChanger={pagination.showSizeChanger}
                            onShowSizeChange={handlePageChange}
                            defaultPageSize={pagination.pageSize}
                        />
                    </div>
                </Flex>
            </Footer>
            <ModalConfirm
                isOpen={confirmation}
                closeModal={onCloseModalConfirm}
                actionAfterConsent={onOkModalConfirm}
            />
        </div>
    );
}
