import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CompanyRequest } from '../services/rest_companies';
import {
  ITypeCompany,
  ITypeContact,
  ITypeContainers,
  ITypeEmail,
  ITypeMessenger,
  ITypePhone,
  ITypeQuality,
  ITypeSite,
  ITypePayment,
  ITypeDeal,
  ITypeDealStatus,
  ITypeDealContainerStatus,
  ICurrency,
  ITerminalStorage,
  IPercent,
  ITypeSelectList,
} from '../types';

interface ITypeState {
  dataTypePhone: Array<ITypePhone>;
  dataTypeMessenger: Array<ITypeMessenger>;
  dataTypeEmail: Array<ITypeEmail>;
  dataTypeContact: Array<ITypeContact>;
  dataTypeCompany: Array<ITypeCompany>;
  dataTypeContainers: Array<ITypeContainers>;
  dataTypeQuality: Array<ITypeQuality>;
  dataTypeSite: Array<ITypeSite>;
  dataTypePayment: Array<ITypePayment>;
  dataTypeDepartment: [];
  dataTypeDeal: ITypeDeal[];
  dataTypeDealStatus: ITypeDealStatus[];
  dataTypeDealContainerStatus: ITypeDealContainerStatus[];
  dataTypeTerminalStorage: ITerminalStorage[];
  dataTypeCurrency: ICurrency[];
  dataTypePercent: IPercent[];
  currencyCurrent?: ICurrency | null;
  dataTypeExpense: ITypeSelectList[];
  dataTypePriority: ITypeSelectList[];
}

const initialState: ITypeState = {
  dataTypePhone: [],
  dataTypeMessenger: [],
  dataTypeEmail: [],
  dataTypeContact: [],
  dataTypeCompany: [],
  dataTypeContainers: [],
  dataTypeQuality: [],
  dataTypePayment: [],
  dataTypeSite: [],

  dataTypeDepartment: [],
  dataTypeDeal: [], // тип сделок
  dataTypeDealStatus: [], // статус сделок в продажах
  dataTypeDealContainerStatus: [], // статус ктк в сделках
  dataTypeTerminalStorage: [], // терминалы
  dataTypeCurrency: [], // валюты
  currencyCurrent: null, // объект курса валют
  dataTypePercent: [],
  dataTypeExpense: [], // вид расхода
  dataTypePriority: [], // приоритет
};

export const typeSite = createAsyncThunk('typeSite', async () => {
  return await CompanyRequest.getTypeSite();
});


const typeSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {
    setTypeContainers(state, action) {
      state.dataTypeContainers = action.payload;
    },
    setTypeQuality(state, action) {
      state.dataTypeQuality = action.payload;
    },
    setTypePhone(state, action) {
      state.dataTypePhone = action.payload;
    },
    setTypeMessenger(state, action) {
      state.dataTypeMessenger = action.payload;
    },
    setTypeEmail(state, action) {
      state.dataTypeEmail = action.payload;
    },
    setTypeCompany(state, action) {
      state.dataTypeCompany = action.payload;
    },
    setTypePayment(state, action) {
      state.dataTypePayment = action.payload;
    },
    setTypeContact(state, action) {
      state.dataTypeContact = action.payload;
    },
    setTypeDeal(state, action) {
      state.dataTypeDeal = action.payload;
    },
    setTypeDealStatus(state, action) {
      state.dataTypeDealStatus = action.payload;
    },
    setTypeDealContainerStatus(state, action) {
      state.dataTypeDealContainerStatus = action.payload;
    },
    setTypeTerminalStorage(state, action) {
      state.dataTypeTerminalStorage = action.payload;
    },
    setTypeCurrency(state, action) {
      state.dataTypeCurrency = action.payload;
    },
    setTypeUpdateCurrency(state, action) {
      state.dataTypeCurrency = action.payload?.data;
      state.currencyCurrent = action.payload?.current;
    },
    setTypePercent(state, action) {
      state.dataTypePercent = action.payload;
    },
    setTypeExpense(state, action) {
      state.dataTypeExpense = action.payload?.map((opt: ITypeSelectList) => ({...opt, value: opt?.id}));
    },
    setTypePriority(state, action) {
      state.dataTypePriority = action.payload?.map((opt: ITypeSelectList) => ({...opt, value: opt?.id}));;
    },
  },
  extraReducers: (builder) => {},
});
export default typeSlice.reducer;
export const {
  setTypeContainers,
  setTypeQuality,
  setTypePhone,
  setTypeMessenger,
  setTypeEmail,
  setTypeCompany,
  setTypePayment,
  setTypeContact,
  setTypeDeal,
  setTypeDealStatus,
  setTypeDealContainerStatus,
  setTypeTerminalStorage,
  setTypeCurrency,
  setTypePercent,
  setTypeUpdateCurrency,
  setTypeExpense,
  setTypePriority
} = typeSlice.actions;
