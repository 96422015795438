import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { IParamRestDelete, StorageData } from '../types';
import { setBreadcrumb } from '../store/store';
import useModalMessage from './useModalMessage';
import { getSearchLink } from '../helpers/link-helper';
import { Rest_filters } from '../services/rest_filters';
import { setActiveKeys, setOpenKeys } from '../store/menu';
import { IColumnType, IPagination } from '../components/ui/Table/Table';
import { SessionStorageManager } from '../components/ui/Table/sessionStorageManager';

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

export function useSearchParams() {
  const { search } = useLocation();

  return useMemo(
    () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
    [search],
  );
}

export const useLink = ({ key, id }: { key: string; id: number | string }) =>
  useMemo(() => getSearchLink({ key, id }), [key, id]);

interface RowData {
  [key: string]: any;
}

interface UseCsvExportProps {
  fields: string[];
}

export const useCsvExport = ({ fields }: UseCsvExportProps) => {
  const extractValue = (obj: any, key: string) => {
    const keys = key.split('.'); // Разделяем ключи для доступа к вложенным объектам
    let value = obj;
    for (const k of keys) {
      value = value ? value[k] : '';
    }
    return value;
  };
  const generateCSV = useCallback(
    (selectedRows: any[], data: RowData[]) => {
      return selectedRows
        .map((id) => {
          const row = data.find((item) => item.id === id);
          if (row && !row.delete) {
            return fields
              .map((field) => extractValue(row, field) || '')
              .map((value) => `"${String(value).replace(/"/g, '""')}"`)
              .join(',');
          }
          return '';
        })
        .filter((row) => row.trim() !== '')
        .join('\n');
    },
    [fields],
  );

  // Пока ничего не
  const copyToClipboard = useCallback((rowsData: string) => {
    navigator.clipboard
      .writeText(rowsData.trim())
      .then(() => {})
      .catch((err) => {
        console.error('Не удалось скопировать данные: ', err);
      });
  }, []);

  return {
    generateCSV,
    copyToClipboard,
  };
};

export const useParamsHistory = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation() as {
    pathname: string;
    state: any;
    search: any;
    key: any;
  };

  return { history, id, location };
};

export const useBreadcrumb = (str: string[]) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translation = str.map((title) => t(title ?? ''));

  useEffect(() => {
    dispatch(setBreadcrumb(translation));
  }, [dispatch, JSON.stringify(translation)]);
};

export const useMenu = ({
  openKey,
  activeKeys,
}: {
  openKey?: string;
  activeKeys: string[];
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (openKey) {
      dispatch(setOpenKeys(openKey));
    }
    dispatch(setActiveKeys(activeKeys));
  }, [JSON.stringify(activeKeys), dispatch, openKey]);
};

export const useLocalizeDocumentAttributes = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.resolvedLanguage) {
      document.documentElement.lang = i18n.resolvedLanguage;
    }
  }, [i18n, i18n.resolvedLanguage]);
};

export const useDefaultTranslation = () => {
  const { t, ...rest } = useTranslation();
  const tDefault = (key: string) => t(key, { default: key });

  return { tDefault, ...rest };
};

interface IGetTable {
  table: string;
  columns: IColumnType<any>[];
  fetchTables: (params: any) => Promise<any>;
  fetchDelete: (params: IParamRestDelete) => Promise<any>;
  checkDeletionCancellation?: (row: any) => {
    isDelete: boolean;
    message: string;
  }; // проверка отмены удаления
  mapperData?: (arr: any[]) => any[];
  location: {
    pathname: string;
    state: any;
    search: any;
    key: any;
  };
}
// общий хук таблиц
export const useGettingDataForTables = ({
  table,
  location,
  columns,
  mapperData,
  fetchTables,
  fetchDelete,
  checkDeletionCancellation,
}: IGetTable) => {
  const { error, info } = useModalMessage();
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [current, setCurrent] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [currentOrder, setCurrentOrder] = useState<string>('DESC');
  const [columnFilters, setColumnFilters] = useState({});
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const storageManager = new SessionStorageManager(location.pathname);
  const storageLocal: any = storageManager.getItem();

  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  const filtersFetch = (text: string, column: string) =>
    Rest_filters.filtersSearch({ text, column: column, table });

  const clearFilters = () => {
    const storageData: StorageData = {
      bull: [],
      fiters: [{}],
      arrFilters: [],
      pagination: {current: 1, page: 10, total: 0}
    };
    storageManager.updateItem(storageData);
    setColumnFilters({});
  };

  const fetchData = ({
    current,
    pageSize,
    order = 'DESC',
    options = {},
  }: {
    current: number;
    pageSize: number;
    order?: string;
    options?: { [key: string]: string };
  }) => {
    setLoading(true);
    fetchTables({
      order,
      ...options,
      current,
      pageSize,
    })
      .then((res) => {
        const response = res.data;
        const rows = mapperData ? mapperData(response?.rows) : response?.rows;
        setDataTable(rows || []);
        setTotal(response?.count || 0);
      })
      .catch((err) => {
        if (err?.message) {
          error(err.message);
          return;
        }
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateDate = (param?: { [key: string]: any }) => {
    clearFilters();
    const options = param || {};
    fetchData({ current, pageSize, options });
  };

  const handlerOrder = (order: string) => {
    setCurrentOrder(order);
    updateEffectDate({ current, pageSize, order });
  };

  const handlerDeleteRow = (row: any) => {
    const { id, isDelete } = { id: row?.id, isDelete: !row?.delete };
    if (checkDeletionCancellation) {
      const { isDelete, message } = checkDeletionCancellation(row);
      if (!isDelete) {
        info(message);
        return;
      }
    }
    fetchDelete({ ids: [id], isDelete })
      .then(() => {
        setDataTable((prev) =>
          prev.map((el) => {
            if (el?.id === id) {
              return { ...el, delete: isDelete };
            }
            return el;
          }),
        );
      })
      .catch((err) => {
        if (err?.message) {
          error(err.message);
          return;
        }
        console.error(err);
      });
  };

  const handlerDeleteCopyAll = (selectedRows: Set<number | undefined>) => {
    const ids = Array.from(selectedRows || []);
    fetchDelete({ ids, isDelete: true })
      .then(() => {
        setDataTable((prev) =>
          prev.map((el) => {
            if (ids.includes(el?.id)) {
              return { ...el, delete: true };
            }
            return el;
          }),
        );
      })
      .catch((err) => {
        if (err?.message) {
          error(err.message);
          return;
        }
        console.error(err);
      });
  };

  const handlerCopyAll = (selectedRows: Set<number | undefined>) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, dataTable);
    copyToClipboard(rowsData);
  };

  const filtersColumn = ({
    data,
    current,
    pageSize,
    order = 'DESC',
    options = {},
  }: {
    data: any;
    current: number;
    pageSize: number;
    order?: string;
    options?: { [key: string]: any };
  }) => {
    if (storageLocal && storageLocal.pagination !== undefined) {
      storageLocal.pagination.current = current;
      storageLocal.pagination.page = pageSize;
      storageManager.setItem(storageLocal);
    }

    if (data && Object.keys(data).length > 0) {
      setLoading(true);
      Rest_filters.fetchFilters({
        order,
        ...options,
        data,
        current,
        pageSize,
        table,
      })
        .then((res) => {
          const response = res.data;
          const rows = mapperData ? mapperData(response?.rows) : response?.rows;
          setDataTable(rows ?? []);
          setTotal(response?.count ?? 0);
        })
        .catch((err) => {
          if (err?.message) {
            error(err.message);
            return;
          }
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      fetchData({ current, pageSize, order, ...options });
    }
    setCurrent(current);
  };

  const updateEffectDate = (param?: { [key: string]: any }) => {
    const options = param || {};
    if (storageLocal) {
      setCurrent(storageLocal.pagination.current || 1);
      setColumnFilters(storageLocal.arrFilters);
      setPageSize(storageLocal.pagination.page || 10);
      filtersColumn({
        data: storageLocal.fiters,
        current: storageLocal.pagination.current,
        pageSize: storageLocal.pagination.page,
        options,
      });
      return;
    }
    fetchData({ current, pageSize, options });
  };

  const onChangePagination = (page: IPagination<any>) => {
    setAllSelected(false);
    setSelectedRows(new Set());
    setCurrent(page.current);
    setPageSize(page.pageSize);

    try {
      const storageData: StorageData = {
        bull: storageLocal?.bull,
        pagination: {
          page: page.pageSize,
          current: page.current,
          total: page.total,
        },
      };
      storageManager.setItem(storageData);
      if (page.current != null && page.pageSize != null) {
        fetchData({ current: page.current, pageSize: page.pageSize });
      }
    } catch (err) {
      error('Ошибка пагинации');
    }
  };

  return {
    current,
    pageSize,
    dataTable,
    optionsTable: {
      loading,
      columnFilters,
      allSelected,
      selectedRows,
      pagination: {
        total: Number(total),
        current,
        pageSize,
        showSizeChanger: true,
      },
      clearFilters,
      handlerCopyAll,
      handlerOrder,
      filtersFetch,
      setDataTable,
      filtersColumn,
      setAllSelected,
      setSelectedRows,
      handlerDeleteRow,
      setColumnFilters,
      onChangePagination,
      handlerDeleteCopyAll,
    },
    updateDate,
    setDataTable,
    updateEffectDate,
  };
};
