import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Rest_releases } from '../services/rest_releases';
import { Rest_containers } from '../services/rest_containers';
import { IContainers } from '../types/containers';
import { Rest_terminal } from '../services/rest_terminal';
import { IHistoryValue } from '../components/CustomHistory/CustomHistory';

export interface ITransporter {
  readonly fio: string;
  readonly phone: string;
  readonly gos_number: string;
  readonly series_passport: string;
  readonly number_passport: string;
}

export interface IContainer {
  id?: number;
  size?: string; // размер
  release_id: string; // номер релиза
  type_container?: string; // тип
  type_container_id?: string;
  terminal_id?: number;
  child_terminal_id?: number;
  container_id?: number; // id если есть контейнер в базе
  num_container?: string; // номер если номерной
  comment: string; // коммент
  reason?: string; // коммент
  count: number; // количество
  delete: boolean; // метка
  release?: any;
  deal?: any;
  edit?: boolean;
  active?: boolean;
}

// export interface IHistoryRelease {
//   readonly id?: number;
//   readonly release_id: string;
//   readonly responsible_id: number;
//   readonly responsible: string;
//   readonly data: IRelease;
//   readonly createdAt: string;
// }

export type IStatus = 'completed' | 'partCompleted' | 'cancelled' | undefined;

export interface IRelease {
  readonly id?: number;
  readonly user_release_id?: string;
  readonly release_id: string;
  readonly release_generate_id?: string;
  readonly containers_count?: number;
  containers: IContainer[];
  currentContainers?: IContainer[];
  readonly date_action_start?: Date | null;
  readonly date_action_start_string?: string;
  readonly date_action_end?: Date | null;
  readonly date_action_end_string?: string;
  status: IStatus;
  inn: string;
  company: string;
  type?: string;
  readonly contractor: string;
  readonly responsible_id: number | null
  readonly responsible?: string
  terminal: string;
  terminal_id: number | null;
  readonly delete?: boolean
  readonly file_ids: number[]
  readonly history_ids?: number[]
  saveFiles?: any[]
  transporter: ITransporter[]
  is_transporter?: boolean
  no_number?: boolean;
  history: IHistoryValue[]
}


export interface IValues {
  [key: string]: any;
}


export interface IForm {
  title?: string;
  isOpen: boolean;
  isCopy?: boolean;
  typeForm?: string;
  initialValue: IRelease;
  onClose: (bol?: boolean) => void;
  onEdit: () => void;
  onPdf: (lang: 'ru' | 'en') => void;
  onHistory: () => void;
  onDeal: ({path, state}: {path: string, state: any}) => void;
}
export interface ITab {
  isDisabled?: boolean;
  initialValue: IRelease;
}

interface IReleasesState {
  generateId?: string,
  releases: IRelease[];
  release?: IRelease;
  newContainer?: IContainers | null;
  loading: boolean;
  isContainerDrawer: boolean;
  count: number;
  page: number;
  currentPage: number;
  terminals: any[];
}

export const defaultValues: IRelease = {
  release_id: '',
  release_generate_id: '',
  user_release_id: '',
  inn: '',
  company: '',
  containers_count: 0,
  containers: [],
  date_action_start: null,
  date_action_start_string: '',
  date_action_end: null,
  date_action_end_string: '',
  type: '20DC',
  terminal: '',
  terminal_id: null,
  contractor: '',
  responsible_id: null,
  status: undefined,
  responsible: '',
  history_ids: [],
  history: [],
  transporter: [],
  no_number: undefined,
  is_transporter: undefined,
  file_ids: [],
  saveFiles: []
};

const initialState: IReleasesState = {
  generateId: '',
  releases: [],
  release: defaultValues,
  newContainer: undefined,
  loading: false,
  isContainerDrawer: false,
  count: 0,
  page: 20,
  currentPage: 1,
  terminals: [],
};

export const generateIdReleaseId = createAsyncThunk(
  'generateIdReleaseId',
  async (param: {char?: string, check?: string,  isCustom?: boolean;}) => {
    return await Rest_releases.generateIdReleaseId(param);
  },
);
export const setRelease = createAsyncThunk(
  'setRelease',
  async (payload: any) => {
    return await Rest_releases.setRelease(payload);
  },
);

export const searchTerminalCityChild = createAsyncThunk(
  'searchTerminalCityChild',
  async (text: string) => {
    return await Rest_terminal.searchTerminalCityChild(text);
  },
);

export const setContainer = createAsyncThunk(
  'setContainer',
  async (payload: {}) => {
      return await Rest_containers.setContainer(payload)
  }
)

const releasesSlice = createSlice({
  name: 'releases',
  initialState,
  reducers: {
    resetState(state) {
     const {releases, ...rest} = initialState;
     state = {...state, ...rest};
    },
    resetContainer(state, action) {
      state.newContainer = action.payload;
    },
    setIsContainerDrawer(state, action) {
      state.isContainerDrawer = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setTables(state, action) {
      state.releases = action.payload;
    },
    setOne(state, action) {
      const data = action.payload?.release ? {
        ...action.payload?.release,
        containers: action.payload.containers,
        history: action.payload.history,
      } : defaultValues;
      state.release = data;
    },
    setOneRelease(state, action) {
      state.release = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateIdReleaseId.fulfilled, (state, action) => {
      state.generateId = action.payload;
    });
    builder.addCase(searchTerminalCityChild.fulfilled, (state, action) => {
      state.terminals = action.payload;
    });
  },
});
export default releasesSlice.reducer;
export const {
  setLoading,
  setCount,
  setCurrentPage,
  setPage,
  setTables,
  setOne,
  setIsContainerDrawer,
  resetContainer,
  resetState,
  setOneRelease
} = releasesSlice.actions;
