import { apiAxios } from '../api/config';
import socket from '../socket';
import { IParamRestDelete } from '../types';
import { IAddComment, IContainersStatus } from '../types/containers';

export class Rest_containers {
  static getStock = (payload: string) => {
    return new Promise((resolve, reject) => {
      socket.emit('get-stock', payload);
    });
  };

  static getResources = () => {
    return new Promise((resolve, reject) => {
      socket.emit('get-resources', (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  };

  static getFiltersContainers(payload: {}): Promise<any> {
    return new Promise((resolve, reject) => {
      socket.emit('get-filters-containers', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static validCompanyContainer(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('valid-company-container', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static setContainer(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('set-container', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static setContainerImport(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('set-container-import', payload);
    });
  }

  static findContainers(payload: string | any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('find-containers', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static findContainersPhoto(payload: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('find-containers-photo', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static loadPhotoContainers(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('load-photo-containers', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getStatusContainer(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('get-status-container', (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static updateNewBooking(payload: IContainersStatus): Promise<any> {
    return new Promise(() => {
      socket.emit('update-new-booking', payload);
    });
  }

  static updateDateBooking(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('update-date-booking', payload);
    });
  }

  static addCommentContainer(payload: IAddComment): Promise<any> {
    return new Promise(() => {
      socket.emit('add-comment-container', payload);
    });
  }

  static deleteCommentContainer(payload: IAddComment): Promise<any> {
    return new Promise(() => {
      socket.emit('delete-comment-container', payload);
    });
  }

  static getCommentContainer(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      socket.emit('get-comment-container', id, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getActivityContainer(payload: {}): Promise<any> {
    return new Promise((resolve, reject) => {
      socket.emit('get-activity-container', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static updateRepair(payload: {}): Promise<any> {
    return new Promise((resolve, reject) => {
      socket.emit('update-repair', payload);
    });
  }

  static searchContainer(payload: {}): Promise<any> {
    return new Promise((resolve, reject) => {
      socket.emit('search-container', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getHistoryContainer(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      socket.emit('get-history', id, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getEdi(): Promise<any> {
    return new Promise((resolve, reject) => {
      socket.emit('get-edi', (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getContainers(param?: any): Promise<any> {
    return apiAxios.post('containers/get-containers', param);
  }

  static findContainerOne(id: string | number): Promise<any> {
    return apiAxios.get(`containers/${id}`);
  }

  static deleteContainer(payload: IParamRestDelete): Promise<any> {
    return apiAxios.delete('containers/delete-container', { data: payload });
  }
}
