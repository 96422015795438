import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
} from 'antd';
import ru from 'react-phone-input-2/lang/ru.json';
import Icon, { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Utils } from '../../utils';
import InfoModal from './infoModal';
import { useStoreDispatch } from '../../store';
import { messagesModal, TERMINAL } from '../../constants';
import { CREATE } from '../../helpers/string-helpers';
import ModalConfirm from '../../components/ui/ModalConfirm/ModalConfirm';
import { SelectSearch } from '../../components/ui/SelectSearch/SelectSearch';
import {
  findTerminalCity,
  searchTerminalCity,
  setTerminalCity,
} from '../../store/terminals';
import 'react-phone-input-2/lib/style.css';
import './style.scss';
import { useLink } from '../../hooks';
import IconButton from '../../components/ui/IconButton';
import { CopyBufferIconDefault } from '../../assets/image/svg';
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import { useTranslation } from 'react-i18next';

const { title: warningTitle, subtitle } = messagesModal.warning;

export const titleErrorParent =
  'Ошибка: "Родитель" не соответствует адресу терминала!';

interface Props {
  title?: string;
  dataCityRedux: any;
  defaultCity?: string;
  onFinishAddTerminal?: (data: any, open: boolean) => void | undefined;
  setIsModalOpenForm?: (
    value?: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  dataRow?: any;
  showMapEdit?: boolean;
  isCopy?: boolean;
  dataCurrency?: any;
  form: FormInstance<any>;
  typeForm?: string | null;
  nameTerminal?: string | undefined | number;
  onClose: () => void;
  onView?: () => void;
}

const FormModalTerminal: React.FC<Props> = ({
  title: titleForm,
  isCopy,
  dataCityRedux,
  onFinishAddTerminal,
  setIsModalOpenForm,
  dataRow,
  dataCurrency,
  form,
  typeForm,
  nameTerminal,
  onClose,
  onView,
}) => {
  const link = useLink({ key: TERMINAL, id: dataRow?.id as number });
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const [dataCity, setDataCity] = useState<any>([]);
  const [pointLat, setPointLat] = useState<number>(0);
  const [pointLon, setPointLon] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [parent, setParent] = useState<any>();
  const [nameCity, setNameCity] = useState<any>([]);
  const [city, setCity] = useState<any[]>([]);
  const [searchCity, setSearchCity] = useState<string | null>(null);
  const [defaultCity, setDefaultCity] = useState<string | null>(null);

  useEffect(() => {
    setNameCity(dataRow?.short_address);
    setDefaultCity(dataRow?.city_child?.city_terminals?.name);
    setPointLon(dataRow?.city_child?.city_terminals?.lon);
    setPointLat(dataRow?.city_child?.city_terminals?.lat);
    form.setFieldsValue({
      name_terminal:
        nameTerminal !== undefined ? nameTerminal : dataRow?.city_child?.name,
      city_terminal_id: dataRow?.city_child?.city_terminal_id,
      address_terminal: dataRow?.address_terminal,
      director: dataRow?.director,
      dispatcher: dataRow?.dispatcher,
      phone_director:
        !Array.isArray(dataRow) && dataRow?.phone_director !== null
          ? dataRow?.phone_director
          : '+7',
      last_phone_director: dataRow?.last_phone_director,
      last_phone_dispatcher: dataRow?.last_phone_dispatcher,
      phone_dispatcher:
        !Array.isArray(dataRow) && dataRow?.phone_dispatcher !== null
          ? dataRow?.phone_dispatcher
          : '+7',
      capacity: dataRow?.capacity,
      first_stock: dataRow?.stock ? dataRow?.stock : 'Без стока',
      text: dataRow?.stock_model?.text,
    });
  }, [dataRow, form, dataCurrency, dataRow?.city_child?.city_terminals?.name]);

  const handleSearchCity = async (payload: any) => {
    if (payload !== '') {
      let arResult: any = [];
      const arResultYandex = await Utils.yandexCity(payload);
      arResultYandex.response?.GeoObjectCollection?.featureMember?.map(
        (item: any, i: number) => {
          const text =
            item?.GeoObject?.metaDataProperty?.GeocoderMetaData?.AddressDetails
              ?.Country?.AddressLine;
          let shortCity: string = '';
          item?.GeoObject?.metaDataProperty?.GeocoderMetaData?.Address?.Components?.map(
            (item: any) => {
              if (item.kind === 'locality' || item.kind === 'province') {
                shortCity = item.name;
              }
            },
          );
          arResult = [
            ...arResult,
            {
              value: text,
              label: text,
              point: item?.GeoObject?.Point?.pos,
              shortCity: shortCity,
            },
          ];
        },
      );
      setDataCity(arResult);
    }
  };

  const handleSelectCity = (payload: string, record: any) => {
    setNameCity(record.shortCity);
    dispatch(findTerminalCity(record.shortCity)).then((response) => {
      if (response.payload.length === 0) {
        setIsModalOpen(true);
        setTitle('Мы не нашли ' + `${record.shortCity}` + ' в нашей базе!');
        const latLon = record.point.split(' ');
        setPointLat(Number(latLon[0]));
        setPointLon(Number(latLon[1]));
      }
    });
  };

  const handleChangeCity = async (payload: any, record: any) => {
    if (record) {
      setNameCity(record.shortCity);
    }
  };

  const findIdByNameCity = async (parent: any, name: string) => {
    for (const key in parent) {
      if (parent[key].child === name) {
        return parent[key].id;
      }
    }
    return null;
  };

  const onFinishForm = async (data: any) => {
    const cityChildId = await findIdByNameCity(parent, data.name_terminal);
    const parentValue =
      parent !== undefined
        ? parent.label
        : dataRow?.city_child?.city_terminals?.name;
    data.id = dataRow?.id;
    data.city_child_terminals_id =
      parent !== undefined ? cityChildId : dataRow?.city_child_terminals_id;
    data.city_terminal_id =
      parent !== undefined
        ? parent.value
        : dataRow?.city_child?.city_terminals?.id;
    data.name = data?.name_terminal;
    data.last_phone_director =
      data?.last_phone_director?.length > 0 ? data?.last_phone_director : null;
    data.last_phone_dispatcher =
      data?.last_phone_dispatcher?.length > 0
        ? data?.last_phone_dispatcher
        : null;
    data.short_address = nameCity;
    data.phone_director =
      data?.phone_director === '+7' ? null : data?.phone_director;
    data.phone_dispatcher =
      data?.phone_dispatcher === '+7' ? null : data?.phone_dispatcher;
    data.stock_id_instruction = dataRow?.stock_model?.id || null;
    if (data.last_stock) {
      data.last_stock.push({ add_stock: data?.stock });
    } else {
      data.last_stock = [{ add_stock: data?.stock }];
    }

    if (nameCity === parentValue) {
      if (onFinishAddTerminal) {
        onFinishAddTerminal(data, false);
      }
    } else {
      if (parent === null) {
        if (onFinishAddTerminal) {
          onFinishAddTerminal(data, false);
        }
      } else {
        setTitle(titleErrorParent);
        setIsModalOpen(true);
      }
    }
  };

  const handleOk = () => {
    if (nameCity != null) {
      dispatch(
        setTerminalCity({
          name: nameCity,
          child_city_id: 0,
          lat: pointLat,
          lon: pointLon,
        }),
      );
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleParent = (id: number, record: any) => {
    const dataSource = record?.children?._owner?.pendingProps?.dataSource;
    if (dataSource) {
      dataSource.map((item: any) => {
        if (item.value === record.value) {
          setDefaultCity(item.label);
          setParent(item);
          setPointLat(item.lat);
          setPointLon(item.lon);
        }
      });
    } else {
      setParent(null);
    }
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCity(event.target.value.toLocaleLowerCase());
    if (event.target.value === '') {
      setSearchCity(null);
    } else {
      dispatch(searchTerminalCity(event.target.value.toLocaleLowerCase())).then(
        (response) => {
          setCity(response.payload);
        },
      );
    }
  };

  const onClickAddTerminal = () => {
    setIsModalOpen(true);
  };

  const onCloseForm = () => {
    form.resetFields();
    if (setIsModalOpenForm) {
      setIsModalOpenForm();
    }
    setCity([]);
    onClose();
  };

  return (
    <Space direction={'vertical'}>
      {!!titleForm && <Flex justify={'space-between'} align={'center'}>
        <h2>{titleForm}</h2>
        <Flex gap={20} align={'center'}>
          <Flex gap={5} align={'center'}>
            <IconButton iType="view-form" onClick={onView} />
            {isCopy && (
              <CopyButton
                text={link}
                textSuccess={t('Ссылка скопирована')}
                icon={<Icon component={CopyBufferIconDefault} />}
              />
            )}
          </Flex>
          <IconButton
            iType="close"
            onClick={onClose}
            style={{ color: 'currentcolor', outline: 'node', border: 'none' }}
          />
        </Flex>
      </Flex>}
      {!!titleForm && <Divider style={{ marginTop: 0, marginBottom: 16 }} />}
      <Form
        autoComplete="off"
        onFinish={onFinishForm}
        form={form}
        initialValues={{
          stock: 'Без стока',
          city_terminal_id: null,
        }}
      >
        <Flex gap={12} vertical>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="name_terminal"
                rules={[
                  {
                    required: true,
                    message: 'Обязательное поле!',
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder={'Название терминала'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="city_terminal_id">
                <SelectSearch
                  dataSource={searchCity !== null ? city : dataCityRedux}
                  onChangeSelect={handleParent}
                  dropdownRender={onNameChange}
                  placeholder={'Родитель'}
                  type={'form'}
                  defaults={defaultCity}
                  style={{ width: '100%' }}
                  hiddenBtn={false}
                  onClick={onClickAddTerminal}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="address_terminal"
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <AutoComplete
                  allowClear
                  value={nameCity}
                  options={dataCity}
                  style={{ width: '100%' }}
                  onSelect={handleSelectCity}
                  onSearch={(text) => handleSearchCity(text)}
                  onChange={handleChangeCity}
                  placeholder="Адрес"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item name="director">
                <Input placeholder={'Руководитель'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Flex gap={12}>
                <Form.Item name="phone_director">
                  <PhoneInput
                    defaultMask={''}
                    enableSearch
                    searchPlaceholder={'Поиск'}
                    searchNotFound={'ничего не нашлось'}
                    country={'ru'}
                    localization={ru}
                  />
                </Form.Item>
                <Flex gap={5}>
                  <Form.List name="last_phone_director">
                    {(_fields, { add }) => (
                      <>
                        <DeleteOutlined
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </>
                    )}
                  </Form.List>
                </Flex>
              </Flex>
              <Form.List name="last_phone_director">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Flex gap={12} key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_phone_director']}
                          rules={[
                            {
                              required: true,
                              message: 'Заполните или удалите!',
                            },
                          ]}
                        >
                          <PhoneInput
                            enableSearch
                            searchPlaceholder={'Поиск'}
                            searchNotFound={'ничего не нашлось'}
                            country={'ru'}
                            localization={ru}
                          />
                        </Form.Item>

                        <Flex gap={5}>
                          <DeleteOutlined
                            onClick={() => remove(name)}
                            style={{
                              fontSize: '16px',
                              color: '#D91528',
                              cursor: 'pointer',
                            }}
                          />
                          <PlusOutlined
                            style={{
                              fontSize: '16px',
                              color: '#00B288',
                              cursor: 'pointer',
                            }}
                            onClick={() => add()}
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>

            <Col span={12}>
              <Form.Item name="dispatcher">
                <Input placeholder={'Диспетчер'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Flex gap={12}>
                <Form.Item name="phone_dispatcher">
                  <PhoneInput
                    defaultMask={''}
                    enableSearch
                    searchPlaceholder={'Поиск'}
                    searchNotFound={'ничего не нашлось'}
                    country={'ru'}
                    localization={ru}
                  />
                </Form.Item>
                <Flex gap={5}>
                  <Form.List name="last_phone_dispatcher">
                    {(_fields, { add }) => (
                      <>
                        <DeleteOutlined
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </>
                    )}
                  </Form.List>
                </Flex>
              </Flex>
              <Form.List name="last_phone_dispatcher">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Flex gap={12} key={key}>
                        <>
                          <Form.Item
                            {...restField}
                            name={[name, 'add_phone_dispatcher']}
                            rules={[
                              {
                                required: true,
                                message: 'Заполните или удалите!',
                              },
                            ]}
                          >
                            <PhoneInput
                              enableSearch
                              searchPlaceholder={'Поиск'}
                              searchNotFound={'ничего не нашлось'}
                              country={'ru'}
                              localization={ru}
                            />
                          </Form.Item>
                        </>
                        <Flex gap={5}>
                          <DeleteOutlined
                            onClick={() => remove(name)}
                            style={{
                              fontSize: '16px',
                              color: '#D91528',
                              cursor: 'pointer',
                            }}
                          />
                          <PlusOutlined
                            style={{
                              fontSize: '16px',
                              color: '#00B288',
                              cursor: 'pointer',
                            }}
                            onClick={() => add()}
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="capacity">
                <Input placeholder={'Вместимость'} />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <Flex vertical gap={12}>
            <Row gutter={[12, 12]} align={'middle'}>
              <Col span={22}>
                <Form.Item name="stock">
                  <Input placeholder={'Сток'} allowClear />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Flex gap={5}>
                  <Form.List name="last_stock">
                    {(_fields, { add }) => (
                      <>
                        <DeleteOutlined
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </>
                    )}
                  </Form.List>
                </Flex>
              </Col>
              <Col span={24} style={{ paddingRight: 0 }}>
                <Form.List name="last_stock">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Flex gap={13} key={key}>
                          <Form.Item
                            {...restField}
                            style={{ width: '100%' }}
                            name={[name, 'add_stock']}
                            rules={[
                              {
                                required: true,
                                message: 'Заполните или удалите!',
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input placeholder={'Сток'} />
                          </Form.Item>

                          <Flex gap={5}>
                            <DeleteOutlined
                              onClick={() => remove(name)}
                              style={{
                                fontSize: '16px',
                                color: '#D91528',
                                cursor: 'pointer',
                              }}
                            />
                            <PlusOutlined
                              style={{
                                fontSize: '16px',
                                color: '#00B288',
                                cursor: 'pointer',
                              }}
                              onClick={() => add()}
                            />
                          </Flex>
                        </Flex>
                      ))}
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
            <Form.Item name={'text'}>
              <Input.TextArea
                autoSize
                placeholder="Краткая инструкция"
                style={{ minHeight: 100 }}
              />
            </Form.Item>
          </Flex>
          <Row gutter={12} style={{ marginTop: 151 }}>
            <Col span={16}>
              <Button
                type={'primary'}
                style={{ width: '100%', background: '#0055FF' }}
                htmlType={'submit'}
              >
                {typeForm === CREATE ? 'Добавить' : 'Редактировать'}
              </Button>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  type={'text'}
                  style={{ width: '100%', color: '#E14453' }}
                  onClick={() => setConfirmation(true)}
                >
                  Отменить
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Flex>
      </Form>
      <InfoModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={title}
      />
      <ModalConfirm
        title={warningTitle}
        subtitle={subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={onCloseForm}
      />
    </Space>
  );
};

export default FormModalTerminal;
