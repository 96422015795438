import { delay } from 'lodash';
import { Select, SelectProps } from 'antd';
import React, { useEffect, useState } from 'react';

interface ISelect extends SelectProps<any> {
  defaultValue?: any;
  api?: (val: string) => Promise<any>;
  mapResponse?: (res: any) => { value: any; label: any }[];
  options?: { value: any; label: any }[];
  style?: React.CSSProperties;
  className?: string;
}

export const FormSelectSearch: React.FC<ISelect> = ({
  api,
  options,
  defaultValue,
  mapResponse = (res) => res,
  style = {},
  className = '',
  ...props
}) => {
  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = (text: string) => {
    if (text?.length >= 2 && api && mapResponse) {
      setLoading(true);
      delay(
        () =>
          api(text)
            .then(mapResponse)
            .then((arr) => {
              if (arr?.length) {
                setSelectOptions(
                  arr.filter((item: any) =>
                    item?.label && text
                      ? item.label
                          .toLowerCase()
                          .includes(text.toLocaleLowerCase())
                      : false,
                  ),
                );
              } else {
                setSelectOptions([]);
              }
            })
            .finally(() => {
              setLoading(false);
            }),
        1000,
      );
    }
  };

  useEffect(() => {
    if (options && Array.isArray(options)) {
      setSelectOptions(options);
    }
  }, [JSON.stringify(options)]);

  return (
    <Select
      allowClear
      showSearch
      suffixIcon={null}
      filterOption={false}
      style={style}
      {...props}
      loading={loading}
      defaultValue={defaultValue || null}
      onSearch={handleSearch}
      notFoundContent={null}
      options={api ? selectOptions : options}
      optionLabelProp="label"
      className={className}
    />
  );
};

export default FormSelectSearch;
