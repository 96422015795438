import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  Button,
  Flex,
  Form,
  Input,
  MenuProps,
  App,
  Modal,
  notification,
  Space,
  Typography,
} from 'antd';
import Icon, { PlusOutlined } from '@ant-design/icons';
import socket from '../../socket';
import { columns } from './columns';
import { Utils } from '../../utils';
import FormContainer from './formContainer';
import { TerminalService } from './service';
import FormModalImport from './formModalImport';
import { RouteNames } from '../../router/names';
import Layout from '../../components/Layouts';
import FormModalContainer from './formModalContainer';
import { blackCloseIcon } from '../../assets/image/svg';
import { useStoreDispatch } from '../../store';
import {
  useBreadcrumb,
  useGettingDataForTables,
  useMenu,
  useParamsHistory,
} from '../../hooks';
import { CREATE, EDIT } from '../../helpers/string-helpers';
import { Table } from '../../components/ui/Table/Table';
import {
  getActivityContainer,
  searchContainer,
  setContainer,
} from '../../store/containers';

import './style.scss';
import '../../assets/scss/terminal.scss';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import { CONTAINER, RENT_DEAL, SALES_DEAL } from '../../constants';
import { Rest_containers } from '../../services/rest_containers';
import { mapperContainers } from './utils';
import { IContainers } from '../../types/containers';

const Containers: React.FC = () => {
  const { message } = App.useApp();
  const { history, location } = useParamsHistory();
  const { pathname, search } = location;
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isModalOpenContainer, setIsModalOpenContainer] =
    useState<boolean>(false);
  const [isModalOpenImport, setIsModalOpenImport] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [openDrawerContainer, setOpenDrawerContainer] =
    useState<boolean>(false);
  const [currentActivity, setCurrentActivity] = useState<number>(1);
  const [pageActivity, setPageActivity] = useState<number>(10);
  const [totalTableActivity, setTotalTableActivity] = useState<number>(0);
  const [dataRowContainer, setDataRowContainer] = useState<any>();
  const [nameTerminal, setNameTerminal] = useState<string | null>(null);
  const searchParams = new URLSearchParams(location.search);
  const [typeForm, setTypeForm] = useState<string>(CREATE);
  const [loadings, setLoadings] = useState<boolean>(false);
  const [dataComment, setDataComment] = useState<any>([]);
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [activityContainer, setActivityContainer] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const {
    dataTable,
    current,
    pageSize,
    optionsTable,
    setDataTable,
    updateDate,
    updateEffectDate,
  } = useGettingDataForTables({
    table: CONTAINER,
    location,
    columns,
    fetchTables: Rest_containers.getContainers,
    fetchDelete: Rest_containers.deleteContainer,
    mapperData: (arr) => arr?.map(mapperContainers) || [],
  });

  useBreadcrumb(['Депо', 'Контейнеры']);

  useMenu({
    openKey: RouteNames.DEPOT,
    activeKeys: [RouteNames.DEPOT, RouteNames.CONTAINERS],
  });

  // проверка url
  useEffect(() => {
    if (search) {
      setNameTerminal(searchParams.get('name'));
      updateDate({
        child: Number(searchParams.get('child')),
        city: Number(searchParams.get('city')),
        stock: searchParams.get('stock'),
      });
      return;
    }
    if (pathname === RouteNames.CONTAINERS) {
      updateEffectDate({
        child: 0,
        city: 0,
        stock: null,
      });
      const row = location?.state?.[CONTAINER];
      if (row) {
        if (row?.edit) {
          setIsModalOpenContainer(false);
          setDataRowContainer(row?.edit?.container);
          setActivityContainer(row?.edit?.activity || []);
          setDataComment(row?.edit?.comments || []);
          if (row?.edit?.container?.delete) {
            setIsModalOpenContainer(true);
            return;
          }
          setOpenDrawerContainer(true);
          return;
        }
        form.resetFields();
        setIsModalOpenContainer(false);
        setTypeForm(CREATE);
        setDataRowContainer(row);
        setOpenDrawerContainer(true);
        return;
      }
    }
  }, [pathname]);

  useEffect(() => {
    socket.on('res-update-new-booking', handleNewBooking);
    socket.on('res-set-container', handleNewContainer);
    socket.on('res-set-container-import', resContainerImport);
    socket.on('res-delete-container', resHandleDeleteRow);
    socket.on('res-update-repair', resUpdateRepair);
    socket.on('res-update-date-booking', handleDateBooking);

    return () => {
      socket.off('res-update-new-booking', handleNewBooking);
      socket.off('res-set-container', handleNewContainer);
      socket.off('res-set-container-import', resContainerImport);
      socket.off('res-delete-container', resHandleDeleteRow);
      socket.off('res-update-repair', resUpdateRepair);
      socket.off('res-update-date-booking', handleDateBooking);
    };
  }, [current, pageSize]);

  const subMenu = {
    label: 'Создать сделку',
    getSubMenu: ({
      item,
      rows,
      data,
    }: {
      item: any;
      rows: Set<any>;
      data: any;
      onClose: () => void;
    }): MenuProps['items'] => {
      const containers: any[] =
        rows?.size && data?.length
          ? data.filter((item: any) => rows.has(item?.id))
          : [];
      if (item && rows && !rows.has(item?.id)) {
        containers.push(item);
      }
      return [
        {
          key: 'Продажа',
          label: (
            <Typography.Text
              data-id="link"
              onClick={() =>
                history.push(RouteNames.SALES_DEAL, {
                  [SALES_DEAL]: { containers },
                })
              }
            >
              {t('Продажа')}
            </Typography.Text>
          ),
        },
        {
          key: 'Аренда',
          label: (
            <Typography.Text
              data-id="link"
              onClick={() =>
                history.push(RouteNames.RENT_DEAL, {
                  [RENT_DEAL]: { containers },
                })
              }
            >
              {t('Аренда')}
            </Typography.Text>
          ),
        },
      ];
    },
  };

  const handleSearch = async (inputValue: string) => {
    setSelectedOption(inputValue);
    const commonParams = [{ page: pageSize }, { current }];

    const executeSearch = async (params: any) => {
      const response = await dispatch(searchContainer(params));
    };

    // Если inputValue пусто, выполняем запрос с пустым массивом
    if (inputValue === '') {
      setSelectedOption(null);
      setSearchColumn([]);
      await executeSearch([{ page: pageSize }, { current }]);
      return;
    }

    // Если searchColumn пуст, выполняем поиск по всем колонкам, кроме исключенных
    if (searchColumn.length === 0) {
      const searchParams = columns
        .filter(
          (item: any) =>
            item.dataIndex !== 'date_entry' &&
            item.dataIndex !== 'arrival_date' &&
            item.dataIndex !== 'departure_date' &&
            item.dataIndex !== 'booking_before' &&
            item.dataIndex !== 'hidden',
        )
        .map((item: any) => ({ [item.dataIndex]: inputValue }));

      const finalSearchParams = [...searchParams, ...commonParams];

      await executeSearch(finalSearchParams);
      return;
    }

    // Если есть колонки для поиска и inputValue длиннее 1 символа
    if (searchColumn.length > 0 && inputValue.length > 1) {
      const searchParams = searchColumn.map((item) => ({ [item]: inputValue }));

      const finalSearchParams = [...searchParams, ...commonParams];

      await executeSearch(finalSearchParams);
    }
  };

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => ({
      ...prevActiveButtons,
      [option.key]: !prevActiveButtons[option.key],
    }));
    setSearchColumn((prevData: any) => [option.dataIndex, ...prevData]);
  };

  const resUpdateRepair = (response: any) => {
    setActivityContainer(response.data.rows);
    setDataTable((prevData: any) => {
      return prevData.map((row: any) => {
        // Найти соответствующий элемент в response.data
        const matchedElement = response.data.rows.find(
          (el: any) => row.id === el.container_id,
        );
        if (matchedElement) {
          // Обновить поле repair, если найдено совпадение
          return {
            ...row, // Скопировать остальные свойства строки
            repair: matchedElement.repair, // Обновить значение repair
          };
        }
        return row; // Если совпадение не найдено, вернуть оригинальную строку
      });
    });
  };

  const resHandleDeleteRow = (response: any) => {
    setDataTable((prevData) => {
      // Проверьте, что response является массивом, если это необходимо
      if (!Array.isArray([response])) {
        console.error('Invalid response data:', [response]);
        return prevData;
      }

      // Предположим, что response это объект с обновленными данными
      const updatedItem = response;
      return prevData.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item,
      );
    });
  };

  const resContainerImport = (response: any) => {
    setDataTable((prevData) => {
      const updatedData = [...prevData];

      if (response?.rows?.length) {
        response.rows.forEach((newItem: any) => {
          const index = updatedData.findIndex((item) => item.id === newItem.id);
          if (index !== -1) {
            // Обновляем существующий объект
            updatedData[index] = newItem;
          } else {
            // Добавляем новый объект
            updatedData.unshift(newItem);
          }
        });

        return updatedData;
      }
      return prevData;
    });
    setIsModalOpenImport(false);
    //  setLoadings(false);
  };

  const handleNewContainer = (response: any) => {
    const result = JSON.parse(response) as {success: number, message?: string; data: IContainers[], user_id: number};
    const ktk = mapperContainers(result?.data?.[0]) as IContainers;
    if (result?.success === 0) {
      Utils.InfoOpenNotification(
        'topRight',
        result.message,
        0,
        api,
      );
    } else {
      let isCreate = true;
      setDataTable((prev) => {
        const result = [...prev];
        const index = result?.findIndex((cont) => cont?.id === ktk?.id);
        if (index < 0) {
          return [{...ktk}, ...result];
        }
        result[index] = {...ktk};
        isCreate = false;
        return [...result];
      })
      setOpenDrawerContainer(false);
      if (Utils.getUserInfo().id === result?.user_id) {
        Utils.openNotification(
          'topLeft',
          isCreate ? 'Добавлено' : 'Обновлено',
          api,
        );
      }
      form.resetFields();
    }
  };

  const handleNewBooking = (response: any) => {
    dispatch(
      getActivityContainer({
        id: response[0].id,
        current: (currentActivity - 1) * pageActivity,
        page: pageActivity,
      }),
    ).then((response) => {
      setTotalTableActivity(response.payload.count);
      setActivityContainer(response.payload.rows);
    });
    setDataTable((prevData: any) => {
      return prevData.map((row: any) =>
        row.id === response[0].id
          ? {
              ...row,
              ['status_terminal.name']: response[0]['status_terminal.name'],
              booking_before:
                response[0].booking_before !== null
                  ? moment(response[0].booking_before).format('DD.MM.YYYY')
                  : null,
            }
          : row,
      );
    });
  };

  const handleDateBooking = (response: any) => {
    setActivityContainer(response.rows);
    setDataTable((prevData: any) => {
      return prevData.map((row: any) => {
        // Найти соответствующий элемент в response.data
        const matchedElement = response.rows.find(
          (el: any) => row.id === el.container_id,
        );
        if (matchedElement) {
          return {
            ...row,
            repair: matchedElement.repair,
          };
        }
        return row; // Если совпадение не найдено, вернуть оригинальную строку
      });
    });
  };

  const onCreate = () => {
    setOpenDrawerContainer(true);
    setDataRowContainer({});
    form.resetFields();
  };

  const onCloseFormContainer = () => {
    setOpenDrawerContainer(false);
    // history.replace(RouteNames.CONTAINERS);
  };

  const closeModalContainers = () => {
    // history.replace(RouteNames.CONTAINERS);
    setIsModalOpenContainer(false);
    setDataRowContainer({});
  };

  const onCloseFilter = () => {
    history.push(RouteNames.CONTAINERS);
    updateDate({
      child: 0,
      city: 0,
      stock: null,
    });
    setNameTerminal(null);
  };

  const editRow = (payload: any) => {
    if (payload?.id) {
      setDataRowContainer(payload);
      setActivityContainer(payload?.activity || []);
      setDataComment(payload?.comments || []);
      setTypeForm(EDIT);
      if (payload?.delete) {
        setIsModalOpenContainer(true);
        return;
      }
      setOpenDrawerContainer(true);
    }
  };

  const onFinish = (data: any) => {
    dispatch(setContainer(data))
      .then(() => {
        setOpenDrawerContainer(false);
      })
      .catch((err) => message.error('Ошибка сохранения'));
  };

  const onEdit = () => {
    setTypeForm(EDIT);
    setIsModalOpenContainer(false);
    setOpenDrawerContainer(true);
  };
  const onView = () => {
    setOpenDrawerContainer(false);
    setIsModalOpenContainer(true);
  };

  const onRow = (row: any) => {
    if (row?.id) {
      setDataRowContainer(row);
      setActivityContainer(row?.activity || []);
      setDataComment(row?.comments || []);
      setIsModalOpenContainer(true);
    }
  };

  const handleShowModal = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChangeImport = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    TerminalService.handleFile(event, columns).then((response) => {
      if (response.length > 0) {
        setIsModalOpenImport(true);
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }
    });
  };

  const closeModalImport = () => {
    setIsModalOpenImport(false);
  };

  return (
    <Layout className="page-container">
      {contextHolder}
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('ContainerTable')}</div>
          <div className={'drawer-title terminal'}>{nameTerminal}</div>
          {nameTerminal !== null && (
            <Icon component={blackCloseIcon} onClick={onCloseFilter} />
          )}
        </Flex>
        <Flex gap={16}>
          <input
            style={{ display: 'none' }}
            ref={inputRef}
            type="file"
            id="fileSelect"
            accept=".xlsx, .xls, .csv"
            onChange={handleFileChangeImport}
          />
          <Button onClick={handleShowModal}>{t('Импорт')}</Button>

          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={onCreate}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            onSearch={(text) => handleSearch(text)}
            popupMatchSelectWidth={false}
            value={selectedOption}
            dropdownRender={() => (
              <>
                <div
                  className={'text-card-12'}
                  style={{ padding: '10px 10px' }}
                >
                  Выбор колонок по которым будет производиться поиск
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: 'wrap', padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      option.title !== '' &&
                      option.dataIndex !== 'date_entry' &&
                      option.dataIndex !== 'arrival_date' &&
                      option.dataIndex !== 'departure_date' &&
                      option.dataIndex !== 'booking_before' && (
                        <Button
                          size={'small'}
                          key={option.key}
                          type="primary"
                          className={
                            activeButtons[Number(option.key)]
                              ? 'active-search-btn'
                              : ''
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      ),
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder={t('Введите текст')} />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          {...optionsTable}
          subMenu={subMenu}
          editRow={editRow}
          dataSource={dataTable}
          columns={columns}
          height={'calc(-162px + 100vh)'}
          style={{ overflow: 'auto hidden' }}
          onRow={onRow}
          onClickLink={onRow}
        />
      </Space>
      <CustomDrawer open={openDrawerContainer} onClose={onCloseFormContainer}>
        <FormContainer
          isOpen={openDrawerContainer}
          isCopy
          onView={onView}
          typeForm={typeForm}
          form={form}
          dataRowContainer={dataRowContainer}
          onClose={onCloseFormContainer}
          onFinishForm={onFinish}
          title={
            typeForm === CREATE
              ? 'Добавить контейнер'
              : 'Редактировать контейнер'
          }
        />
      </CustomDrawer>
      <Modal
        className="page-container"
        closable={false}
        footer={null}
        width={'80%'}
        open={isModalOpenContainer}
      >
        <FormModalContainer
          onEdit={onEdit}
          isCopy
          totalTableActivity={totalTableActivity}
          setActivityContainer={setActivityContainer}
          currentActivity={currentActivity}
          pageActivity={pageActivity}
          activityContainer={activityContainer}
          dataComment={dataComment}
          dataRowContainer={dataRowContainer}
          closeModalContainers={closeModalContainers}
          isDisabled={
            !!dataRowContainer?.deal ||
            !!dataRowContainer?.release ||
            !!dataRowContainer?.delete ||
            !!dataRowContainer?.active
          }
        />
      </Modal>
      <Modal
        className="page-container"
        closable={false}
        footer={null}
        width={'94%'}
        open={isModalOpenImport}
      >
        <FormModalImport
          closeModalImport={closeModalImport}
          loadings={loadings}
          setLoadings={setLoadings}
        />
      </Modal>
    </Layout>
  );
};

export default Containers;
