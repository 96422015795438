import { apiAxios } from '../api/config';
import socket from '../socket';
import { IParamRestDelete } from '../types';

export class Rest_contractors {
  static getFiltersContractors(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('get-filters-contractors', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static updateContractors(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('update-contractors', payload);
    });
  }

  static setContractors(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        socket.emit('save-contractors', payload);
      } catch (e) {
        reject(e);
      }
    });
  }

  static getContractorsRequisites(id: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('get-contractors-requisites', id, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getFindCompany(payload: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('find-company', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getFindMyCompany(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('find-my-company', (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getContractors(payload: {}): Promise<any> {
    return apiAxios.post('contractors/get-contractors', payload);
  }

  static findCompanyById(id: string): Promise<any> {
    return apiAxios.get(`contractors/${id}`);
  }

  static deleteContractors(payload: IParamRestDelete): Promise<any> {
    return apiAxios.delete('contractors/delete-company', { data: payload });
  }
}
