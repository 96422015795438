import { IContainer } from "../../../store/sales";

export const TAB_DEAL = 'tabDeal';
export const TAB_DOCUMENT = 'tabDocument';
export const TAB_REPAIR = 'tabRepair';
export const FORMAT_DATE_KTK = 'DD.MM.YYYY';

export const TABLE_SALES_DEAL = 'tableSalesDeal';
export const ROW_SALES_DEAL = 'rowSalesDeal';
export const COUNT_SALES_DEAL = 'countSalesDeal';

export const salesTypeId = 3;

// ктк для колонок
export const nameColumns = [
  {style: {}, title: 'Релиз'},
  {style: {}, title: '№КТК'},
  {style: {}, title: 'Тип'},
  {style: {}, title: 'Статус'},
  {style: {}, title: 'Входящая ставка'},
  {style: {}, title: 'Стоимость'},
  {style: {}, title: 'Оплата подрядчику'},
  {style: {}, title: 'Дата выдачи'},
  {style: {}, title: 'Дата доставки'},
  {style: {minWidth: 'auto'}, title: 'ГТД'},
];
export interface DataType {
  key: React.Key;
  firstName: string;
  lastName: string;
  age: number;
  address: string;
  tags: string[];
}

export const columnsKtk: DataType[] = [
  {
    key: '3',
    firstName: 'Joe',
    lastName: 'Black',
    age: 32,
    address: 'Sydney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
];
// ктк для excel
export const nameColumnsKtk = [
  'Релиз',
  '№КТК',
  'Тип',
  'Статус',
  'Входящая ставка',
  'Стоимость',
  'Оплата подрядчику',
  'Дата выдачи',
  'Дата доставки',
  'ГТД',
];
export const keyNamesKtk = [
  'release',
  'num_container',
  'type_container',
  'status',
  'bet',
  'price',
  'payment',
  'issue_date',
  'departure_date',
  'gtd'
];

export const statusColorsDeal = [
  {
    title: 'Новый',
    color: '#00B288',
    background: '#E6F6EC',
  },
  {
    title: 'Ожидаем оплаты',
    color: '#F29100',
    background: '#FEF4E6',
  },
  {
    title: 'Выдан без оплаты',
    color: '#445371',
    background: '#EDEEF1',
  },
  {
    title: 'Продан',
    color: '#0055FF',
    background: '#F2F6FF',
  },
  {
    title: 'Просрочено',
    color: '#E14453',
    background: '#FCE8EA',
  },
];
enum CURRENCY_TYPE {
  '&#8381;' = 2, // рубль
  '&#36;' = 3, // доллар
  '&#8364;' = 4, // евро

}

