import React from 'react';
import { Flex, Tag } from 'antd';
import classnames from 'classnames';
import { IconButton, iconButtonType } from '../../ui/IconButton/IconButton';

import style from '../style.module.scss';
import { useTranslation } from 'react-i18next';

export interface IIcon {
  title: string;
  typeIcon: iconButtonType;
  onClick?: () => void;
}

interface IStatus {
  icons?: IIcon[];
  isActive?: boolean;
}

export const Status: React.FC<IStatus> = ({ icons, isActive }) => {
  const { t } = useTranslation();
  return (
    <Flex gap={10} align="center">
      {icons?.map(({ title, typeIcon, onClick }, i) => (
        <IconButton key={i} iType={typeIcon} title={title} onClick={onClick} />
      ))}
      <Tag
        className={classnames(style['status'], style['status_inactive'], {
          [style['status_active']]: isActive,
        })}
      >
        {t(isActive ? 'Онлайн' : 'Не в сети')}
      </Tag>
    </Flex>
  );
};
