import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  App,
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Select,
  Typography,
} from 'antd';

import TabContainers from './tabs/edit/TabContainers';
import { messagesModal, RENT_DEAL } from '../../../../constants';
import {
  TAB_DEAL,
  TAB_CONTAINERS,
  noStyleField,
  TAB_EXPENSES,
  dealNames,
  expensesNames,
} from '../constants';
import { RootState, useStoreDispatch } from '../../../../store';
import { ITab, ITabs } from '../../../../components/ui/Tabs/Tabs';
import ModalConfirm from '../../../../components/ui/ModalConfirm/ModalConfirm';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';
import {
  defaultExpenses,
  defaultLocationReturn,
  defaultRelease,
  IDeal,
  IFormDeal,
  rentTypeDefaultDealId,
  rentTypeId,
  setDeals,
} from '../../../../store/rent';
import TabDeal from './tabs/edit/TabDeal';
import style from '../style.module.scss';
import { Utils } from '../../../../utils';
import socket from '../../../../socket';
import {
  RES_SET_DEAL_RENT,
  SET_DEAL_RENT,
} from '../../../../services/rest_rent';
import { TabExpenses } from './tabs/edit/TabExpenses';
import { Rest_files } from '../../../../services/rest_files';
import { ITypeDeal } from '../../../../types';
import { getContainerSubmit } from '../utils';
import { setTableDeals } from '../../../../store/sales';
import { CopyBufferIconDefault } from '../../../../assets/image/svg';
import CopyButton from '../../../../components/ui/CopyButton/CopyButton';
import { useLink } from '../../../../hooks';
import IconButton from '../../../../components/ui/IconButton';
import Icon from '@ant-design/icons';

//для перехода из запроса в сделку
interface LocationState {
  tableName?: string;
  dealId?: string;
}

const FormDeal: React.FC<IFormDeal> = ({
  onRelease,
  onClose,
  onEdit,
  onView,
  isOpen,
  isCopy,
  initialValue,
  disabled,
  title = 'Редактировать сделку',
  isFromRequest = false,
}) => {
  const link = useLink({ key: RENT_DEAL, id: initialValue?.id as number });
  const location = useLocation<LocationState>();
  const { tableName } = location.state || {};
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useStoreDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState(() => TAB_DEAL);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [isErrorsDeal, setIsErrorsDeal] = React.useState(false);
  const [isErrorsExpenses, setIsErrorsExpenses] = React.useState(false);
  const isEditDeal = useSelector<RootState>((state) => state.rent.isEditDeal);
  const isEditDealContainers = useSelector<RootState>(
    (state) => state.rent.isEditDealContainers,
  ) as boolean;
  const dealData = useSelector<RootState>(
    (state) => state.rent.dealData,
  ) as IDeal[];
  const dealType = useSelector<RootState>(
    (state) => state.types.dataTypeDeal,
  ) as ITypeDeal[];

  const isDisabled = !!initialValue?.id;
  const formTitle = isFromRequest ? 'Создать сделку' : title;
  const formDisabled = isFromRequest ? false : disabled;

  // HACK: type IDeal
  const defaultValuesDeal: IDeal = {
    id: undefined,
    id_deal: '',
    type_deal: 'Аренда',
    type_deal_id: rentTypeDefaultDealId,
    type_deal_char: 'АА',
    phone: '',
    my_company: '',
    my_company_id: undefined,
    company: '',
    company_id: undefined,
    contact_id: undefined,
    agreement_id: undefined,
    agreement: null,
    releases: [defaultRelease],
    location_release: null,
    location_return: [defaultLocationReturn],
    related_accounts: [], // связанные счета
    related_deals: [], // связанные сделки
    related_deals_ids: [],
    nds: undefined,
    currency: '',
    currency_id: undefined,
    type_container: undefined,
    condition: undefined,
    count_ktk: undefined,
    total_ktk: undefined,
    bet: undefined,
    containers: [],
    containers_ids: [],
    fine: undefined,
    message_subject: undefined,
    additionally: undefined,
    count_day: undefined,
    snp: undefined,
    expenses: [defaultExpenses],
    responsible_id: null,
    responsible_name: null,
    companies: [],
    my_companies: [],
    agreement_ids: [],
    delete: false,
    edit: true,
  };

  useEffect(() => {
    if (isDisabled) {
      const { location_return } = initialValue;
      const currentRoute = Array.isArray(location_return)
        ? location_return
        : defaultValuesDeal.location_return;
      form.setFieldsValue({
        ...defaultValuesDeal,
        ...initialValue,
        location_return: currentRoute,
      });
    } else {
      const initUser = {
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id,
        isResponsible: true,
      };

      const responsibleInfo = {
        responsible_id: initUser.id,
        responsible_name: initUser.name,
      };
      form.setFieldsValue({
        ...defaultValuesDeal,
        ...initialValue,
        ...responsibleInfo,
      });
    }
  }, [isDisabled, isOpen]);

  useEffect(() => {
    if (isFromRequest) {
      const initUser = {
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id,
        isResponsible: true,
      };

      const responsibleInfo = {
        responsible_id: initUser.id,
        responsible_name: initUser.name,
      };

      form.setFieldsValue({
        ...defaultValuesDeal,
        ...initialValue,
        ...responsibleInfo,
      });
    } else if (initialValue?.id) {
      form.setFieldsValue({
        ...defaultValuesDeal,
        ...initialValue,
      });
    }
  }, [isFromRequest, initialValue, form]);

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: TAB_DEAL,
        label: 'Сделка',
        forceRender: true,
        isError: isErrorsDeal,
        disabledText: isEditDealContainers ? 'Завершите редактирование' : '',
        disabled: isEditDealContainers,
        content: <TabDeal initialValue={initialValue} isDisabled={disabled} />,
      },
      {
        key: TAB_CONTAINERS,
        label: 'Контейнеры',
        forceRender: true,
        content: 
          <TabContainers 
            initialValue={initialValue} 
            isDisabled={disabled} 
            isFromRequest={isFromRequest}
          />,
      },
      {
        key: TAB_EXPENSES,
        label: 'Доп.Расходы',
        isError: isErrorsExpenses,
        forceRender: true,
        content: <TabExpenses initialValue={initialValue} isDisabled={disabled} />,
      },
    ],
    [isErrorsDeal, form, isEditDeal, isEditDealContainers, disabled],
  );

  const onSubmit = async (values: IDeal) => {
    try {
      setIsLoading(true);
      // не забыть сохранить новые файлы releases
      if (values?.releases?.length) {
        for (const release of values.releases) {
          if (release?.saveFiles?.length) {
            const res = await Rest_files.submitFiles(release.saveFiles);
            if (res.data?.length) {
              release.ids.push(...res.data);
              release.saveFiles = []; // очистка файлов
            }
          }
        }
      }

      delete values.related_deals;

      values.containers = values?.containers?.map(getContainerSubmit);
      const submit = {
        ...initialValue,
        ...values,
      };
      console.log(submit);

      dispatch(setDeals(submit)).finally(() => {
        socket.off(SET_DEAL_RENT);
      });
      socket.on(RES_SET_DEAL_RENT, (res) => {
        if (res?.id) {
          const result = dealData?.length ? [...dealData] : [];
          const index = dealData.findIndex((deal) => deal?.id === res.id);
          if (index >= 0) {
            result[index] = res;
          } else {
            result.push(res);
          }
          dispatch(setTableDeals(result));
        }
        setIsLoading(false);
        handleClose(true);
        form.resetFields();
      });
      socket.on('error', (err) => {
        console.error(err);
        message.error('Произошла ошибка во время сохранения');
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      console.error('submit', err);
    }
  };
  const onUpdate = async (changedValues: IDeal, allValues: IDeal) => {
    setIsErrorsDeal(false);
    setIsErrorsExpenses(false);
    return;
  };
  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (!outOfDate && errorFields) {
      for (const errors of errorFields) {
        const title = errors?.name?.[0];
        if (isErrorsDeal && isErrorsExpenses) {
          break;
        }
        if (dealNames.includes(title)) {
          setIsErrorsDeal(true);
        }
        if (expensesNames.includes(title)) {
          setIsErrorsExpenses(true);
        }
      }
    }

    return;
  };

  const handleClose = (isClear?: boolean) => {
    if (onClose) {
      onClose(isClear);
    }
    form.resetFields();
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  const selectedType = (val: string | null, opt: any) => {
    form.setFieldsValue({
      type_deal: opt?.label ?? '',
      type_deal_char: opt?.char || '',
    });
  };

  //дляперехода из запроса аренды
  const filteredOptions = dealType
    ?.filter(({ status_containers_id }) => status_containers_id === rentTypeId)
    ?.map(({ id, value, char }) => ({
      value: id,
      label: value,
      char,
    }));

  useEffect(() => {
    if (initialValue?.type_deal_id) {
      form.setFieldsValue({
        ...defaultValuesDeal,
        ...initialValue,
      });
    }
  }, [initialValue, form]);
  
  useEffect(() => {
    if (isFromRequest && initialValue) {
      form.setFieldsValue({
        ...defaultValuesDeal,
        ...initialValue, // Устанавливаем значения из запроса аренды
      });
    }
  }, [isFromRequest, initialValue, form]);

  return (
    <>
      <Form
        // disabled={disabled}
        disabled={formDisabled}
        className={style['tabs-form']}
        autoComplete="off"
        noValidate
        layout="vertical"
        form={form}
        initialValues={{ ...defaultValuesDeal, ...initialValue }}
        scrollToFirstError
        onFinish={onSubmit}
        onFinishFailed={onFinishError}
        onValuesChange={onUpdate}
      >
        {noStyleField.map((name) => (
          <Form.Item key={name} name={name} noStyle />
        ))}
        <Flex vertical gap={10}>
          <Flex justify="end">
            <Flex gap={5} align={'center'}>
              <IconButton
                hidden={!onView || disabled || !isDisabled}
                disabled={!isDisabled}
                iType="view-form"
                onClick={onView}
              />
              <IconButton
                hidden={
                  !!initialValue?.delete ||
                  !onEdit ||
                  !initialValue?.edit ||
                  !disabled
                }
                disabled={!isDisabled}
                iType="edit-form"
                onClick={onEdit}
              />
              {isDisabled && isCopy && (
                <CopyButton
                  text={link}
                  textSuccess={t('Ссылка скопирована')}
                  icon={<Icon component={CopyBufferIconDefault} />}
                  disabled={!isDisabled}
                />
              )}
            </Flex>
          </Flex>
          <Flex justify="space-between" align="center">
            <Flex gap={10} align="center">
              <Typography.Text className={style.title}>
                {disabled ? initialValue.id_deal : t(title ?? '')}
              </Typography.Text>
            </Flex>
            <Flex justify="space-between" align="center" gap={10}>
              <Form.Item
                name={'type_deal_id'}
                rules={[{ required: true, message: t('Обязательное поле') }]}
                style={{ margin: 0, width: '130px' }}
              >
                <Select
                  placeholder={t('Тип сделки')}
                  optionLabelProp="label"
                  options={dealType
                    ?.map(({ id, value, char, status_containers_id }) => ({
                      value: id,
                      label: value,
                      char,
                      status_containers_id,
                    }))
                    ?.filter(
                      ({ status_containers_id }) =>
                        status_containers_id === rentTypeId,
                    )}
                  disabled={isDisabled}
                  onSelect={selectedType}
                />
              </Form.Item>
              <Button
                type="default"
                onClick={onRelease}
                style={{ margin: 0, width: '130px' }}
              >
                {t('Создать релиз')}
              </Button>
              <Form.Item name={'id_deal'} style={{ margin: 0, width: '130px' }}>
                <Input placeholder={'ID сделки'} allowClear disabled />
              </Form.Item>
            </Flex>
          </Flex>

          <Divider style={{ margin: 0 }} />
        </Flex>
        <ITabs
          className={style['tabs-form__tab']}
          isErrorTabs
          activeKey={activeKey}
          tabs={tabs}
          onChange={changeClickTab}
        />
        <Form.Item hidden={disabled}>
          <Flex gap={50} justify="end" style={{ margin: '32px 0' }}>
            <TooltipButton
              propsTooltip={{
                title: isEditDealContainers
                  ? 'Завершите редактирование'
                  : 'Добавить',
              }}
              propsButton={{
                type: 'primary',
                htmlType: 'submit',
                style: { maxWidth: '290px', minWidth: '290px' },
                disabled: isLoading || isEditDealContainers,
              }}
            >
              {isLoading
                ? '...' + t('Сохранение')
                : initialValue?.id
                ? t('Сохранить изменения')
                : t('Добавить')}
            </TooltipButton>
            <Button
              onClick={() => setConfirmation(true)}
              type={'text'}
              style={{ color: '#E14453', maxWidth: '290px', minWidth: '290px' }}
            >
              Отменить
            </Button>
          </Flex>
        </Form.Item>
        {disabled && (
          <Flex
            gap={8}
            justify="space-between"
            style={{ margin: '32px 32px 32px auto', maxWidth: '500px' }}
          >
            <TooltipButton
              propsButton={{
                onClick: () => {
                  if (onClose) {
                    onClose();
                  }
                },
                type: 'text',
                style: {
                  maxWidth: '290px',
                  minWidth: '290px',
                  color: '#E14453',
                },
                disabled: !disabled,
              }}
            >
              Закрыть
            </TooltipButton>
          </Flex>
        )}
      </Form>
      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </>
  );
};

export default FormDeal;
