import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Modal,
  Popover,
  Row,
  Select,
  Skeleton,
  Tag,
  Typography,
} from 'antd';
import { RootState, useStoreDispatch } from '../../store';
import {
  getActivityContainer,
  getHistoryContainer,
  updateDateBooking,
  updateNewBooking,
  updateRepair,
} from '../../store/containers';
import Icon from '@ant-design/icons';
import { blackCloseIcon, moreIcon } from '../../assets/image/svg';
import ListComponent from '../../components/ui/List/ListComponent';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../utils';
import moment from 'moment/moment';
import { IContainers, IContainersStatus } from '../../types/containers';
import FullScreen from '../../components/ui/FullScreen/FullScreen';
import Carousel from '../../components/ui/FullScreen/Carousel';
import FormModalComments from './formModalComments';
import Uploader from '../../components/ui/Uploader/Uploader';
import FormModalHistory from './history/formModalHistory';
import { RangePickerProps } from 'antd/es/date-picker';
import ScrollList from '../../components/ui/ScrollList';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { RouteNames } from '../../router/names';
import { getSearchLink, openInNewTab } from '../../helpers/link-helper';
import { useLink, useParamsHistory } from '../../hooks';
import { CONTAINER, RELEASE, SALES_DEAL } from '../../constants';
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import { UploaderPreview } from '../../components/ui/Uploader/UploaderPreview';

const { Title, Link } = Typography;

interface Props {
  dataRowContainer?: IContainers;
  closeModalContainers?: any;
  dataComment?: any;
  activityContainer?: any;
  currentActivity?: number;
  pageActivity?: number;
  setActivityContainer?: (value: any) => void;
  totalTableActivity: number;
  isDisabled?: boolean;
  isCopy?: boolean;
  onEdit: () => void;
}

const FormModalContainer: React.FC<Props> = ({
  dataRowContainer,
  closeModalContainers,
  currentActivity,
  pageActivity,
  totalTableActivity,
  isDisabled,
  isCopy,
  onEdit,
  dataComment,
  activityContainer,
  setActivityContainer,
}) => {
  const link = useLink({ key: CONTAINER, id: dataRowContainer?.id as number });
  const dataStatusContainer = useSelector(
    (state: RootState) => state.containers.dataStatusContainer,
  );
  const { history } = useParamsHistory();
  const dispatch = useStoreDispatch();
  const { t, i18n } = useTranslation();
  const [dataPhoto, setDataPhoto] = useState<any>([]);
  const [status, setStatus] = useState<string>('Не оплачен');
  const [booking, setBooking] = useState(false);
  const [dateBooking, setDateBooking] = useState(moment().format('DD.MM.YYYY'));
  const [extend, setExtend] = useState(false);
  const [newStatus, setNewStatus] = useState<boolean>(false);
  const [responsible, setResponsible] = useState<string>('');
  const [statusTerminal, setStatusTerminal] = useState<string>('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [isModalOpenComment, setIsModalOpenComment] = useState<boolean>(false);
  const [parentDataComment, setParentDataComment] = useState<any[]>([]);
  const [checkedRepair, setCheckedRepair] = useState<boolean>(false);
  const [typeModalOpen, setTypeModalOpen] = useState<string>('');
  const tableRef = useRef<HTMLDivElement>(null);
  const [current, setCurrent] = useState<number>(1);
  const [lastItemReached, setLastItemReached] = useState<boolean>(false);
  let [page, setPage] = useState<number>(10);
  const [isMenu, setIsMenu] = useState(false);
  const [previewIndex, setPreviewIndex] = useState<number>(0);

  useEffect(() => {
    setCheckedRepair(dataRowContainer?.repair);
  }, [dataRowContainer]);

  useMemo(() => {
    setParentDataComment(dataComment);
    setResponsible(dataRowContainer?.responsible?.fio);
  }, [dataRowContainer?.responsible?.fio, JSON.stringify(dataComment)]);

  useMemo(() => {
    setStatusTerminal(
      dataRowContainer?.status_terminal !== null
        ? dataRowContainer?.status_terminal?.name ?? ''
        : '',
    );
  }, [dataRowContainer?.status_terminal?.name]);

  const updateDate = useMemo(() => {
    return moment(dateBooking, 'DD.MM.YYYY')
      .add(7, 'days')
      .format('YYYY-MM-DD');
  }, [dateBooking]);

  const handlePlaceOrder = (payload: IContainersStatus) => {
    setStatusTerminal(payload.name);
    if (payload.name === 'Продажа') {
      setNewStatus(false);
      if (closeModalContainers) {
        closeModalContainers();
      }
      history.replace(RouteNames.SALES_DEAL, {
        [SALES_DEAL]: { containers: [dataRowContainer] },
      });
      return;
    }
    if (payload.name === 'Хранение') {
      setNewStatus(false);
      dispatch(
        updateNewBooking({
          ...payload,
          status_id: payload?.id,
          id: dataRowContainer?.id,
          booking_before: null,
          responsible_id: Utils.getUserInfo().id,
          current: (Number(currentActivity) - 1) * Number(pageActivity),
          page: pageActivity,
        }),
      );
      setDateBooking(moment().format('DD.MM.YYYY'));
    } else {
      setDateBooking(
        moment(dateBooking, 'DD.MM.YYYY').add(7, 'days').format('DD.MM.YYYY'),
      );
      setNewStatus(true);
      dispatch(
        updateNewBooking({
          ...payload,
          status_id: payload.id,
          id: dataRowContainer?.id,
          booking_before: updateDate,
          responsible_id: Utils.getUserInfo().id,
          current: (Number(currentActivity) - 1) * Number(pageActivity),
          page: pageActivity,
        }),
      );
    }
  };

  const handleStatus = (value: any) => {
    if (value === 'Новое бронирование') {
      setIsMenu(false);
      if (statusTerminal === 'Хранение') {
        setStatusTerminal('');
      }
      setResponsible(Utils.getUserInfo().fio);
      setNewStatus(true);
    }
    if (value === 'История изменений') {
      setIsMenu(false);
      setIsModalOpenComment(true);
      setTypeModalOpen('history');
      if (dataRowContainer?.id) {
        dispatch(getHistoryContainer(dataRowContainer.id));
      }
    }
  };

  const contentOrder = (
    <ListComponent
      objectList={dataStatusContainer.filter(
        (item) =>
          item.name === 'Хранение' ||
          item.name === 'Аренда' ||
          item.name === 'Продажа',
      )}
      type={'object'}
      onClickObject={handlePlaceOrder}
    />
  );
  const contentStatus: JSX.Element = (
    <ListComponent
      arrayList={['Paid', 'NotPaid']}
      type={'array'}
      onClickArray={handleStatus}
    />
  );
  const containerStatusOrder: JSX.Element = (
    <>
      {!isDisabled && (
        <ListComponent
          arrayList={[
            'NewBooking',
            'PlaceOrder',
            'CancelReservation',
            'HistoryChanges',
            'ListContainers',
          ]}
          type={'array'}
          onClickArray={(val) => {
            handleStatus(val);
            setIsMenu(false);
          }}
        />
      )}
      {!isDisabled && (
        <div className={'list-ul'}>
          <div
            className="list-li"
            onClick={() => {
              setIsMenu(false);
              onEdit();
            }}
          >
            {t('Редактировать')}
          </div>
        </div>
      )}
      {isCopy && (
        <div className={'list-ul'}>
          <div className="list-li">
            <CopyButton
              textButton="Копировать ссылку"
              text={link}
              textSuccess={t('Ссылка скопирована')}
              onClick={() => {
                setIsMenu(false);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
  const handleDateExtend = (date: any) => {
    dispatch(
      updateDateBooking({
        status_id:
          dataStatusContainer
            ?.filter((item) => item.name === 'Продление')
            .map((item) => item.id)[0] || 1,
        booking_before: date,
        id: dataRowContainer?.id,
        responsible_id: Utils.getUserInfo().id,
        current: (Number(currentActivity) - 1) * Number(pageActivity),
        page: pageActivity,
      }),
    );
    setDateBooking(moment(date['$d'], 'DD.MM.YYYY').format('DD.MM.YYYY'));
    setExtend(false);
  };

  const handleCloseExtend = () => {
    setExtend(false);
  };

  const closeContainers = () => {
    closeModalContainers();
    setNewStatus(false);
    setCurrent(1);
    setPage(10);
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0);
    }
  };

  const onCloseModalImages = () => {
    setPreviewVisible(false);
    setPreviewIndex(0);
  };

  const onOpenModalImages = (index: number) => {
    setPreviewIndex(index);
    setPreviewVisible(true);
  };

  const handleComment = () => {
    setIsModalOpenComment(true);
    setTypeModalOpen('comment');
  };

  const handleCloseComment = () => {
    setIsModalOpenComment(false);
  };

  const formatFIO = (fullName: string) => {
    if (!fullName) return '';
    const [lastName, firstName, middleName] = fullName.split(' ');
    if (!lastName || !firstName || !middleName) return fullName; // обработка случая, когда имя неполное
    return `${lastName} ${firstName[0]}. ${middleName[0]}.`;
  };

  const updateParentDataComment = (newData: any, type: string) => {
    if (type === 'add') {
      setParentDataComment((prevData: any) => [newData, ...prevData]);
    } else {
      setParentDataComment(newData);
    }
  };

  const content = parentDataComment.length > 0 && (
    <>
      <div>
        {formatFIO(parentDataComment[0]?.['user.fio'])}{' '}
        {moment(parentDataComment[0]?.createdAt).format('DD.MM.YYYY H:mm')}
      </div>
      <div className={'box-popover'}>{parentDataComment[0]?.text}</div>
    </>
  );

  const checkRepair: CheckboxProps['onChange'] = (e) => {
    let statusId = 1;
    if (!e.target.checked) {
      statusId =
        dataStatusContainer
          ?.filter((item) => item.name === 'В ремонте')
          .map((item) => item.id)[0] || 1;
    } else {
      statusId =
        dataStatusContainer
          ?.filter((item) => item.name === 'Вышел из ремонта')
          .map((item) => item.id)[0] || 1;
    }

    setCheckedRepair(e.target.checked);
    dispatch(
      updateRepair({
        status_id: statusId,
        repair: e.target.checked,
        id: dataRowContainer?.id,
        user_id: Utils.getUserInfo().id,
        current: currentActivity,
        responsible_id: Utils.getUserInfo().id,
        page: pageActivity,
      }),
    );
  };

  const closeModalHistory = () => {
    setIsModalOpenComment(false);
    setTypeModalOpen('');
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const today = moment().startOf('day');
    const limitDate = moment().add(7, 'days');
    return current && (current < today || current > limitDate);
  };

  const renderTag = (
    label: string,
    value: any,
    dateFormat = false,
    isStatus = false,
  ) => {
    const hasValue = Boolean(value);
    const displayValue = hasValue
      ? dateFormat
        ? moment(value).format('DD.MM.YYYY')
        : value
      : 'Отсутствует';

    const style = isStatus
      ? hasValue
        ? { color: '#13AA64', background: '#CEFBE7' }
        : { color: '#445371', background: '#EDEEF1' }
      : hasValue
      ? { color: '#0055FF', background: '#E6EEFF' }
      : { color: '#445371', background: '#EDEEF1' };

    return (
      <Flex vertical gap={4}>
        <Typography.Text className={'text-card-12'}>{label}</Typography.Text>
        <Tag bordered={false} style={style}>
          {displayValue}
        </Tag>
      </Flex>
    );
  };

  const paginationActivity = () => {
    dispatch(
      getActivityContainer({
        id: dataRowContainer?.id,
        current: (Number(currentActivity) - 1) * Number(pageActivity) + page,
        page: Number(pageActivity),
      }),
    ).then((response) => {
      if (response.payload.rows.length > 0) {
        setPage(+Number(pageActivity) + page);
        setCurrent((Number(currentActivity) - 1) * Number(pageActivity) + page);
        if (setActivityContainer) {
          setActivityContainer((prevContainer: any) => [
            ...prevContainer,
            ...response.payload.rows,
          ]);
        }
      }
      setLastItemReached(false);
    });
  };

  return (
    <div
      onClick={() => {
        if (isMenu) {
          setIsMenu(false);
        }
      }}
    >
      <Row className={classnames('modal-container', 'page-container')}>
        <Flex align={'center'} vertical={false} style={{ width: '100%' }}>
          <Col span={7}>
            <Title level={4} style={{ marginBottom: 0 }}>
              {dataRowContainer?.num_container}
            </Title>
          </Col>
          <Col span={15} className={'text-base'}>
            <Flex gap={12}>
              <div>{dataRowContainer?.type_container}</div>
              <div>|</div>
              <div>
                Собственник: {dataRowContainer?.contractors?.name_company}
              </div>
              <div className={'line'}>|</div>
              <div>Терминал: {dataRowContainer?.child_terminal?.name}</div>
            </Flex>
          </Col>
          <Col span={2}>
            <Flex gap={10} justify={'flex-end'} style={{ cursor: 'pointer' }}>
              <Popover
                placement="bottomRight"
                content={isCopy || !isDisabled ? containerStatusOrder : null}
                open={isMenu}
              >
                <Icon component={moreIcon} onClick={() => setIsMenu(true)} />
              </Popover>
              <Icon component={blackCloseIcon} onClick={closeContainers} />
            </Flex>
          </Col>
        </Flex>
      </Row>
      <Divider style={{ marginBottom: 0, marginTop: 8 }} />
      <Form autoComplete="off">
        <Flex className={'container-modal'} vertical gap={20}>
          <Row gutter={[16, 16]}>
            <Col>
              {renderTag('Состояние', dataRowContainer?.quality, false, true)}
            </Col>
            <Col>
              {renderTag('Дата входа', dataRowContainer?.date_entry, true)}
            </Col>
            <Col>
              {renderTag('Дата прибытия', dataRowContainer?.arrival_date, true)}
            </Col>
            <Col>
              {renderTag('Дата убытия', dataRowContainer?.departure_date, true)}
            </Col>
            <Col>
              {renderTag('Входящая ставка', dataRowContainer?.currency_price)}
            </Col>
            <Col>
              {dataRowContainer?.deal
                ? renderTag(
                    'Продажа',
                    <Typography.Link
                      onClick={() =>
                        openInNewTab(
                          getSearchLink({
                            key: SALES_DEAL,
                            id: dataRowContainer.deal.id,
                          }),
                        )
                      }
                    >
                      {dataRowContainer.deal?.id_deal || ''}
                    </Typography.Link>,
                  )
                : null}
            </Col>
            <Col>
              {dataRowContainer?.release
                ? renderTag(
                    'Релиз',
                    <Typography.Link
                      onClick={() =>
                        openInNewTab(
                          getSearchLink({
                            key: RELEASE,
                            id: dataRowContainer.release?.id,
                          }),
                        )
                      }
                    >
                      {dataRowContainer.release?.release_id || ''}
                    </Typography.Link>,
                  )
                : null}
            </Col>
            <Col>{renderTag('Сток', dataRowContainer?.stock, false)}</Col>
          </Row>
          <Flex
            style={{ marginBottom: 24, width: '100%' }}
            className={
              dataRowContainer?.delete ? 'disabled-div' : 'enabled-div'
            }
          >
            <Col span={8}>
              <Form.Item name={'status'}>
                <Flex vertical gap={9}>
                  <Title level={5} className={'text-base sm'}>
                    <Flex gap={14}>
                      <div>{t('current_status')}</div>
                      {statusTerminal === 'Хранение' ? (
                        <div className={'text-base sm blue'}>
                          {statusTerminal}
                        </div>
                      ) : isDisabled ? (
                        <div className={'text-base sm blue'}>
                          {' '}
                          {dataRowContainer?.delete
                            ? t('Booking') + ' ' + statusTerminal
                            : t('Продажа')}
                        </div>
                      ) : (
                        <Popover
                          placement="bottom"
                          content={contentOrder}
                          trigger="click"
                        >
                          <div className={'text-base sm blue'}>
                            {' '}
                            {t('Booking') + ' ' + statusTerminal}
                          </div>
                        </Popover>
                      )}
                    </Flex>
                  </Title>
                  <Title
                    level={5}
                    className={'text-base sm'}
                    style={{ margin: '0 0 10px' }}
                  >
                    <Flex gap={14}>
                      <div>Статус оплаты:</div>
                      {isDisabled ? (
                        <div className={'text-base sm blue'}>{status}</div>
                      ) : (
                        <Popover
                          placement="bottom"
                          content={contentStatus}
                          trigger="click"
                        >
                          <div className={'text-base sm blue'}>{status}</div>
                        </Popover>
                      )}
                    </Flex>
                  </Title>
                </Flex>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Title level={5} className={'text-base sm'}>
                  <Flex gap={14} justify={'space-between'}>
                    <Flex gap={65}>
                      <div>Бронь до:</div>
                      <Flex gap={9} style={{ height: 0 }}>
                        <Flex vertical>
                          {statusTerminal !== 'Хранение' ? (
                            <Link
                              onClick={() => setBooking(!booking)}
                              disabled={isDisabled}
                            >
                              <div className={'text-base sm red'}>
                                {dateBooking}
                              </div>
                            </Link>
                          ) : (
                            <Link
                              onClick={() => setBooking(!booking)}
                              disabled={!newStatus || isDisabled}
                            >
                              <div className={'text-base sm'}>
                                {dateBooking}
                              </div>
                            </Link>
                          )}
                        </Flex>
                        <Flex vertical>
                          {statusTerminal !== 'Хранение' && !newStatus ? (
                            <Link
                              onClick={() => setExtend(!extend)}
                              disabled={isDisabled}
                            >
                              <div className={'text-base sm blue'}>
                                Продлить
                              </div>
                            </Link>
                          ) : (
                            <Link
                              onClick={() => setExtend(!extend)}
                              disabled={!newStatus || isDisabled}
                            >
                              <div className={'text-base sm'}>Продлить</div>
                            </Link>
                          )}
                          <DatePicker
                            disabled={isDisabled}
                            disabledDate={disabledDate}
                            open={extend}
                            onOpenChange={handleCloseExtend}
                            style={{ visibility: 'hidden', bottom: 35 }}
                            onChange={handleDateExtend}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Title>
                <Title level={5} className={'text-base sm'}>
                  <Flex gap={14}>
                    <div>Ответственный:</div>
                    <div className={'text-base sm blue'}>{responsible}</div>
                  </Flex>
                </Title>
              </Form.Item>
            </Col>
            <Flex gap={7} vertical>
              <Checkbox
                className={'text-base sm'}
                checked={checkedRepair}
                onChange={checkRepair}
                disabled={dataRowContainer?.delete || isDisabled}
              >
                В ремонте
              </Checkbox>
              <Select
                disabled={isDisabled}
                defaultValue="Доставка"
                style={{ width: 150 }}
                options={[{ value: 'Доставка', label: 'Доставка' }]}
              />
            </Flex>
          </Flex>
          <Flex gap={22} vertical>
            <Flex className={'container-photo all-custom-v-scroll'} style={{padding: '0 12px'}}>
              <UploaderPreview
                isOpen
                isView
                ids={dataRowContainer?.ids_photo}
              />
              {!dataRowContainer?.ids_photo?.length && (
                <Typography.Paragraph style={{fontSize: '20px', margin: 'auto'}}>
                  {t('Нет изображений')}
                </Typography.Paragraph>
              )}
            </Flex>
            <Flex gap={12} vertical>
              <ScrollList
                setLastItemReached={setLastItemReached}
                lastItemReached={lastItemReached}
                totalTableActivity={totalTableActivity}
                paginationActivity={paginationActivity}
                tableRef={tableRef}
                height={101}
                data={activityContainer}
              >
                {activityContainer?.map((item: any, i: number) => (
                  <div key={i} className="table-row">
                    <div className="table-cell">
                      {moment(item?.created_at).format('DD.MM.YY H:mm')}
                    </div>
                    <div className="table-cell">{item?.status?.name}</div>
                    <div className="table-cell">{item?.user?.fio}</div>
                  </div>
                ))}
              </ScrollList>
              <div className={'box-container'}>
                <div className={'text-base xs'}>Перемещения</div>
              </div>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify={'flex-start'} style={{ width: '100%' }}>
          <Popover placement="topLeft" content={content} arrow={false}>
            <Button
              type={'text'}
              style={{ width: 151, color: '#05F' }}
              onClick={handleComment}
              disabled={isDisabled}
            >
              Комментарий
            </Button>
          </Popover>
        </Flex>
      </Form>
      <Modal
        className="page-container"
        open={isModalOpenComment}
        footer={null}
        closable={typeModalOpen === 'comment'}
        width={typeModalOpen === 'comment' ? 828 : '80%'}
        centered
        onCancel={handleCloseComment}
        title={typeModalOpen === 'comment' && 'Комментарий'}
      >
        {typeModalOpen === 'comment' ? (
          <FormModalComments
            rowId={dataRowContainer?.id}
            dataComment={dataComment}
            updateParentDataComment={updateParentDataComment}
          />
        ) : (
          <FormModalHistory
            dataRowContainer={dataRowContainer}
            activityContainer={activityContainer}
            closeModalHistory={closeModalHistory}
          />
        )}
      </Modal>
    </div>
  );
};

export default FormModalContainer;
