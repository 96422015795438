import React from 'react';

import { useTranslation } from 'react-i18next';
import { Avatar, Col, Divider, Flex, Input, Row, Typography } from 'antd';

import Icon, { UserOutlined } from '@ant-design/icons';

import { Icons } from './Icons';
import { useLink } from '../../hooks';
import { CONTACT } from '../../constants';
import Button from '../../components/ui/Button/Button';
import IconButton from '../../components/ui/IconButton';
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import {
  CopyBufferIconDefault,
  copyIcon,
  EmailIcon,
  flagRuIcon,
} from '../../assets/image/svg';

import './style.scss';

const { Text, Title } = Typography;

interface IContact {
  dataContacts: any;
  isCopy?: boolean;
  onEdit: () => void;
  onClose: () => void;
}

const ViewContacts: React.FC<IContact> = ({
  dataContacts,
  isCopy,
  onEdit,
  onClose,
}) => {
  const { t } = useTranslation();
  const link = useLink({ key: CONTACT, id: dataContacts?.id as number });
  return (
    <>
      <Flex vertical>
        <Flex justify={'space-between'} className={'drawer-header'}>
          <Flex vertical style={{ width: '100%' }}>
            <Flex justify={'space-between'} align={'center'}>
              <h2 className={'drawer-title'}>{dataContacts?.full_name}</h2>
              <Flex gap={5} align={'center'}>
                <IconButton
                  iType="edit-form"
                  onClick={onEdit}
                  disabled={!!dataContacts?.delete}
                />
                {isCopy && (
                  <CopyButton
                    text={link}
                    textSuccess={t('Ссылка скопирована')}
                    icon={<Icon component={CopyBufferIconDefault} />}
                  />
                )}
              </Flex>
            </Flex>
            <h3>Источник</h3>
            <h4>{dataContacts.source}</h4>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Flex gap={4} vertical>
              <h3>Должность</h3>
              <h4>{dataContacts.position}</h4>
            </Flex>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Flex gap={4} vertical>
              <Flex gap={4} vertical>
                <h3>Контакты</h3>
                <Row>
                  <Col className="gutter-row" span={10}>
                    <Text>{dataContacts?.type_phone_first}</Text>
                  </Col>
                  {dataContacts?.phone_first && (
                    <>
                      <Col className="gutter-row" span={12}>
                        <Flex gap={4}>
                          <Icon component={flagRuIcon} />
                          <Text>{dataContacts?.phone_first}</Text>
                        </Flex>
                      </Col>
                      <Col className="gutter-row" span={2}>
                        <CopyButton
                          text={dataContacts?.phone_first}
                          icon={<Icon component={copyIcon} />}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                {dataContacts?.last_phone?.map((item: any, index: number) => {
                  return (
                    <Row key={index}>
                      <Col className="gutter-row" span={10}>
                        <Text>{item.add_type_phone}</Text>
                      </Col>
                      {item.add_phone && (
                        <>
                          <Col className="gutter-row" span={12}>
                            <Flex gap={4}>
                              <Icon component={flagRuIcon} />
                              <Text>{item.add_phone}</Text>
                            </Flex>
                          </Col>
                          <Col className="gutter-row" span={2}>
                            <CopyButton
                              text={item.add_phone}
                              icon={<Icon component={copyIcon} />}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  );
                })}
              </Flex>
              <Flex gap={4} vertical>
                {dataContacts?.email_first !== null && (
                  <Row>
                    <Col className="gutter-row" span={10}>
                      <Text>{dataContacts?.type_email_first}</Text>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Flex gap={4} align={'center'}>
                        <Button icon={<EmailIcon />} type={'icon'} />
                        <Text>{dataContacts?.email_first}</Text>
                      </Flex>
                    </Col>
                    <Col className="gutter-row" span={2}>
                      <CopyButton
                        text={dataContacts?.email_first}
                        icon={<Icon component={copyIcon} />}
                      />
                    </Col>
                  </Row>
                )}
                {dataContacts?.last_email?.map((item: any, index: number) => {
                  return (
                    <Row key={index}>
                      <Col className="gutter-row" span={10}>
                        <Text>{item.add_type_email}</Text>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <Flex gap={4}>
                          <Button icon={<EmailIcon />} type={'icon'} />
                          <Text>{item.add_email}</Text>
                        </Flex>
                      </Col>
                      <Col className="gutter-row" span={2}>
                        <CopyButton
                          text={item.add_email}
                          icon={<Icon component={copyIcon} />}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </Flex>
              <Flex gap={4} vertical>
                {dataContacts?.messenger_first !== null && (
                  <Row>
                    <Col className="gutter-row" span={10}>
                      <Text>{dataContacts?.type_messenger_first}</Text>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Flex gap={4}>
                        <Icons.arrIcon
                          type={dataContacts?.type_messenger_first}
                        />
                        <Text>{dataContacts?.messenger_first}</Text>
                      </Flex>
                    </Col>
                    <Col className="gutter-row" span={2}>
                      <CopyButton
                        text={dataContacts?.messenger_first}
                        icon={<Icon component={copyIcon} />}
                      />
                    </Col>
                  </Row>
                )}
                {dataContacts?.last_messenger?.map(
                  (item: any, index: number) => {
                    return (
                      <Row key={index}>
                        <Col className="gutter-row" span={10}>
                          <Text>{item.add_type_messenger}</Text>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Flex gap={4}>
                            <Icons.arrIcon type={item.add_type_messenger} />
                            <Text>{item.add_messenger}</Text>
                          </Flex>
                        </Col>
                        <Col className="gutter-row" span={2}>
                          <CopyButton
                            text={item.add_messenger}
                            icon={<Icon component={copyIcon} />}
                          />
                        </Col>
                      </Row>
                    );
                  },
                )}
              </Flex>
            </Flex>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Flex gap={4} vertical>
              <h3>Компания</h3>
              {dataContacts?.company !== null &&
                dataContacts?.company.map((item: any, index: number) => (
                  <h5 key={index}>{item}</h5>
                ))}
            </Flex>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Flex gap={4} vertical>
              <h3>Тип контакта</h3>
              <Text>{dataContacts.type_contact}</Text>
            </Flex>
            <Divider />
            <Flex gap={6} vertical>
              <h3 style={{ marginBottom: 0 }}>Дополнительно</h3>
              <h4 style={{ marginTop: 0 }}>Ответственный</h4>
              <Flex
                justify={'space-between'}
                align={'center'}
                style={{ paddingRight: 7 }}
              >
                {!!dataContacts?.['users.fio'] && (
                  <Flex gap={6} align={'center'}>
                    <Avatar size={28} icon={<UserOutlined />} />
                    <Text style={{ marginTop: 0, color: '#05F' }}>
                      {dataContacts?.['users.fio']}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Flex vertical style={{ marginTop: 6 }}>
              <Title level={4} style={{ marginBottom: 6 }}>
                Комментарий
              </Title>
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 20 }}
                defaultValue={dataContacts?.comment}
                placeholder="Комментарии"
                disabled
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap={8}
          justify="end"
          style={{ margin: '32px' }}
        >
          <Button onClick={onClose} type="primary" style={{ width: '174px' }}>
            {t('Закрыть')}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default ViewContacts;
