import { UserOutlined } from '@ant-design/icons';
import { Flex, Form, Input, notification, Typography } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import { createContext, FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteNames } from '../../router/names';
import { useStoreDispatch } from '../../store';
import s from './RegistrationForm.module.scss';
import {
  EmailInputIcon,
  closeEyeIcon,
  LockIcon,
  openEyeIcon,
} from '../../assets/image/svg';
import Button from '../ui/Button/Button';
import Icon from '@ant-design/icons/lib/components/Icon';
import { UserRequest } from '../../services/rest_users';
import { TOK_EN, Utils } from '../../utils';
import { setAuth } from '../../store/users';

const Context = createContext({ name: 'Default' });

interface IClickLogin {
  clickLogin: () => void;
}

const RegistrationForm: FC<IClickLogin> = ({ clickLogin }) => {
  const dispatch = useStoreDispatch();
  const router = useHistory();
  const [api, contextHolder] = notification.useNotification();

  const onFinish = async (data: any) => {
    data.role_id = 1;
    const result = await UserRequest.registrationAxios(data);
    const jRes = result?.data;

    if (jRes.success === 0) {
      dispatch(setAuth(false));
      openNotification('topRight', jRes.message);
    } else {
      dispatch(setAuth(true));
      await Utils.setStorage(TOK_EN, jRes.data);
      router.push(RouteNames.CRM_COMPANY);
    }
  };
  const openNotification = (
    placement: NotificationPlacement,
    message: string,
  ) => {
    api.error({
      message: `Ошибка`,
      description: <Context.Consumer>{({ name }) => message}</Context.Consumer>,
      placement,
    });
  };
  const contextValue = useMemo(() => ({ name: '' }), []);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <Flex align={'center'} vertical>
        <div className={s.container}>
          <h1
            style={{
              marginBottom: 30,
              textAlign: 'center',
              fontFamily: 'Manrope',
              fontSize: 32,
              fontWeight: 700,
            }}
          >
            Регистрация аккаунта
          </h1>
          <Form onFinish={onFinish} layout="vertical">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, укажите Ваше имя!',
                  whitespace: true,
                },
              ]}
            >
              <Input
                className={s.input}
                placeholder="Имя"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, укажите Вашу фамилию!',
                  whitespace: true,
                },
              ]}
            >
              <Input
                className={s.input}
                placeholder="Фамилия"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item name="first_name">
              <Input
                className={s.input}
                placeholder="Отчество"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Введенный адрес электронной почты неверен!',
                },
                {
                  required: true,
                  message: 'Пожалуйста, введите свой адрес электронной почты!',
                  whitespace: true,
                },
              ]}
            >
              <Input
                className={s.input}
                placeholder="Email"
                prefix={<EmailInputIcon />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              className={s.smallStep}
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, введите свой пароль!',
                  whitespace: true,
                },
              ]}
            >
              <Input.Password
                className={s.input__password}
                prefix={<LockIcon />}
                style={{ marginBottom: 5 }}
                type={'password'}
                placeholder="Пароль"
                iconRender={(visible) =>
                  visible ? (
                    <Icon component={openEyeIcon} />
                  ) : (
                    <Icon component={closeEyeIcon} />
                  )
                }
              />
            </Form.Item>
            <Form.Item
              name="repeat_password"
              className={s.smallStep}
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, повторите пароль!',
                  whitespace: true,
                },
              ]}
            >
              <Input.Password
                className={s.input__password}
                prefix={<LockIcon />}
                type={'password'}
                placeholder="Повторите пароль"
                iconRender={(visible) =>
                  visible ? (
                    <Icon component={openEyeIcon} />
                  ) : (
                    <Icon component={closeEyeIcon} />
                  )
                }
              />
            </Form.Item>
            <Form.Item name="button" className={s.bigStep}>
              <Flex align={'center'} vertical gap={10}>
                <Button style={{ backgroundColor: '#0055FF', width: '100%' }}>
                  Зарегистрироваться
                </Button>
                <Typography.Link

                  onClick={clickLogin}
                >
                  или войдите
                </Typography.Link>
              </Flex>
            </Form.Item>
          </Form>
        </div>
      </Flex>
    </Context.Provider>
  );
};

export default RegistrationForm;
