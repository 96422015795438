import { Avatar, Flex, Typography } from 'antd';
import React from 'react';
import { checksDatePeriod } from '../../../helpers/date-helpers';
import moment from 'moment';
import IconButton from '../../ui/IconButton';
import { Utils } from '../../../utils';
import styles from '../style.module.scss';
import { UserOutlined } from '@ant-design/icons';

export interface IComment {
  id?: number | null;
  text: string;
  user: {
    avatar?: string;
    fio: string;
  };
  toUser: {
    id?: number | null;
    tag?: string;
    fio?: string;
    email?: string;
    phone?: string;
  };
  user_id: number;
  updatedAt: any;
  createdAt: any;
}

export interface IItem {
  isWidth?: boolean;
  comment: IComment;
  onDelete: () => void;
}

export const ChatItem: React.FC<IItem> = ({ comment, onDelete, isWidth }) => {
  const isDelete =
    checksDatePeriod(comment?.updatedAt || comment?.createdAt) &&
    comment?.user_id === Utils.getUserInfo()?.id;
  const deleteComment = (id?: number | null) => {
    onDelete();
  };
  const data = moment(comment?.createdAt)?.isValid() ?  moment(comment?.createdAt).format('DD MMM HH:mm') : '';
  const title = comment?.user?.fio;
  const [start, end] = typeof title === 'string' ? title.split(' ') : [];
  const alt = (start?.[0] ?? '') + (end?.[0] ?? '');
  return (
    <div className={styles['box-comment']}>
      <Flex className={styles['box-comment__row']}>
        <Avatar className={styles['box-comment__avatar']} src={comment?.user?.avatar} gap={5} icon={alt ? null : <UserOutlined />} alt={alt || 'icon'}>
          {alt}
        </Avatar>
        <Flex vertical gap={5} style={isWidth ? {width: '100%'} : {}}>
          <Flex justify={isWidth ? 'space-between' : 'flex-start'} align={'baseline'} gap={30}>
            <Typography.Paragraph className={styles['box-comment__title']}>
              {comment?.user?.fio}
            </Typography.Paragraph>
            <Flex align="center" gap={10}>
              <Typography.Text className={styles['box-comment__data']}>
                {data}
              </Typography.Text>
              {!isDelete && (
                <IconButton
                  className={styles['box-comment__delete']}
                  iType="delete"
                  onClick={() => deleteComment(comment?.id)}
                />
              )}
            </Flex>
          </Flex>
          <Flex>
            <Typography.Paragraph
              className={styles['box-comment__description']}
              ellipsis={{
                rows: 4,
                tooltip: { placement: 'topLeft', title: comment?.text },
              }}
            >
              <Typography.Link>{comment?.toUser?.tag ?? ''}</Typography.Link>{' '}
              {comment?.text}
            </Typography.Paragraph>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};
