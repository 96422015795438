import { IColumnsEditTable } from '../../../components/ui/Form/FormTable/FormTable';
import { CREATE } from '../../../helpers/string-helpers';

// табы
export const COMPLETED = 'completed'; // таб завершить
export const INVOICE_APPROVAL = 'invoiceApproval'; // согласование счета
export const INCONSISTENT_ACCOUNTS = 'inconsistentAccounts'; // не согласованные счета
export const CHECKING_ECONOMICS_DEPARTMENT = 'checkingEconomicsDepartment'; // Проверка экономического отдела
export const PAYMENT = 'payment'; // оплата
export const PRIMARY_INFORMATION = 'primaryInformation'; // запрос первичной информации

export const titles = {
  [CREATE]: 'Новый счет',
  [INVOICE_APPROVAL]: 'Согласование счета',
  [INCONSISTENT_ACCOUNTS]: 'Не согласованные счета',
  [CHECKING_ECONOMICS_DEPARTMENT]: 'Проверка экономического отдела',
  [PAYMENT]: 'Оплата',
  [PRIMARY_INFORMATION]: 'Запрос первичной информации',
  [COMPLETED]: 'Завершить',

}

export const ktkDefault = [
  {
    deal_id: '333',
    num_container: 'ASSQ1131314',
    type_container: '20DC',
    bet: '100',
    count_day_snp: '20',
    checked: false,
  },
];

export const columnsKtk: IColumnsEditTable[] = [
  {
    title: '№',
    dataIndex: 'deal_id',
    width: '30%',
  },
  {
    title: '№КТК',
    dataIndex: 'num_container',
    width: '30%',
  },
  {
    title: 'Тип',
    dataIndex: 'type_container',
    width: '30%',
  },
  {
    title: 'Ставка клиенту',
    dataIndex: 'bet',
    width: '30%',
  },
  {
    title: 'Период (если СНП)',
    dataIndex: 'count_day_snp',
    width: '30%',
  },
  {
    title: '',
    dataIndex: 'checked',
    width: '40px',
    type: 'checkbox',
  },
];

export const defaultResponsiblePerson = { responsible_id: null, options: [] };

export const noStyleField = [
  'id',
  'type_deal',
  'type_deal_char',
  'email',
  'phone',
  'my_company_id',
  'company_id',
  'currency_id',
  'contact_id',
  'expense_id',
  'responsible_id',
  'responsible_name',
  'agreement_id',
  'operating_director_id',
  'author_id',
  'task_id',
  'saveFiles',
  'file_ids',
  'responsible_persons',
  'observers',
  'observer',
  'observer_id',
  'approval_director',
  'approval_fin',
  'executor_id',
  'related_deals_ids',
];
