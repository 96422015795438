import React from 'react';

import { useTranslation } from 'react-i18next';
import { Collapse, Flex, Typography } from 'antd';

import style from '../style.module.scss';
import { HistoryLabel } from './HistoryLabel';
import { Checkbox } from '../../ui/Checkbox/Checkbox';
import { IAnyValue, IConfigList } from '../CustomHistory';
import {
  IViewTableColumn,
  IViewTableData,
  ViewTable,
} from '../../ui/List/ViewTable/ViewTable';

interface IHistoryColumns {
  title?: string;
  prevData?: IAnyValue;
  currentData?: IAnyValue;
  configList: IConfigList[];
}
export const HistoryColumns: React.FC<IHistoryColumns> = ({
  currentData,
  prevData,
  configList,
}) => {
  const { t } = useTranslation();
  const getItems = (
    title: string,
    data: IViewTableData[],
    columns?: IViewTableColumn[],
  ) => [
    {
      key: title,
      label: <HistoryLabel label={t(title)} />,
      children: (
        <ViewTable
          className={style['history__table']}
          columns={columns}
          data={data || []}
        />
      ),
    },
  ];
  return (
    <Flex gap={10} vertical>
      <div className={style['history__row']}>
        <Typography.Text className={style.checkboxes__label}> </Typography.Text>
        <HistoryLabel label={t('Было')} />
        <HistoryLabel label={t('Стало')} />
      </div>
      {configList.map(
        ({
          isCheckShow,
          isCheckOpposite,
          titleRow,
          suffixTitleRow,
          renderValue,
          defaultValue,
          typeRow,
          keyRowCurrentData,
          keyRowCurrentDataShow,
          keyRowPrevData,
          keyRowPrevDataShow,
          keyValueRowCurrentData,
          keyValueRowPrevData,
          columnsTable,
        }, i) => (
          <div key={i + typeRow}>
            {typeRow === 'string' &&
              (isCheckShow
                ? isCheckOpposite
                  ? !prevData?.[keyRowPrevDataShow] ||
                    !currentData?.[keyRowCurrentDataShow]
                  : prevData?.[keyRowPrevDataShow] ||
                    currentData?.[keyRowCurrentDataShow]
                : true) && (
                <div className={style['history__row']}>
                  <HistoryLabel
                    label={t(titleRow) + suffixTitleRow}
                    selected={
                      prevData?.[keyRowPrevData] !==
                      currentData?.[keyRowCurrentData]
                    }
                  />

                  <HistoryLabel
                    className={style['history__background']}
                    value={
                      renderValue
                        ? prevData?.[keyRowPrevData]
                          ? renderValue
                          : defaultValue
                        : prevData?.[keyValueRowPrevData] || defaultValue
                    }
                  />
                  <HistoryLabel
                    className={style['history__background']}
                    value={
                      renderValue
                        ? currentData?.[keyRowCurrentData]
                          ? renderValue
                          : defaultValue
                        : currentData?.[keyValueRowCurrentData] || defaultValue
                    }
                  />
                </div>
              )}
            {typeRow === 'checkbox' &&
              (isCheckShow
                ? isCheckOpposite
                  ? !prevData?.[keyRowPrevDataShow] ||
                    !currentData?.[keyRowCurrentDataShow]
                  : prevData?.[keyRowPrevDataShow] ||
                    currentData?.[keyRowCurrentDataShow]
                : true) && (
                <div className={style['history__row']}>
                  <HistoryLabel
                    label={t(titleRow) + suffixTitleRow}
                    selected={
                      prevData?.[keyRowPrevData] !==
                      currentData?.[keyRowCurrentData]
                    }
                  />

                  <div className={style['history__background']}>
                    <Checkbox
                      isChoice={prevData?.[keyValueRowPrevData]}
                      disabled
                    ></Checkbox>
                  </div>
                  <div className={style['history__background']}>
                    <Checkbox
                      isChoice={currentData?.[keyValueRowCurrentData]}
                      disabled
                    ></Checkbox>
                  </div>
                </div>
              )}
            {typeRow === 'table' &&
              (isCheckShow
                ? isCheckOpposite
                  ? !prevData?.[keyRowPrevDataShow] ||
                    !currentData?.[keyRowCurrentDataShow]
                  : prevData?.[keyRowPrevDataShow] ||
                    currentData?.[keyRowCurrentDataShow]
                : true) && (
                <>
                  <div className={style['history__row']}>
                    <HistoryLabel
                      label={t(titleRow) + suffixTitleRow}
                      selected={
                        JSON.stringify(prevData?.[keyRowPrevData]) !==
                        JSON.stringify(currentData?.[keyRowCurrentData])
                      }
                    />
                  </div>
                  <div className={style['history__column']}>
                    <Collapse
                      collapsible="icon"
                      items={getItems(
                        'Было',
                        prevData?.[keyValueRowPrevData],
                        columnsTable,
                      )}
                    />
                    <Collapse
                      collapsible="icon"
                      items={getItems(
                        'Стало',
                        currentData?.[keyValueRowCurrentData],
                        columnsTable,
                      )}
                    />
                  </div>
                </>
              )}
            {typeRow === 'length' &&
              (isCheckShow
                ? isCheckOpposite
                  ? !prevData?.[keyRowPrevDataShow] ||
                    !currentData?.[keyRowCurrentDataShow]
                  : prevData?.[keyRowPrevDataShow] ||
                    currentData?.[keyRowCurrentDataShow]
                : true) && (
                <div className={style['history__row']}>
                  <HistoryLabel
                    label={t(titleRow) + suffixTitleRow}
                    selected={
                      prevData?.[keyRowPrevData]?.length !==
                      currentData?.[keyRowCurrentData]?.length
                    }
                  />

                  <HistoryLabel
                    className={style['history__background']}
                    value={
                      renderValue
                        ? prevData?.[keyRowPrevData]?.length
                          ? renderValue
                          : defaultValue
                        : prevData?.[keyValueRowPrevData]?.length ||
                          defaultValue
                    }
                  />
                  <HistoryLabel
                    className={style['history__background']}
                    value={
                      renderValue
                        ? currentData?.[keyRowCurrentData]?.length
                          ? renderValue
                          : defaultValue
                        : currentData?.[keyValueRowCurrentData] || defaultValue
                    }
                  />
                </div>
              )}
          </div>
        ),
      )}
    </Flex>
  );
};
