import React from 'react';
import { useEffect } from 'react';

import { Space } from 'antd';
import { useSelector } from 'react-redux';

import { columns } from './columns';
import socket from '../../../socket';
import style from '../style.module.scss';
import { AGREEMENT } from '../../../constants';
import { FormAgreements } from './FormAgreements';
import { ViewAgreements } from './ViewAgreements';
import { Table } from '../../../components/ui/Table/Table';
import { RootState, useStoreDispatch } from '../../../store';
import { CREATE, EDIT, VIEW } from '../../../helpers/string-helpers';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import {
  useGettingDataForTables,
  useParamsHistory,
} from '../../../hooks';
import {
  Rest_agreement,
  TYPE_AGREEMENT,
} from '../../../services/rest_agreement';
import {
  defaultAgreement,
  getAgreementTypes,
  IAgreement,
  resetStore,
  setIsOpenDrawer,
  setOneAgreement,
  setTypeForm,
} from '../../../store/agreement';

interface ITableAgreements {
  paths: { parent: string };
  activeKey: string;
}

export const TableAgreements: React.FC<ITableAgreements> = ({
  paths,
  activeKey,
}) => {
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const typeForm = useSelector<RootState>(
    (state) => state.agreement.typeForm,
  ) as string;
  const isOpenDrawer = useSelector<RootState>(
    (state) => state.agreement.isOpenDrawer,
  ) as boolean;
  const initialValue = useSelector<RootState>(
    (state) => state.agreement.agreement,
  ) as IAgreement;

  const types = useSelector<RootState>(
    (state) => state.agreement.types,
  ) as any[];

  const { dataTable, optionsTable, updateDate, updateEffectDate } =
    useGettingDataForTables({
      table: AGREEMENT,
      location,
      columns,
      fetchTables: Rest_agreement.getAgreements,
      fetchDelete: Rest_agreement.deleteAgreement,
    });

  useEffect(() => {
    if (activeKey !== AGREEMENT) return;

    if (pathname === paths.parent) {
      dispatch(resetStore());
      updateEffectDate();
      const row = location?.state?.[AGREEMENT];
      if (row) {
        if (row?.edit) {
          dispatch(setOneAgreement(row.edit));
          dispatch(setTypeForm(EDIT));
          dispatch(setIsOpenDrawer(true));
          return;
        }
        dispatch(setTypeForm(CREATE));
        dispatch(setOneAgreement(row));

        dispatch(setIsOpenDrawer(true));
        return;
      }
      return;
    }

    return history.replace(paths.parent);
  }, [activeKey, pathname]);

  useEffect(() => {
    if (activeKey !== AGREEMENT) return;
    if (!types?.length) {
      dispatch(getAgreementTypes()).finally(() => {
        socket.off(TYPE_AGREEMENT);
      });
      return;
    } else {
      socket.off(TYPE_AGREEMENT);
    }
  }, []);

  const onRow = (row: IAgreement) => {
    dispatch(setTypeForm(VIEW));
    dispatch(setOneAgreement(row));
    dispatch(setIsOpenDrawer(true));
  };

  const handleShowEditForm = (row: IAgreement) => {
    dispatch(setTypeForm(EDIT));
    dispatch(setOneAgreement(row));
    if (row?.delete) {
      dispatch(setTypeForm(VIEW));
    }
    dispatch(setIsOpenDrawer(true));
  };

  const onCloseDrawer = (res?: boolean) => {
    dispatch(setIsOpenDrawer(false));
    dispatch(setOneAgreement({ ...defaultAgreement }));
  };

  const onClose = () => {
    onCloseDrawer(false);
  }

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Table
        {...optionsTable}
        className={style.container__table}
        onClickLink={onRow}
        editRow={handleShowEditForm}
        onRow={onRow}
        columns={columns}
        dataSource={dataTable}
        style={{ width: '100%' }}
        height={'calc(100vh - 350px)'}
      />

      <CustomDrawer
        isHeight
        minWidth={700}
        open={isOpenDrawer}
        onClose={onClose}
      >
        {typeForm === VIEW ? (
          <ViewAgreements
            isCopy
            typeForm={typeForm}
            isOpen={isOpenDrawer}
            initialValue={initialValue}
            onEdit={() => dispatch(setTypeForm(EDIT))}
            onClose={onCloseDrawer}
          />
        ) : (
          <FormAgreements
            isCopy
            typeForm={typeForm}
            isOpen={isOpenDrawer}
            initialValue={initialValue}
            onClose={onCloseDrawer}
            onEdit={() => dispatch(setTypeForm(VIEW))}
          />
        )}
      </CustomDrawer>
    </Space>
  );
};
