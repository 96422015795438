import { createSlice } from "@reduxjs/toolkit";

interface IFiltersState {
    dataFilters: []
}

const initialState: IFiltersState = {
    dataFilters: []
};

const filtersSlice = createSlice({
    name: 'filtersSlice',
    initialState,
    reducers: {
        clearFiltersFetch(state) {
            state.dataFilters = []
        },
        setFiltersFetch(state, action) {
            state.dataFilters = [];
            state.dataFilters = action.payload.rows
        },
    },
});
export default filtersSlice.reducer;
export const {clearFiltersFetch, setFiltersFetch} = filtersSlice.actions
