import React from 'react';
import { Breadcrumb, Collapse, Flex, Typography } from 'antd';
import classnames from 'classnames';
import { HistoryColumns } from './components/HistoryColumns';
import { getDateString } from '../../helpers/date-helpers';
import { useTranslation } from 'react-i18next';
import IconButton from '../ui/IconButton';

import style from './style.module.scss';
import { IViewTableColumn } from '../ui/List/ViewTable/ViewTable';

export interface IAnyValue {
  [key: string]: any;
}

export interface IHistoryValue {
  createdAt: string;
  responsible: string;
  data: any;
  [key: string]: any;
}

export interface IConfigList {
  typeRow: 'string' | 'checkbox' | 'table' | 'delete' | 'length'; // тип строки
  renderValue?: any; // если не нужно значение а например иконку вывести
  titleRow: string; // заголовок строки
  suffixTitleRow: string; // суффикс после заголовка если нужен
  isCheckShow?: boolean; // нужна ли проверка на показ
  isCheckOpposite?: boolean; // нужна ли проверка на показ
  keyRowPrevDataShow: string; // ключ объекта начального, если нужно проверить показать или нет
  keyRowCurrentDataShow: string; // ключ объекта текущего, если нужно проверить показать или нет
  keyRowPrevData: string; // ключ объекта начального
  keyRowCurrentData: string; // ключ объекта текущего
  keyValueRowPrevData: string; // ключ значения начальное
  keyValueRowCurrentData: string; // ключ значения текущее
  defaultValue: string | any[]; // дефолтное значение если не надо показать null или undefined
  columnsTable?: IViewTableColumn[] // колонки для таблицы показа
}

interface ICustomHistory {
  initialValue: IHistoryValue[];
  defaultData: IAnyValue[];
  configList: IConfigList[];
  title?: string;
  onReturn: () => void;
}

export const CustomHistory: React.FC<ICustomHistory> = ({
  initialValue,
  defaultData,
  configList,
  onReturn,
  title = '',
}) => {
  const { t } = useTranslation();

  const items = initialValue?.map((item, i, arr) => ({
    key: item.createdAt,
    label: (
      <Flex justify="space-between">
        <Typography.Text>{item.responsible}</Typography.Text>
        <Typography.Text>
          {getDateString({ date: item.createdAt, format: 'DD/MM/YYYY HH:mm' })}
        </Typography.Text>
      </Flex>
    ),
    children: (
      <HistoryColumns
        key={item.responsible + i}
        prevData={i ? arr[i - 1].data : defaultData}
        currentData={item.data}
        configList={configList}
      />
    ),
  }));
  const breadcrumb = title
    ? [{ title: t('История изменений') }, { title }]
    : [{ title: t('История изменений') }];
  return (
    <div className={style['history']}>
      <Flex gap={10} align="center" justify="space-between">
        <Breadcrumb
          style={{ fontSize: '16px' }}
          separator={
            <div className={style['separator']}>
              <span className={style['separator__circle']} />
            </div>
          }
          items={breadcrumb}
        />
        <IconButton iType="go-back" onClick={onReturn} />
      </Flex>
      <div className={classnames(style['history__content'])}>
        <Collapse collapsible="icon" items={items} />
      </div>
    </div>
  );
};
