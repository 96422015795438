import { IColumnsEditTable } from '../../../components/ui/Form/FormTable/FormTable';
export const COMPLETED = 'completed';
export const PART_COMPLETED = 'partCompleted';
export const CANCELLED = 'cancelled';
export const NEW = 'new';
export const CREATED = 'created';
export const SENT = 'sent';

export const ktkDefault = [
  {
    deal_id: '333',
    num_container: 'ASSQ1131314',
    type_container: '20DC',
    bet: '100',
    count_day_snp: '20',
    checked: false,
  },
];

export const columnsKtk: IColumnsEditTable[] = [
  {
    title: '№',
    dataIndex: 'deal_id',
    width: '30%',
  },
  {
    title: '№КТК',
    dataIndex: 'num_container',
    width: '30%',
  },
  {
    title: 'Тип',
    dataIndex: 'type_container',
    width: '30%',
  },
  {
    title: 'Ставка клиенту',
    dataIndex: 'bet',
    width: '30%',
  },
  {
    title: 'Период (если СНП)',
    dataIndex: 'count_day_snp',
    width: '30%',
  },
  {
    title: '',
    dataIndex: 'checked',
    width: '40px',
    type: 'checkbox',
  },
];

export const statusColorsInvoice = {
  [CREATED]: {
    title: 'Создан',
    style: {
      color: '#00B288',
      background: '#E6F6EC',
    },
  },
  [SENT]: {
    title: 'Отправлен',
    style: {
      color: '#a3a9b1',
      background: '#E6F6EC',
    },
  },
  [PART_COMPLETED]: {
    title: 'Оплачен частично',
    style: {
      color: '#a3a9b1',
      background: '#E6F6EC',
    },
  },
  [COMPLETED]: {
    title: 'Оплачен',
    style: {
      color: '#a3a9b1',
      background: '#E6F6EC',
    },
  },
  [NEW]: {
    title: 'Нет статуса',
    style: {
      color: '#a3a9b1',
      background: '#E6F6EC',
    },
  },
  [CANCELLED]: {
    title: 'Аннулирован',
    style: {
      color: '#E14453',
      background: '#FCE8EA',
    },
  },
};

export const noStyleField = [
  'id',
  'type_deal',
  'type_deal_char',
  'email',
  'phone',
  'my_company_id',
  'company_id',
  'contact_id',
  'currency_id',
  'responsible_id',
  'responsible_name',
  'saveFiles',
  'executor_id',
  'agreement_id',
  'related_deals_ids',
];
