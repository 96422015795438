import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { App, AutoComplete, Button, Flex, Input, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { RouteNames } from '../../../router/names';
import Container from '../../../components/Layouts/Container';
import { RootState, useStoreDispatch } from '../../../store';
import { SessionStorageManager } from '../../../components/ui/Table/sessionStorageManager';
import { StorageData } from '../../../types';
import {
  useBreadcrumb,
  useGettingDataForTables,
  useMenu,
  useParamsHistory,
} from '../../../hooks';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../../components/ui/Table/Table';
import {
  setTableRequests,
  IRequest,
  setResetState,
  defaultValuesRequest,
  findOneRequest,
} from '../../../store/rent';
import { columns } from './columns';
import socket from '../../../socket';
import {
  DELETE_REQUESTS_RENT,
  GET_REQUESTS_RENT,
  Rest_rent,
} from '../../../services/rest_rent';
import {
  TABLE_RENT_REQUESTS,
  ROW_RENT_REQUESTS,
  COUNT_RENT_REQUESTS,
} from '../deals/constants';
import {
  CREATE,
  EDIT,
  getTypeForm,
  VIEW,
} from '../../../helpers/string-helpers';
import { RENT_REQUEST, REQUESTS } from '../../../constants';
import { setOneRequest } from '../../../store/rent';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import FormModalRentRequest from './components/formModalRentRequest';

const Requests = () => {
  const { t } = useTranslation();
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();

  const [openDrawerRequest, setOpenDrawerRequest] = useState<boolean>(false);
  const requestOneData = useSelector<RootState>(
    (state) => state.rent.requestOneData,
  ) as IRequest;

  const {
    dataTable,
    current,
    pageSize,
    optionsTable,
    setDataTable,
    updateDate,
    updateEffectDate,
  } = useGettingDataForTables({
    table: RENT_REQUEST,
    location,
    columns,
    fetchTables: Rest_rent.getRentRequests,
    fetchDelete: Rest_rent.deleteRequest,
  });

  useBreadcrumb(['Аренда', 'Запросы аренды']);
  useMenu({
    openKey: RouteNames.RENT,
    activeKeys: [RouteNames.RENT, RouteNames.RENT_REQUESTS],
  });
  const [typeForm, setTypeForm] = useState<string>(
    requestOneData?.id ? EDIT : CREATE,
  );

  useEffect(() => {
    if (requestOneData?.id) {
      setTypeForm(EDIT);
    } else {
      setTypeForm(CREATE);
    }
  }, [requestOneData]);

  useEffect(() => {
    if (pathname === RouteNames.RENT_REQUESTS) {
      dispatch(setResetState());
      updateEffectDate();
      const rent = location?.state?.[REQUESTS];
      if (rent) {
        if (rent?.edit) {
          dispatch(setOneRequest(rent.edit));
          setTypeForm(EDIT);
          setOpenDrawerRequest(true);
          return;
        }
        setTypeForm(CREATE);
        dispatch(
          setOneRequest({
            ...defaultValuesRequest,
            ...rent,
          }),
        );

        setOpenDrawerRequest(true);
        return;
      }
    }
    return history.replace(RouteNames.RENT_REQUESTS);
  }, [pathname]);

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => {
      setSearchColumn((prev) =>
        prevActiveButtons[option.key]
          ? prev.filter((item: string) => item !== option.dataIndex)
          : [...prev, option.dataIndex],
      );
      return {
        ...prevActiveButtons,
        [option.key]: !prevActiveButtons[option.key],
      };
    });
  };

  const onCloseFormDeal = () => {
    dispatch(setResetState());
    setOpenDrawerRequest(false);
  };

  const handleSearch = (val: string) => {};

  const onCreate = () => {
    setTypeForm(CREATE);
    dispatch(setOneRequest(defaultValuesRequest));
    setOpenDrawerRequest(true);
  };

  const onClickLink = (data: any) => {
    const row = data;
    if (row?.id) {
      setTypeForm(EDIT);
      dispatch(setOneRequest(row));
      setOpenDrawerRequest(true);
    }
  };

  const onRow = (data: IRequest) => {
    const row = data;
    if (row?.id) {
      setTypeForm(EDIT);
      dispatch(setOneRequest(row?.id));
      setOpenDrawerRequest(true);
    }
  };

  const handleShowEditForm = async (data: IRequest) => {
    const row = data;
    if (row?.id) {
      setTypeForm(EDIT);
      await dispatch(findOneRequest(Number(row.id))); // Ждем загрузку данных
      setOpenDrawerRequest(true);
    }
  };

  const handleShowDrawerRequest = (data: IRequest) => {
    setTypeForm(CREATE);
    dispatch(setOneRequest(defaultValuesRequest));
    setOpenDrawerRequest(true);
    console.log('тип handleShowDrawerRequest', typeForm);
  };

  const onCloseFormRequest = () => {
    dispatch(setResetState());
    setOpenDrawerRequest(false);
  };

  return (
    <Container>
      <Flex
        gap={10}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('Запросы аренды')}</div>
        </Flex>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={onCreate}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            defaultValue={searchValue}
            popupMatchSelectWidth={false}
            onSearch={handleSearch}
            dropdownRender={() => (
              <>
                <div
                  className={'text-card-12'}
                  style={{ padding: '10px 10px' }}
                >
                  {t('Выбор колонок по которым будет производиться поиск')}
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: 'wrap', padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      option.title !== '' &&
                      option.dataIndex !== 'date_entry' &&
                      option.dataIndex !== 'arrival_date' &&
                      option.dataIndex !== 'departure_date' &&
                      option.dataIndex !== 'booking_before' && (
                        <Button
                          size={'small'}
                          key={option.key}
                          type="primary"
                          className={
                            activeButtons[Number(option.key)]
                              ? 'active-search-btn'
                              : ''
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      ),
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder={t('Введите текст')} />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          {...optionsTable}
          onClickLink={onClickLink}
          editRow={handleShowEditForm}
          onRow={onRow}
          columns={columns}
          dataSource={dataTable}
          height={'calc(-162px + 100vh)'}
          style={{ overflow: 'auto hidden' }}
        />
      </Space>
      <CustomDrawer
        open={openDrawerRequest}
        onClose={onCloseFormRequest}
        isHeight
        minWidth={1000}
      >
        <FormModalRentRequest
          title={
            typeForm === CREATE ? 'Создать сделку' : 'Редактировать сделку'
          }
          isOpen={openDrawerRequest}
          onClose={onCloseFormDeal}
          initialValue={requestOneData || defaultValuesRequest}
          onEdit={() => setTypeForm(EDIT)}
          typeForm={typeForm}
        />
      </CustomDrawer>
    </Container>
  );
};

export default Requests;
